/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListQboAccountsOrdering =
  (typeof ListQboAccountsOrdering)[keyof typeof ListQboAccountsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListQboAccountsOrdering = {
  status: 'status',
  '-status': '-status',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

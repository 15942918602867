/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListBusinessNotesOrdering =
  (typeof ListBusinessNotesOrdering)[keyof typeof ListBusinessNotesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListBusinessNotesOrdering = {
  user_full_name: 'user_full_name',
  '-user_full_name': '-user_full_name',
  content: 'content',
  '-content': '-content',
  timestamp: 'timestamp',
  '-timestamp': '-timestamp',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

import BigNumber from 'bignumber.js'
import { unreachable } from '../misc'
import { Zero } from './utils'

export namespace Format {
  export function currency(
    value: string | BigNumber | null | false,
    options: {
      minDecimalPlaces?: 0 | 2
      maxDecimalPlaces?: number
      sign?: 'positive-and-negative' | 'negative-only'
      treatZeroAsNull?: boolean
      removeDollarSignFromEmptyValue?: boolean
    } = {},
  ) {
    const {
      sign = 'negative-only',
      treatZeroAsNull = false,
      removeDollarSignFromEmptyValue = false,
      minDecimalPlaces = 2,
      maxDecimalPlaces = 2,
    } = options

    if (value === null || value === false || (BigNumber(value).isZero() && treatZeroAsNull))
      return removeDollarSignFromEmptyValue ? '--' : '$ --'

    const bigNumber = BigNumber(value)
    const precisionExcludingZeros = bigNumber.decimalPlaces() ?? 0
    const decimalCount = Math.min(
      Math.max(precisionExcludingZeros, minDecimalPlaces),
      maxDecimalPlaces,
    )

    try {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimalCount,
        maximumFractionDigits: decimalCount,
        signDisplay:
          sign === 'negative-only'
            ? 'auto'
            : sign === 'positive-and-negative'
            ? 'exceptZero'
            : unreachable(sign),
      })

      return formatter.format(bigNumber.toNumber())
    } catch (e: any) {
      reportError(e)
      const isNegative = bigNumber.lt(Zero)
      return `${isNegative ? '-' : ''}$${bigNumber.abs().toFixed(decimalCount)}`
    }
  }

  // It's tempting to change this to take in parameters instead of an enum butt please don't.
  export function decimal(
    value: string | BigNumber | null | false,
    type: 'at_most_3' | 'exactly_2',
  ) {
    if (value === null || value === false) return '--'
    const n = BigNumber(value)
    if (type === 'at_most_3') {
      return n.toFixed(Math.min(n.decimalPlaces() ?? 0, 3))
    } else {
      return n.toFixed(2)
    }
  }

  export function percent(multiplier: BigNumber | string | null) {
    if (multiplier === null) return '-- %'
    const bigNumber = BigNumber(multiplier).multipliedBy(100)
    const precisionExcludingZeros = bigNumber.decimalPlaces()
    return `${bigNumber.toFixed(Math.min(precisionExcludingZeros ?? 0, 2))}%`
  }

  export function ratio(numerator: BigNumber | string | null, denominator: BigNumber | null) {
    if (numerator === null || denominator === null || denominator.isZero()) return '--'
    return percent(BigNumber(numerator).dividedBy(denominator))
  }

  export function currencyRatio(
    numerator: BigNumber | string | null,
    denominator: BigNumber | string,
  ) {
    const denominatorNum = BigNumber(denominator)
    if (denominatorNum.isZero()) return '--'
    return `${currency(numerator)} (${ratio(numerator, denominatorNum)})`
  }

  export function phone(value: string | null) {
    if (!value) return '--'

    const cleaned = value.replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

    if (!match) {
      reportError(`Unformattable phone: ${value}`)
      return value
    } else {
      const [, match1, match2, match3] = match
      return `(${match1}) ${match2}-${match3}`
    }
  }

  export function ein(value: string | null) {
    if (!value) return '--'
    const cleaned = value.replace(/\D/g, '')
    const match = cleaned.match(/^(\d{2})(\d{7})$/)

    if (!match) {
      reportError(`Unformattable EIN: ${value}`)
      return value
    } else {
      const [, match1, match2] = match
      return `${match1}-${match2}`
    }
  }
}

import { toast } from 'react-toastify'
import { MSArray } from 'msutils/array'
import Button from 'compass/data/Button'
import { theme2 } from 'theme2'
import { defineModule } from '../../utils'

export default defineModule({
  id: 'debug',
  component: (
    <div className="vflex gap-3">
      <Button
        theme={theme2.ButtonThemeTextOrange}
        onClick={() => {
          MSArray.assertFind([], () => false)
          toast.success('Sent an error to Sentry')
        }}
      >
        Trigger assert find error
      </Button>
    </div>
  ),
})

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListPaymentSourcesOrdering =
  (typeof ListPaymentSourcesOrdering)[keyof typeof ListPaymentSourcesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListPaymentSourcesOrdering = {
  name: 'name',
  '-name': '-name',
  type: 'type',
  '-type': '-type',
  external: 'external',
  '-external': '-external',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

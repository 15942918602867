import { Cb } from 'cb'
import { MultiEmailInputUtils } from 'compass-local/MultiEmailInput'
import { t } from 'content'
import { F } from 'msutils'
import { RichAddressInputUtils } from '../RichAddressInput'

export namespace ClientInputUtils {
  export const schema = F.Group({
    validate: (subvals) => ({
      ...subvals,
      ...(subvals.contactType === 'homeowner' && { ein: '' }),
    }),
    spec: {
      contactType: F.Choice<Cb.PayerContactContactType>({
        initValue: 'homeowner',
      }).required(),
      name: F.Text({ required: true }),
      email: F.Email(),
      address: F.Group({
        errorKey: 'address_2',
        spec: RichAddressInputUtils.schema.spec,
        optional: true,
      }),
      ccEmails: MultiEmailInputUtils.schema,
      phone: F.Phone(),
      ein: F.Ein(),
    },
  })

  export const ClientTypes = [
    { id: 'homeowner', title: t('Homeowner') },
    { id: 'contractor', title: t('Contractor') },
    { id: 'developer', title: t('Business') },
  ] as const

  export type CopySpec = {
    title: string
    submit: string
  }
}

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListEstimateConfigsOrdering =
  (typeof ListEstimateConfigsOrdering)[keyof typeof ListEstimateConfigsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListEstimateConfigsOrdering = {
  default_markup_multiplier: 'default_markup_multiplier',
  '-default_markup_multiplier': '-default_markup_multiplier',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

import { Cb } from 'cb'
import { t, Trans } from 'content'
import Typography from 'compass/data/Typography'
import Card from 'compass/layout/Card'
import { theme2 } from 'theme2'
import BigNumber from 'bignumber.js'
import Button from 'compass/data/Button'
import Icon from 'compass/data/Icon'
import { LinkEXPERIMENTAL } from 'components/misc/LinkEXPERIMENTAL'
import { Format } from 'msutils'
import { RenofiUtils } from '../utils'

type Props = {
  amount: BigNumber
  offer: NonNullable<Cb.RenofiAdOffer | Cb.GetRenofiAdViewOutputOffer>
  perspective: RenofiUtils.Perspective
}

export default function RenofiAdCard({ amount, offer, perspective }: Props) {
  const clickLink = Cb.useClickLinkHook()

  return (
    <Card className="vflex gap-3 max-w-[400px]">
      <div className="vflex gap-2">
        <div className="flex justify-between items-center">
          <div className="vflex gap-1 items-top">
            <Typography variant="label">
              {BigNumber(offer.max_amount).gte(500000)
                ? t('Finance up to $500k for as low as')
                : t('Finance for as low as')}
            </Typography>
            <Typography>
              <Trans
                tKey="<X>{{ X }}</X> / month"
                X={<Typography variant="title" />}
                values={{ X: Format.currency(offer.monthly_payment) }}
              />
            </Typography>
          </div>
          <img src="/images/renofi-logo.svg" alt="RenoFi logo" />
        </div>
        <Typography>
          <Trans
            tKey="RenoFi unlocks the power of your home’s future value so you can <X>keep your existing mortgage</X> and <X>get lower rates</X>."
            X={<Typography variant="bodybold" />}
          />
        </Typography>
      </div>
      <div
        onClick={() => {
          if ('link_id' in offer) {
            clickLink({ link_id: offer.link_id })
          }
        }}
      >
        <div className="w-min">
          <Button
            theme={theme2.ButtonThemeTextDarkSmall}
            endIcon={<Icon name="square-with-arrow" />}
            onClick={() =>
              LinkEXPERIMENTAL.open(
                RenofiUtils.getOfferUrl({ type: offer.type, offerAmount: amount, perspective }),
              )
            }
          >
            {perspective === 'offerer'
              ? t('Get pre-qualified')
              : t('Learn more and get pre-qualified')}
          </Button>
        </div>
      </div>
    </Card>
  )
}

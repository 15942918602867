import { ChangeEvent, HTMLAttributes, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { unreachable } from 'msutils/misc'
import useScreenSize from '../../theme/useScreenSize'
import { InputUtils } from '../utils'

export namespace TextInputUtils {
  export type TypeProps =
    | ({ type?: 'text' } & InputUtils.InputStateProps<string>)
    | ({ type: 'decimal'; decimalPlaces?: number } & InputUtils.InputStateProps<BigNumber | ''>)
    | ({ type: 'currency'; decimalPlaces?: number } & InputUtils.InputStateProps<BigNumber | ''>)
    | ({ type: 'percent'; decimalPlaces?: number } & InputUtils.InputStateProps<BigNumber | ''>)
    | ({ type: 'date' } & InputUtils.InputStateProps<string>)

  type InputType = TypeProps['type']

  // ------- Formatting utils

  type InputOperations<T> = {
    // "123.4" -> "$123.4"
    toDisplay: (internalValue: string) => string
    // "$123.4" -> "123.4"
    fromDisplay: (displayValue: string) => string
    // "123.4" -> BigNumber(123.4)
    toExternal: (internalValue: string) => T
    // BigNumber(123.4) -> "123.40"
    fromExternal: (externalValue: T) => string
    isAcceptableAsIntermediateValue: (internalValue: string) => boolean
    isAcceptableAsExternalValue: (internalValue: string) => boolean
    selectionRangeIsValid: (value: string, range: [number | null, number | null]) => boolean
    resetSelectionRange: (value: string) => number
  }

  const defaultConfig: InputOperations<string> = {
    toDisplay: (x) => x,
    fromDisplay: (x) => x,
    toExternal: (x) => x,
    fromExternal: (x) => x,
    isAcceptableAsIntermediateValue: () => true,
    isAcceptableAsExternalValue: () => true,
    selectionRangeIsValid: () => true,
    resetSelectionRange: () => 0,
  }

  function decimalConfig(decimalPlaces: number): InputOperations<BigNumber | ''> {
    return {
      toDisplay: (x) => x,
      fromDisplay: (val) => {
        const deformatted = val.replaceAll(',', '')
        if (deformatted.split('').filter((x) => x === '-').length > 1) {
          return `-${deformatted.replaceAll('-', '')}`
        } else {
          return deformatted
        }
      },
      toExternal: (x) => x && BigNumber(x),
      fromExternal: (x) => x && x.toString(),
      isAcceptableAsIntermediateValue: (x) =>
        x === '' ||
        x === '-' ||
        (!BigNumber(x).isNaN() &&
          (BigNumber(x).decimalPlaces() ?? decimalPlaces + 1) <= decimalPlaces),
      isAcceptableAsExternalValue: (x) => x === '' || !BigNumber(x).isNaN(),
      selectionRangeIsValid: () => true,
      resetSelectionRange: () => 0,
    }
  }

  function currencyConfig(decimalPlaces: number): InputOperations<BigNumber | ''> {
    return {
      ...decimalConfig(decimalPlaces),
      toDisplay: (x) => (x === '' ? '' : x.startsWith('-') ? `-$${x.substring(1)}` : `$${x}`),
      fromDisplay: (x) => decimalConfig(decimalPlaces).fromDisplay(x).replaceAll('$', ''),
    }
  }

  function percentConfig(decimalPlaces: number): InputOperations<BigNumber | ''> {
    return {
      ...decimalConfig(decimalPlaces),
      toExternal: (x) => x && BigNumber(x).dividedBy(100),
      fromExternal: (x) => x && x.multipliedBy(100).toString(),
      toDisplay: (x) => (x === '' ? '' : x.startsWith('-') ? `-${x.substring(1)}%` : `${x}%`),
      fromDisplay: (x) => decimalConfig(decimalPlaces).fromDisplay(x).replaceAll('%', ''),
      selectionRangeIsValid: (x, range) => {
        if (range[0] !== range[1]) {
          return true
        } else if (!range[0] || !range[1]) {
          return true
        } else {
          return range[0] !== x.length
        }
      },
      resetSelectionRange: (x) => x.length - 1,
    }
  }

  const dateConfig: InputOperations<string> = {
    ...defaultConfig,
    isAcceptableAsIntermediateValue: (x) => /^\d*$/.test(x.replaceAll('/', '')),
    isAcceptableAsExternalValue: (x) => /^\d*$/.test(x.replaceAll('/', '')),
  }

  function getTypeConfig(props: {
    type?: InputType
    decimalPlaces?: number
  }): InputOperations<any> {
    if (!props.type || props.type === 'text') {
      return defaultConfig
    } else if (props.type === 'currency') {
      return currencyConfig(props.decimalPlaces ?? 2)
    } else if (props.type === 'decimal') {
      return decimalConfig(props.decimalPlaces ?? 2)
    } else if (props.type === 'percent') {
      return percentConfig(props.decimalPlaces ?? 2)
    } else if (props.type === 'date') {
      return dateConfig
    } else {
      return unreachable(props.type)
    }
  }

  export function getInitialDisplayValue(props: TypeProps) {
    const config = getTypeConfig(props)
    return config.toDisplay(config.fromExternal(props.value))
  }

  export function rewriteSelectionIndex(props: TypeProps, el: HTMLInputElement) {
    setTimeout(() => {
      const config = getTypeConfig(props)
      if (!config.selectionRangeIsValid(el.value, [el.selectionStart, el.selectionEnd])) {
        const newSelectionIndex = config.resetSelectionRange(el.value)
        el.setSelectionRange(newSelectionIndex, newSelectionIndex)
      }
    }, 20)
  }

  type FormattedChangeHandlerProps = {
    update: (newValue: any) => void
    setDisplayValue: (newValue: string) => void
  }
  export function useFormattedChangeHandler(
    props: TypeProps,
    { update, setDisplayValue }: FormattedChangeHandlerProps,
  ) {
    return useMemo(() => {
      return (e: ChangeEvent<HTMLInputElement>) => {
        const config = getTypeConfig(props)
        const internalValue = config.fromDisplay(e.target.value)
        if (!config.isAcceptableAsIntermediateValue(internalValue)) {
          // report
        } else {
          const displayValue = config.toDisplay(internalValue)
          setDisplayValue(displayValue)
          rewriteSelectionIndex(props, e.target)
          if (!config.isAcceptableAsExternalValue(internalValue)) {
            // report
          } else {
            update(config.toExternal(internalValue))
          }
        }
      }
    }, [update, setDisplayValue, props])
  }

  export function usePreInputHandler(props: TypeProps) {
    const decimalPlaces = 'decimalPlaces' in props ? props.decimalPlaces : null
    return useMemo(() => {
      return (e: any) => {
        const config = getTypeConfig({
          type: props.type,
          decimalPlaces: decimalPlaces ?? undefined,
        })
        const likelyNextValue = config.fromDisplay(
          e.target.value.substring(0, e.target.selectionStart) +
            (e.data ?? '') +
            e.target.value.substring(e.target.selectionEnd),
        )

        if (!config.isAcceptableAsIntermediateValue(likelyNextValue)) {
          e.preventDefault()
        }
      }
    }, [props.type, decimalPlaces])
  }

  // ------- Utils

  export function useDefaultAlignment(type: InputType): 'right' | 'left' {
    const sz = useScreenSize()
    if (sz === 'sm') return 'left'

    if (!type || type === 'text') {
      return 'left'
    } else if (type === 'currency') {
      return 'right'
    } else if (type === 'decimal') {
      return 'right'
    } else if (type === 'percent') {
      return 'right'
    } else if (type === 'date') {
      return 'left'
    } else {
      return unreachable(type)
    }
  }

  export function getPlaceholderForType(type: InputType): string | null {
    if (!type || type === 'text') {
      return null
    } else if (type === 'currency') {
      return '$0'
    } else if (type === 'decimal') {
      return '1'
    } else if (type === 'percent') {
      return '0%'
    } else if (type === 'date') {
      return null
    } else {
      return unreachable(type)
    }
  }

  export function getInputModeForType(
    type: InputType,
  ): HTMLAttributes<HTMLInputElement>['inputMode'] {
    if (!type || type === 'text') {
      return 'none'
    } else if (type === 'currency' || type === 'decimal' || type === 'percent') {
      return 'decimal'
    } else if (type === 'date') {
      return 'text'
    } else {
      return unreachable(type)
    }
  }

  export function getMaxLengthForType(type: InputType) {
    switch (type) {
      case undefined:
      case 'text':
      case 'decimal':
      case 'percent':
        return undefined
      case 'currency':
        return 12
      case 'date':
        return 10
      default:
        return unreachable(type)
    }
  }

  export function setDefaultSelectionForType(type: InputType, el: HTMLInputElement) {
    if (type === 'currency' || type === 'decimal' || type === 'percent' || type === 'date') {
      el.setSelectionRange(0, el.value.length)
    }
  }

  export function shouldSync(props: TypeProps, displayValue: string) {
    if (typeof props.value === 'string') {
      return props.value !== displayValue
    } else {
      const config = getTypeConfig(props)
      return !props.value.eq(BigNumber(config.toExternal(config.fromDisplay(displayValue))))
    }
  }
}

import { F, Zero } from 'msutils'

export namespace SetCommissionFormUtils {
  export const schema = F.Group({
    spec: {
      maximumCommission: F.Decimal2({ initValue: Zero, required: true }),
      newCommission: F.Decimal2({ initValue: Zero, required: true }),
      description: F.Text({ required: true }),
      sections: F.List({
        spec: F.Group({
          spec: {
            sectionId: F.Text(),
            included: F.Toggle(),
          },
        }),
      }),
    },
  })
}

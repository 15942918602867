/* eslint-disable mosaic-js/unnamed-args */
import { ReactNode, useCallback, FC, useRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { t } from 'content'
import { getErrorFn, standardizeError } from './useErrorHandling'

export type FallbackProps = {
  error: Error
  resetErrorBoundary: () => void
}

type Props = {
  Fallback?: FC<FallbackProps>
  name?: string
  children: ReactNode
}

function BoundaryInternal({ Fallback: CustomFallback, name, children }: Props) {
  const errorFn = useRef(getErrorFn()).current
  const DefaultFallback = useCallback(() => <>{t('An unexpected error has occurred')}</>, [])

  return (
    <ErrorBoundary
      FallbackComponent={CustomFallback ?? DefaultFallback}
      onError={(x) =>
        errorFn(standardizeError(x).fromBoundary(name ? `${name}Boundary` : 'UnnamedBoundary'))
      }
    >
      {children}
    </ErrorBoundary>
  )
}

function wrap<T extends object>(
  Component: FC<T>,
  name: string,
  CustomFallback?: FC<FallbackProps>,
): FC<T> {
  return function Inner(props) {
    return (
      <BoundaryInternal Fallback={CustomFallback} name={name}>
        <Component {...props} />
      </BoundaryInternal>
    )
  }
}

export const Boundary = Object.assign(BoundaryInternal, { wrap })

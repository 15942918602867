import { useState } from 'react'
import { cn } from 'msutils/classnames'
import { useOnChange } from 'msutils'
import { InputUtils } from '../utils'
import { compassId } from '../../_internal/utils'
import useScreenSize from '../../theme/useScreenSize'
import { TextInputUtils } from './utils'

type Props = TextInputUtils.TypeProps &
  InputUtils.InputBaseProps &
  InputUtils.TextInputProps & {
    disableKeyboard?: boolean
    autoselectOnFocus?: boolean
    align?: 'right' | 'left'
  }

export default function TextInput(props: Props) {
  const { update, focus, blur } = InputUtils.useStateProps<any>(props)
  const [displayValue, setDisplayValue] = useState(TextInputUtils.getInitialDisplayValue(props))
  useOnChange([props.value], () => {
    if (TextInputUtils.shouldSync(props, displayValue)) {
      setDisplayValue(TextInputUtils.getInitialDisplayValue(props))
    }
  })

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null)
  const defaultAlignment = TextInputUtils.useDefaultAlignment(props.type)
  const sz = useScreenSize()
  const formattedChangeHandler = TextInputUtils.useFormattedChangeHandler(props, {
    update,
    setDisplayValue,
  })
  const preInputHandler = TextInputUtils.usePreInputHandler(props)

  const align = sz === 'sm' ? 'left' : props.align ?? defaultAlignment
  const isFocused = document.activeElement === inputRef

  if (props.hidden) return null
  return (
    <InputUtils.InputBase
      title={props.title}
      subtitle={props.subtitle}
      theme={props.theme}
      disabled={props.disabled ?? false}
      optional={props.optional}
      annotation={
        props.error && !isFocused
          ? {
              icon: 'exclamation-in-circle',
              iconHeight: 12,
              message: props.error,
              borderWidth: 2,
              borderColor: 'outline-th-red-warning',
              textColor: 'text-th-red-warning',
            }
          : undefined
      }
    >
      <InputUtils.InputContainer
        inputEl={inputRef}
        isEmpty={!displayValue}
        text={displayValue}
        align={props.align ?? defaultAlignment}
        placeholder={
          props.placeholder ?? TextInputUtils.getPlaceholderForType(props.type) ?? undefined
        }
      >
        <input
          type="text"
          value={displayValue}
          ref={setInputRef}
          inputMode={TextInputUtils.getInputModeForType(props.type)}
          disabled={props.disabled}
          readOnly={props.disableKeyboard}
          onBeforeInput={preInputHandler}
          onChange={formattedChangeHandler}
          maxLength={TextInputUtils.getMaxLengthForType(props.type)}
          onKeyDown={(e) => {
            if (e.code === 'Escape' || e.code === 'Enter') {
              inputRef?.blur()
              e.stopPropagation()
            }
          }}
          className={cn(
            compassId('text-input'),
            compassId('text-input', props.type ?? 'text'),
            InputUtils.InputClassnames,
            'border-0 absolute inset-0 bg-transparent p-0',
            align === 'right' && 'text-right',
          )}
          onFocus={(e) => {
            focus?.()
            TextInputUtils.setDefaultSelectionForType(props.type, e.target)
          }}
          onBlur={blur}
        />
      </InputUtils.InputContainer>
    </InputUtils.InputBase>
  )
}

import { useMemo } from 'react'
import Image from 'next/image'
import XactLogo from 'assets/xact-logo.svg'
import { useAnalyticsContext } from 'root/global'
import { t } from 'content'
import { F, Format, MSArray, random, Zero } from 'msutils'
import useScreenSize from 'compass/theme/useScreenSize'
import { MSForm } from 'utils/form'
import { Mutator } from 'utils/form-input'
import useFormProps from 'utils/useFormProps'
import SetCommissionForm from 'components/forms/SetCommissionForm'
import BigNumber from 'bignumber.js'
import EstimateItemsInput, { EstimateItemsInputUtils } from 'components/inputs/EstimateItemsInput'
import { MSLayout } from 'utils/layout'
import { Action } from 'utils/actions'
import { EstimateUtils } from 'features/estimates/utils'
import { EstimateInputUtils as Utils } from '../utils'
import { PrefillFromEstimate } from './PrefillFromEstimate'
import Preview from './Preview'
import ImportEstimateFromXactRemodelForm from './ImportEstimateFromXactRemodelForm'
import SetMarkupModal from './SetMarkupModal'
import { SectionInput } from './component-utils'

type Props = {
  state: F.Input<typeof Utils.schema>
  calculationContext: EstimateItemsInputUtils.EstimateCalculationContext
  copy: Utils.CopySpec
  saveDraft: Mutator<any>
  autosave: Mutator<any>
  disableAddSection: boolean
  context: Utils.Context
  pageNumber: `${number} / ${number}`
  capabilities: EstimateUtils.Capability[]
  variant: 'project' | 'change-order'
}

export function AmountsPage({
  state,
  calculationContext,
  saveDraft,
  disableAddSection,
  copy,
  autosave,
  context,
  pageNumber,
  capabilities,
  variant,
}: Props) {
  const { back, push } = Utils.pageManager.useContext()
  const { trigger, error } = F.useSubgroupValidation(Utils.schema, state, ['lineItems'])
  const sz = useScreenSize()
  const previewFormProps = useFormProps()
  const { allowCreateBufferedDiscounts } = useAnalyticsContext().flags

  const defaultMarkupMultiplier = useMemo(
    () => BigNumber(context.generalProjectConfig.default_markup_multiplier),
    [context.generalProjectConfig],
  )
  const cost = calculationContext.getCost(state.lineItems)
  const total = calculationContext.getL4Amount(state.lineItems)
  const hasCommission = MSArray.isNonEmpty(
    EstimateItemsInputUtils.getAllItems(state.lineItems).filter((x) => x.isCommission.value),
  )
  const commission = calculationContext.getCommission(state.lineItems)

  const totalsMetrics = [
    capabilities.includes('view-markup') && { k: t('Cost'), v: Format.currency(cost) },
    hasCommission && { k: t('Commission'), v: Format.currency(commission) },
    capabilities.includes('view-markup') && {
      k: t('Profit margin'),
      v: Format.currencyRatio(total.minus((cost ?? Zero).plus(commission ?? Zero)), total),
    },
    { k: t('Estimate total'), v: Format.currency(total), bold: true },
  ]

  const footer = !state.lineItems.children.at(0)?.isSection.value && (
    <MSLayout.SummaryMetricsBar values={totalsMetrics} />
  )
  const saveDraftA = Action.mutation(copy.saveDraft, {
    qualify: () => (autosave.isLoading ? Utils.waitForAutoSaveMessage : {}),
    mutate: () => saveDraft.mutateAsync(),
    theme: 'secondary',
  })

  const setMarkupForLineItemsA = Action.form(t('Set markup for all items'), {
    Form: SetMarkupModal,
    qualify: () => ({ lineItems: state.lineItems }),
  })

  const importFromXactA = Action.form(t('Import from XactRemodel'), {
    Form: ImportEstimateFromXactRemodelForm,
    customIconDISCOURAGED: <Image src={XactLogo} alt="xact" />,
    qualify: () => ({
      onSuccess: (input) => {
        state.lineItems.children._controller.update(EstimateItemsInputUtils.fromXactApi(input))
      },
    }),
  })

  const setCommissionA = Action.form(t('Set commission'), {
    Form: SetCommissionForm,
    qualify: () => ({ state }),
  })

  const didAddReversedDiscount = () => {
    state.showMarkup.update(false)
    state.showUnitCosts.update(false)
    state.showSubSectionPricing.update(false)
  }

  return (
    <MSForm.Body
      title={copy.title}
      warnOnClose={state._controller.hasChanged}
      pageName={t('Line items')}
      pageNumber={pageNumber}
      autosave={{ isSaved: !state._controller.hasChanged, isLoading: autosave.isLoading }}
      error={saveDraft.error ?? error}
      back={back}
      footer={footer}
      inlineFooter={footer}
      actions={[
        Action.more(
          MSArray.collapse([
            capabilities.includes('edit-structure') && importFromXactA,
            saveDraftA,
            Action.button(t('Preview'), { onClick: previewFormProps.setActive }),
            capabilities.includes('edit-structure') && setMarkupForLineItemsA,
          ]),
          {
            qualify: () => sz === 'sm',
          },
        ),
        Action.hidden(saveDraftA, sz === 'sm'),
        Action.next(t('Additional details'), {
          onClick: () => trigger(() => push('additional-details')),
        }),
      ]}
    >
      <MSForm.Panels
        rightContent={
          <MSForm.ComponentOrDrawer {...previewFormProps} title={t('Preview')}>
            <Preview
              copy={copy}
              state={state}
              calculationContext={calculationContext}
              showFormatOptions={capabilities.includes('edit-structure')}
            />
          </MSForm.ComponentOrDrawer>
        }
      >
        <div className="flex gap-2 justify-between">
          {capabilities.includes('edit-structure') ? (
            <PrefillFromEstimate variant={variant} state={state} />
          ) : (
            // eslint-disable-next-line mosaic-js/no-useless-div
            <div />
          )}
          {sz !== 'sm' && (
            <Action.Mount
              {...Action.dropdown(t('More...'), {
                theme: 'text',
                options: MSArray.collapse([
                  capabilities.includes('edit-structure') && importFromXactA,
                  Action.divider,
                  capabilities.includes('edit-structure') &&
                    Action.button(t('Add section'), {
                      qualify: () => !disableAddSection,
                      onClick: () => {
                        if (state.lineItems.children.at(0)?.isSection.value) {
                          state.lineItems.children._controller.append({
                            type: 'group',
                            description: 'Untitled section',
                            listId: random(),
                            isSection: true,
                            children: [
                              { listId: random(), children: [], markup: defaultMarkupMultiplier },
                            ],
                          })
                        } else {
                          state.lineItems._controller.update({
                            type: 'group',
                            description: '--section--',
                            isSection: true,
                            children: [
                              {
                                ...EstimateItemsInputUtils.getRowInitFromInput(state.lineItems),
                                listId: random(),
                                description: 'Untitled section 1',
                                isSection: true,
                              },
                              {
                                type: 'group',
                                description: 'Untitled section 2',
                                listId: random(),
                                isSection: true,
                                children: [
                                  {
                                    listId: random(),
                                    children: [],
                                    markup: defaultMarkupMultiplier,
                                  },
                                ],
                              },
                            ],
                          })
                        }
                      },
                    }),
                  capabilities.includes('edit-structure') && setMarkupForLineItemsA,
                  allowCreateBufferedDiscounts &&
                    state.lineItems.children.at(0)?.isSection.value &&
                    setCommissionA,
                ]),
              })}
            />
          )}
        </div>
        {state.lineItems.children.at(0)?.isSection.value ? (
          <SectionInput
            state={state.lineItems}
            hideTotal={state.hideEstimateTotal.value}
            calculationContext={calculationContext}
            generalProjectConfig={context.generalProjectConfig}
            didAddReversedDiscount={didAddReversedDiscount}
            capabilities={capabilities}
          />
        ) : (
          <EstimateItemsInput
            state={state.lineItems}
            calculationContext={calculationContext}
            generalProjectConfig={context.generalProjectConfig}
            didAddReversedDiscount={didAddReversedDiscount}
            capabilities={capabilities}
          />
        )}
      </MSForm.Panels>
    </MSForm.Body>
  )
}

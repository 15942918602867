/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListItemsOrdering = (typeof ListItemsOrdering)[keyof typeof ListItemsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListItemsOrdering = {
  name: 'name',
  '-name': '-name',
  type: 'type',
  '-type': '-type',
  description: 'description',
  '-description': '-description',
  unit_value: 'unit_value',
  '-unit_value': '-unit_value',
  qbo_class_source_id: 'qbo_class_source_id',
  '-qbo_class_source_id': '-qbo_class_source_id',
  qbo_income_account_source_id: 'qbo_income_account_source_id',
  '-qbo_income_account_source_id': '-qbo_income_account_source_id',
  qbo_expense_account_source_id: 'qbo_expense_account_source_id',
  '-qbo_expense_account_source_id': '-qbo_expense_account_source_id',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

import { useState } from 'react'
import { ErrorIcon } from 'compass-local/legacy/icons'
import InputBase, { InputBaseUtils } from 'compass-local/InputBase'
import { getInputProps, InputProps } from 'compass-local/utils/InputProps'
import { useDebouncedFunction } from 'compass-local/utils/useDebouncedFunction'
import { useOnChange } from 'msutils'
import { TextAreaUtils } from '../utils'

type Props = InputProps<string> &
  InputBaseUtils.ExternalProps & {
    debounceUpdates?: boolean
    placeholder?: string
    maxHeight?: number
    minHeight?: number
    autofocus?: boolean
    maxLength?: number
  }

export default function TextArea(props: Props) {
  const {
    value,
    update,
    focus,
    blur,
    error,
    willUpdate,
    didUpdate,
    disabled,
    placeholder,
    maxHeight,
    minHeight,
    maxLength,
    autofocus,
    debounceUpdates,
  } = getInputProps(props)
  const [immediateValue, setImmediateValue] = useState(value)
  const [inputRef, setInputRef] = useState<HTMLTextAreaElement | null>(null)
  TextAreaUtils.useDynamicResize({
    inputRef,
    minHeight,
    maxHeight,
    value: debounceUpdates ? immediateValue : value,
  })

  // if `value` changes, not from a pushUpdates (setExternalValue), then we should re-sync outer value and inner value

  const pushUpdates = async (oldValue: string, newValue: string) => {
    if (willUpdate) await willUpdate(oldValue, newValue)
    update?.(newValue)
    didUpdate?.(oldValue, newValue)
  }
  const debouncedPushUpdate = useDebouncedFunction(pushUpdates, 500)
  useOnChange([value], () => {
    if (value !== immediateValue) {
      setImmediateValue(value)
    }
  })

  const updateInLifecycle = (newValue: string) => {
    if (debounceUpdates) {
      debouncedPushUpdate(value, newValue)
      setImmediateValue(newValue)
    } else {
      pushUpdates(value, newValue)
    }
  }

  return (
    <InputBase
      {...props}
      lineHeight="leading-none"
      cursorType="cursor-text"
      isFocused={document.activeElement === inputRef}
      setFocus={() => inputRef?.focus()}
      {...(error && {
        annotation: {
          active: true,
          message: error,
          color: 'red',
          icon: <ErrorIcon height={12} />,
        },
      })}
    >
      <textarea
        ref={setInputRef}
        // mb for bottom line height
        className="placeholder:text-th-text-hint placeholder:truncate -mb-0.5"
        style={{
          ...TextAreaUtils.propsOverride,
        }}
        value={(debounceUpdates ? immediateValue : value) ?? ''}
        autoFocus={autofocus}
        onChange={(e) => updateInLifecycle(e.target.value)}
        onFocus={() => focus?.()}
        onBlur={() => {
          debouncedPushUpdate.flush()
          blur?.()
        }}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
      />
    </InputBase>
  )
}

import { CompassTypes } from 'compass-local'

export function onClickHref(props: {
  href: CompassTypes['href']
  push: (href: CompassTypes['href']) => void
}) {
  return () => {
    props.push(props.href)
  }
}

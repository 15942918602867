/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListPhotosOrdering = (typeof ListPhotosOrdering)[keyof typeof ListPhotosOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListPhotosOrdering = {
  creator_name: 'creator_name',
  '-creator_name': '-creator_name',
  externally_created_at: 'externally_created_at',
  '-externally_created_at': '-externally_created_at',
  original_url: 'original_url',
  '-original_url': '-original_url',
  thumbnail_url: 'thumbnail_url',
  '-thumbnail_url': '-thumbnail_url',
  description: 'description',
  '-description': '-description',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

import { Cb } from 'cb'
import { t } from 'content'
import { Module } from 'modules/routes'
import Typography from 'compass/data/Typography'
import { Action } from 'utils/actions'

type Props = {
  details: NonNullable<Cb.TaskSignUnconditionalLienWaiverDetails>
  close: () => void
}

export default function SignUnconditionalLienWaiver({ details, close }: Props) {
  return (
    <div className="flex justify-between items-center gap-4 px-4 py-3">
      <Typography>
        {t(
          'Invoice #{{ X1 }} for {{ X2 }} has been paid. Sign the unconditional lien waiver now.',
          {
            X1: details.invoice_number,
            X2: details.project_name,
          },
        )}
      </Typography>
      <div className="w-min">
        <Action.Mount
          {...Action.href(t('Sign'), {
            theme: 'orange-text',
            href: Module(`/invoices/${details.invoice_id}`).href,
            onClick: close,
          })}
        />
      </div>
    </div>
  )
}

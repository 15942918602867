import { ReactNode } from 'react'
import { cn } from 'msutils/classnames'
import { BaseLayout } from '../../baseLayout'
import Typography from '../../data/Typography'
import InteractionTarget from '../InteractionTarget'

type RadioOptionTheme = {
  selectedColor: string
  unselectedColor: string
  disabledColor: string
  inset: number
  targetHeight: number
  selectedOutlineColor: string
  unselectedOutlineColor: string
  borderWidth: number
  outlineWidth: number
}

type Props = {
  isSelected: boolean
  setSelected?: () => void
  focus?: () => void
  blur?: () => void
  disabled?: boolean | string
  // ui props
  title: string
  theme: RadioOptionTheme
  caption?: string
  badge?: ReactNode
  widget?: ReactNode
}

export default function RadioOption({
  isSelected,
  setSelected,
  focus,
  blur,
  disabled,
  title,
  theme,
  caption,
  badge,
  widget,
}: Props) {
  return (
    <InteractionTarget onClick={setSelected} disabled={disabled} onFocus={focus} onBlur={blur}>
      <BaseLayout.View
        inset={theme.inset}
        border={`${theme.borderWidth}px solid ${
          isSelected ? theme.selectedOutlineColor : theme.unselectedOutlineColor
        }`}
        outline={`${theme.outlineWidth}px solid ${
          isSelected ? theme.selectedOutlineColor : 'transparent'
        }`}
        borderRadius={1}
      >
        <BaseLayout.HStack gap={4} justify="between">
          <BaseLayout.HStack gap={2.5}>
            <BaseLayout.NativeDiv
              border={`2px solid ${isSelected ? theme.selectedColor : theme.unselectedColor}`}
              borderRadius={10000}
              position="relative"
              height={theme.targetHeight}
              width={theme.targetHeight}
              background={disabled ? theme.disabledColor : undefined}
              flexShrink={0}
            >
              <BaseLayout.NativeDiv
                background={isSelected ? theme.selectedColor : 'transparent'}
                borderRadius={10000}
                inset={2}
                position="absolute"
              />
            </BaseLayout.NativeDiv>
            <BaseLayout.VStack gap={1}>
              <BaseLayout.HStack gap={2}>
                <Typography
                  variant={isSelected ? 'bodybold' : 'body'}
                  className={cn(disabled && 'text-th-text-disabled')}
                >
                  {title}
                </Typography>
                {badge}
              </BaseLayout.HStack>
              {caption && (
                <Typography variant="caption" className={cn(disabled && 'text-th-text-disabled')}>
                  {caption}
                </Typography>
              )}
            </BaseLayout.VStack>
          </BaseLayout.HStack>
          {widget}
        </BaseLayout.HStack>
      </BaseLayout.View>
    </InteractionTarget>
  )
}

import { useMemo, useRef } from 'react'
import debounce from 'lodash.debounce'

export function useDebouncedFunction<TArgs extends any[], TReturn>(
  fn: (...args: TArgs) => TReturn,
  timeout: number = 500,
) {
  const stableFn = useRef(fn).current
  return useMemo(() => debounce(stableFn, timeout), [stableFn, timeout])
}

import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { STRIPE_API_KEY } from 'env'
import { Cb, Q } from 'cb'
import { loadConnectAndInitialize, StripeConnectInstance } from '@stripe/connect-js'
import { useOnChange } from 'msutils'

type TStripeConnectContext = {
  getStripeConnectInstance: () => StripeConnectInstance | null
}

const StripeConnectContext = createContext<TStripeConnectContext | undefined>(undefined)

export const useStripeConnectContext = () => {
  const context = useContext(StripeConnectContext)
  if (context === undefined)
    throw new Error('StripeConnectContext must be used within an StripeConnectProvider')
  return context
}

type Props = {
  children: ReactNode
}

export default function StripeConnectProvider({ children }: Props) {
  const [stripeConnectInstance, setStripeConnectInstance] = useState<StripeConnectInstance | null>(
    null,
  )
  const [stripeConnectInstanceRequested, setStripeConnectInstanceRequested] = useState(false)
  const stripeConnectedAccountLinkQ = Cb.useListStripeConnectedAccountLinks({
    select: Q.opt,
  })

  // Initializing Stripe is very brittle. If fetching the client secret fails
  // when it's initialized (as is the case before the business has a Stripe
  // account), it won't recover. If it's initialized more than once, it will
  // also start acting weirdly. We must load it exactly once and only once
  // the Stripe account has been created.
  useOnChange([stripeConnectInstanceRequested, stripeConnectedAccountLinkQ], () => {
    if (!stripeConnectInstanceRequested) return
    if (stripeConnectInstance) return
    if (stripeConnectedAccountLinkQ.status !== 'success') return
    if (!stripeConnectedAccountLinkQ.data) return

    setStripeConnectInstance(
      loadConnectAndInitialize({
        publishableKey: STRIPE_API_KEY,
        fetchClientSecret: async () => {
          const res = await Cb.generateStripeOnboardingClientSecret({})
          return res.client_secret
        },
        appearance: {
          variables: {
            borderRadius: '4px',
            spacingUnit: '12px',
            colorPrimary: '#F87128',
            fontSizeBase: '16px',
            fontFamily: 'Inter, system-ui, sans-serif',
            buttonPrimaryColorText: '#FFFFFF',
          },
        },
        fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap' }],
      }),
    )
  })

  const getStripeConnectInstance = useCallback(() => {
    setStripeConnectInstanceRequested(true)
    return stripeConnectInstance
  }, [stripeConnectInstance])

  const ctxValue = useMemo(() => ({ getStripeConnectInstance }), [getStripeConnectInstance])

  return <StripeConnectContext.Provider value={ctxValue}>{children}</StripeConnectContext.Provider>
}

import { Cb } from 'cb'
import { Action } from 'utils/actions'
import TextInputDONOTUSE from 'compass-local/TextInput'
import { t } from 'content'
import { F } from 'msutils'
import { Mutator } from 'utils/form-input'
import { MSForm } from 'utils/form'
import { AdditionalContactInputUtils as Utils } from '../utils'

type Props = {
  state: F.Input<typeof Utils.schema>
  submit: Mutator<Cb.AdditionalContact>
  copy: Utils.CopySpec
}

export default function AdditionalContactInput(props: Props) {
  const { state, submit, copy } = props

  return (
    <MSForm.Body
      title={copy.title}
      size="small"
      error={submit.error?.message}
      warnOnClose={state._controller.hasChanged}
      actions={[Action.mutation(copy.submit, { mutate: submit.mutateAsync })]}
    >
      <TextInputDONOTUSE
        title={t('Name')}
        placeholder={t('Enter contact name')}
        {...F.props(state.name)}
      />
      <TextInputDONOTUSE
        title={t('Job title')}
        placeholder={t('Enter contact job title')}
        optional
        {...F.props(state.jobTitle)}
      />
      <TextInputDONOTUSE
        type="email"
        placeholder={t('Enter contact email')}
        {...F.props(state.email)}
        optional
      />
      <TextInputDONOTUSE
        type="phone"
        placeholder={t('Enter contact phone number')}
        {...F.props(state.phone)}
        optional
      />
    </MSForm.Body>
  )
}

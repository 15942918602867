import { useState } from 'react'
import { useAnalyticsContext } from 'root/global'
import Metrics from 'compass-local/Metrics'
import BaseMetric from 'compass-local/BaseMetric'
import { toSentenceCase } from 'msutils'
import Delayed from 'utils/Delayed'
import Codeblock from '../../components/Codeblock'
import { defineModule } from '../../utils'

function Module() {
  const { flags, setFlagOverrides } = useAnalyticsContext()
  const [search, setSearch] = useState('')

  return (
    <div className="vflex [&>*]:py-1 [&>*:nth-child(odd)]:bg-th-warmgrey-2">
      <Delayed delayMilliseconds={200}>
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search..."
          className="border !bg-th-bg-white w-[220px]"
          autoFocus
        />
      </Delayed>
      <Metrics layout="h">
        {Object.keys(flags)
          .filter((x) => search === '' || x.toLowerCase().includes(search.toLowerCase()))
          .map((f) => (
            <BaseMetric
              key={toSentenceCase(f)}
              k={toSentenceCase(f)}
              v={
                <div
                  onClick={() => {
                    if (typeof flags[f as keyof typeof flags] === 'boolean') {
                      setFlagOverrides((oldValue) => ({
                        ...oldValue,
                        [f]: !flags[f as keyof typeof flags],
                      }))
                    }
                  }}
                >
                  <Codeblock disableCopy>{String(flags[f as keyof typeof flags])}</Codeblock>
                </div>
              }
            />
          ))}
      </Metrics>
    </div>
  )
}

export default defineModule({
  id: 'flags',
  component: <Module />,
})

import { useState } from 'react'
import { ConnectComponentsProvider, ConnectAccountOnboarding } from '@stripe/react-connect-js'
import SupportLink from 'components/misc/SupportLink'
import { ClosableLayoutDONOTUSE } from 'utils/ClosableLayout'
import { t } from 'content'
import { useStripeConnectContext } from 'root/global/Provider/StripeConnectProvider'
import Spinner from 'compass-local/Spinner'
import { OnboardAccountFormUtils } from '../utils'

export default OnboardAccountFormUtils.fc.Form(() => {
  const [stripeLoadingStarted, setStripeLoadingStarted] = useState(false)
  const { onSuccess } = OnboardAccountFormUtils.fc.useContext()
  const { getStripeConnectInstance } = useStripeConnectContext()
  const stripeConnectInstance = getStripeConnectInstance()

  return (
    <ClosableLayoutDONOTUSE.Body title={t('Validate business information')}>
      {stripeConnectInstance ? (
        <div className="vflex items-center gap-10">
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectAccountOnboarding
              onLoaderStart={() => setStripeLoadingStarted(true)}
              onExit={onSuccess}
              fullTermsOfServiceUrl="https://www.trybeam.com/terms-of-use"
              privacyPolicyUrl="https://www.trybeam.com/privacy-policy"
              collectionOptions={{
                fields: 'currently_due',
                futureRequirements: 'omit',
              }}
            />
          </ConnectComponentsProvider>
          {stripeLoadingStarted && <SupportLink tKey="Need help? <X>Please contact support</X>." />}
        </div>
      ) : (
        <Spinner fill size="large" />
      )}
    </ClosableLayoutDONOTUSE.Body>
  )
})

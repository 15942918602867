import { t } from 'content'
import { Cb } from 'cb'
import { download, serializeFilename } from 'utils/file'
import { unhandled } from 'msutils/misc'
import { MSArray } from 'msutils'
import BigNumber from 'bignumber.js'

export namespace EstimateUtils {
  export function mapExpenseCostCodeToIncome(costCodeId: string | null, costCodes: Cb.CostCode[]) {
    const expenseCostCode = costCodes.find((cc) => cc.id === costCodeId)
    return expenseCostCode?.mapped_to_income_cost_code_id
      ? costCodes.find((cc) => cc.id === expenseCostCode.mapped_to_income_cost_code_id)
      : null
  }

  export function computeRowTotalPreview({
    quantity,
    unitCost,
    markup,
  }: {
    quantity: string
    unitCost: string
    markup: string
  }) {
    const unitCostNum = BigNumber(BigNumber(unitCost || '0').toFixed(2))
    // Why do we support a quantity of '-'?
    const unitQuantityNum = BigNumber(
      BigNumber(!quantity || quantity === '-' ? '0' : quantity).toFixed(2),
    )
    const markupMultiplierNum = BigNumber(
      BigNumber(BigNumber(1).plus(BigNumber(markup || '0'))).toFixed(2),
    )
    return BigNumber(
      BigNumber(unitCostNum.multipliedBy(markupMultiplierNum).toFixed(2))
        .multipliedBy(unitQuantityNum)
        .toFixed(2),
    )
  }

  export function prefillContractLineItems({
    estimate,
    costCodes,
  }: {
    estimate: Cb.Estimate
    costCodes: Cb.CostCode[]
  }) {
    return estimate.line_item_nodes.flatMap((g) =>
      g.group_details
        ? g.group_details.hide_line_items || !estimate.show_sub_section_pricing
          ? {
              amount: BigNumber(g.group_details.total_amount),
              description: g.group_details.description,
              costCode: EstimateUtils.mapExpenseCostCodeToIncome(
                g.group_details?.cost_code_id,
                costCodes,
              ),
            }
          : g.children.map((childItem) => ({
              amount: BigNumber(
                EstimateUtils.computeRowTotalPreview({
                  unitCost: childItem.amount_details?.unit_cost ?? '',
                  quantity: childItem.amount_details?.quantity ?? '',
                  markup: BigNumber(childItem.amount_details?.markup_multiplier ?? '0').toFixed(2),
                }).toFixed(2),
              ),
              description: g.group_details?.description
                ? `${g.group_details.description}: ${childItem.amount_details?.description ?? ''}`
                : childItem.amount_details?.description ?? '',
              costCode: EstimateUtils.mapExpenseCostCodeToIncome(
                childItem.amount_details?.cost_code_id ?? null,
                costCodes,
              ),
            }))
        : g.amount_details
        ? {
            amount: BigNumber(
              EstimateUtils.computeRowTotalPreview({
                unitCost: g.amount_details.unit_cost ?? '',
                quantity: g.amount_details.quantity ?? '',
                markup: BigNumber(g.amount_details.markup_multiplier ?? '0').toFixed(2),
              }).toFixed(2),
            ),
            description: g.amount_details.description,
            costCode: EstimateUtils.mapExpenseCostCodeToIncome(
              g.amount_details?.cost_code_id,
              costCodes,
            ),
          }
        : unhandled('Prefill contract from line items error'),
    )
  }

  export function getEstimateSubtitle(estimate: Cb.Estimate) {
    return MSArray.print(
      estimate.line_item_nodes.flatMap((x) => {
        const description =
          x.amount_details?.description ||
          x.children.find((y) => !!y.amount_details?.description)?.amount_details?.description
        return description || []
      }),
    )
  }

  export function downloadEstimate(estimate: Cb.Estimate, file: File) {
    const filename = `estimate_${estimate.number}_for_${estimate.display_name}`
    download(file, serializeFilename(filename))
  }

  export const UnitTypes = [
    { id: 'each', title: t('each') } as const,
    { id: 'ls', title: t('ls') } as const,

    { id: 'ft', title: t('ft') } as const,
    { id: 'lf', title: t('lf') } as const,

    { id: 'sy', title: t('sy') } as const,
    { id: 'acre', title: t('acre') } as const,

    { id: 'sqft', title: t('sqft') } as const,
    { id: 'cuft', title: t('cuft') } as const,
    { id: 'bcy', title: t('bcy') } as const,
    { id: 'tcy', title: t('tcy') } as const,
    { id: 'cy', title: t('cy') } as const,

    { id: 'lbs', title: t('lbs') } as const,
    { id: 'kgs', title: t('kgs') } as const,
    { id: 'tons', title: t('tons') } as const,

    { id: 'hours', title: t('hours') } as const,
    { id: 'days', title: t('days') } as const,
    { id: 'weeks', title: t('weeks') } as const,
    { id: 'months', title: t('months') } as const,
  ]

  // Important: the order in which we do the multiplication and rounding matters.
  // This is to ensure that unit price == (unit cost * markup multiplier) so that
  // the result for each row will be the same regardless of whether markup is
  // being shown to the client or not.
  export function calculateLineItemTotal({
    unitCost,
    markup,
    quantity,
  }: {
    unitCost: BigNumber
    markup: BigNumber
    quantity: BigNumber
  }) {
    return BigNumber(
      BigNumber(unitCost.multipliedBy(markup.plus(1)).toFixed(5))
        .multipliedBy(quantity)
        .toFixed(2),
    )
  }
  export type Capability = 'edit-structure' | 'view-markup'

  export function getCapabilities(employee: Cb.Employee): Capability[] {
    return employee.role === Cb.EmployeeRole.salesperson ? [] : ['edit-structure', 'view-markup']
  }
}

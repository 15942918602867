import { useState } from 'react'
import {
  InputBaseProps,
  InputClassnames,
  InputStateProps,
  TextInputProps,
  useStateProps,
} from 'compass-local/input/utils'
import { cn } from 'msutils/classnames'
import { compassId } from 'compass-local/root/utils'
import { t } from 'content'
import Portal from 'compass-local/root/Portal'
import useScreenSize from 'compass/theme/useScreenSize'
import { TextArea2Utils as Utils } from '../utils'

type Props = InputStateProps<string> &
  InputBaseProps &
  TextInputProps & {
    defaultHeight?: number
  }

export default function TextArea2(props: Props) {
  const { value, update, focus, blur } = useStateProps(props)
  const [inputRef, setInputRef] = useState<HTMLTextAreaElement | null>(null)
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const [frozenValue, setFrozenValue] = useState(value)
  const sz = useScreenSize()
  Utils.useDynamicResize({ inputRef, value })

  const placeholder = props.placeholder ?? t('Enter text...')
  const isFocused = document.activeElement === inputRef

  return (
    <div
      className="relative flex grow items-center"
      style={props.defaultHeight ? { height: props.defaultHeight } : undefined}
      ref={setContainerRef}
    >
      <div
        className={cn(
          InputClassnames,
          isFocused && 'opacity-0',
          !value && 'text-th-text-hint',
          'pointer-events-none px-3 grow clamp-1 whitespace-pre-line',
        )}
      >
        {(isFocused ? frozenValue : value) || placeholder}
      </div>
      <textarea
        value={value}
        onChange={() => undefined}
        onClick={(e) => {
          inputRef?.focus()
          const el = e.target as HTMLTextAreaElement
          inputRef?.setSelectionRange(el.selectionStart ?? null, el.selectionEnd ?? null)
        }}
        placeholder={placeholder}
        className={cn(
          compassId('text-area-ghost'),
          InputClassnames,
          'border-0 py-[11px] placeholder:text-th-text-hint opacity-0 overflow-hidden absolute inset-0',
        )}
      />
      <Portal element={containerRef}>
        <div
          style={
            isFocused
              ? {
                  height: containerRef?.clientHeight ?? 0,
                  width: containerRef?.clientWidth ?? 0,
                }
              : undefined
          }
          className={cn('absolute left-0 top-0', !isFocused && 'w-0 h-0 overflow-hidden')}
        >
          <textarea
            value={value}
            onChange={(e) => update(e.target.value)}
            onFocus={() => {
              focus?.()
              setFrozenValue(value)
            }}
            onBlur={blur}
            onKeyDown={(e) => {
              if (e.code === 'Escape') {
                inputRef?.blur()
                e.stopPropagation()
              }
            }}
            ref={setInputRef}
            placeholder={placeholder}
            style={{
              minWidth: containerRef?.clientWidth ?? 0,
              width: sz === 'sm' ? containerRef?.clientWidth ?? 0 : 400,
            }}
            className={cn(
              compassId('text-area'),
              InputClassnames,
              'py-[9px] placeholder:text-th-text-hint pointer-events-auto border-2 relative z-[1] shadow-lg',
            )}
          />
        </div>
      </Portal>
    </div>
  )
}

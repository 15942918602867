import { ReactNode } from 'react'
import { cn } from 'msutils/classnames'
import { BorderStyle } from 'compass-local/input/utils'
import { compassId } from 'compass-local/root/utils'
import Typography from 'compass/data/Typography'
import { StandardBaseUtils as Utils } from '../utils'

type Props = {
  border: BorderStyle
  title?: string
  maxWidth?: `max-w-[${number}px]`
  children: ReactNode
}

export default function StandardBase({ border, title, maxWidth, children }: Props) {
  return (
    <div className={cn(compassId('input-base', 'standard'), maxWidth, 'vflex gap-2 grow')}>
      {title && <Typography variant="label">{title}</Typography>}
      <div
        className={cn(
          compassId('input-base', 'border'),
          Utils.getBorderClassNames(border),
          'bg-th-bg-white',
        )}
      >
        {children}
      </div>
    </div>
  )
}

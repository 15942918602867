import { useEffect, useState } from 'react'
import { Cb } from 'cb'
import useDebouncedValue from 'utils/useDebouncedValue'

export type Props = {
  title: string
  body: string
  fileId: string | null
}

export default function useEstimateCoverPagePdfPreview(props: Props) {
  const debouncedProps = useDebouncedValue(props, 500)
  const [pdfPreview, setPdfPreview] = useState<File | null>(null)

  const pdfPreviewQ = Cb.useGetEstimateCoverPagePdfPreview({
    params: {
      title: debouncedProps.title,
      body: debouncedProps.body,
      file_id: debouncedProps.fileId ?? undefined,
    },
    select: (x) => x as File,
  })

  useEffect(() => {
    if (pdfPreviewQ.data) setPdfPreview(pdfPreviewQ.data)
  }, [pdfPreviewQ.data])

  return pdfPreview
}

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListNotesOrdering = (typeof ListNotesOrdering)[keyof typeof ListNotesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListNotesOrdering = {
  target_display_name: 'target_display_name',
  '-target_display_name': '-target_display_name',
  timestamp: 'timestamp',
  '-timestamp': '-timestamp',
  content: 'content',
  '-content': '-content',
  creator_employee_name: 'creator_employee_name',
  '-creator_employee_name': '-creator_employee_name',
  has_reply: 'has_reply',
  '-has_reply': '-has_reply',
  is_external: 'is_external',
  '-is_external': '-is_external',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

import { CBTestUtils, Q, Cb, config } from 'cb'
import { getAuth, setAuth } from 'root/utils'

export default [
  {
    name: 'Set up business',
    unauthed: true,
    single: true,
    inputs: [
      {
        id: 'email',
        label: 'Email',
        type: 'string',
        default: `thomas@usebeam.co`,
      },
      {
        id: 'randomizeEmail',
        label: 'Randomize email',
        type: 'boolean',
        default: true,
      },
      {
        id: 'onboardBusiness',
        label: 'Onboard business',
        type: 'boolean',
        default: true,
      },
      {
        id: 'verifyEmail',
        label: 'Verify email',
        type: 'boolean',
        default: true,
      },
    ],
    trigger: async ({ email, randomizeEmail, onboardBusiness, verifyEmail }: any) => {
      const emailSuffix = randomizeEmail ? `+${Math.floor(Math.random() * 10000000)}` : ''
      const processedEmail = `${email.slice(0, email.indexOf('@'))}${emailSuffix}${email.slice(
        email.indexOf('@'),
      )}`
      const { business, auth, user } = await CBTestUtils.setupBusiness({
        props: { email: processedEmail },
      })
      if (onboardBusiness) {
        await CBTestUtils.onboardBusinessToStripe({ business, auth })
        await CBTestUtils.linkBankAccount({ business, auth })
      }
      if (verifyEmail) {
        await Cb.verifyEmail({ user_id: user.id }, config(auth))
      }
      setAuth({ header: auth }, 'local')
      window.location.pathname = '/'
      // eslint-disable-next-line
      await new Promise((res) => setTimeout(res, 1500))
    },
  },
  {
    name: 'Give onboarding intent',
    single: true,
    trigger: async () => {
      const { header: auth } = getAuth() ?? {}
      const business = await Cb.listBusiness(undefined, config(auth)).then(Q.first)
      await Cb.partialUpdateBusiness(business.id, { has_onboarding_intent: true })
      window.location.pathname = '/'
    },
  },
  {
    name: 'Fill out onboarding survey',
    single: true,
    trigger: async () => {
      const { header: auth } = getAuth() ?? {}
      const business = await Cb.listBusiness(undefined, config(auth)).then(Q.first)
      if (auth) {
        await CBTestUtils.createBusinessOnboardingSurvey({ auth, business })
      }
      window.location.pathname = '/'
    },
  },
  {
    name: 'Complete Stripe onboarding',
    single: true,
    trigger: async () => {
      const { header: auth } = getAuth() ?? {}
      const business = await Cb.listBusiness(undefined, config(auth)).then(Q.first)
      if (auth) {
        await CBTestUtils.onboardBusinessToStripe({ business, auth })
      }
      window.location.pathname = '/'
    },
  },
  {
    name: 'Link bank account',
    single: true,
    trigger: async () => {
      const { header: auth } = getAuth() ?? {}
      const business = await Cb.listBusiness(undefined, config(auth)).then(Q.first)
      if (auth) {
        await CBTestUtils.linkBankAccount({ business, auth })
      }
      window.location.pathname = '/'
    },
  },
  {
    name: 'Add project',
    inputs: [
      {
        id: 'name',
        label: 'Name',
        type: 'string',
        default: '19 Walter St',
      },
    ],
    trigger: ({ name, i }: any) => {
      const { header: auth } = getAuth() ?? {}
      const name2 = i > 0 ? `${name} (${i})` : name
      if (auth) {
        CBTestUtils.createProject({ auth, props: { name: name2 } })
      }
    },
  },
  {
    name: 'Create vendor',
    inputs: [
      {
        id: 'name',
        label: 'Name',
        type: 'string',
        default: 'Thomas Electric',
      },
      {
        id: 'phone',
        label: 'Phone',
        type: 'string',
        default: '3333333333',
      },
      {
        id: 'address',
        label: 'Address',
        type: 'string',
        default: '19 Walter St',
      },
    ],
    trigger: async ({ name, phone, address, i }: any) => {
      const { header: auth } = getAuth() ?? {}
      if (auth) {
        const business = await Cb.listBusiness(undefined, config(auth)).then(Q.first)

        CBTestUtils.createVendor({
          auth,
          business,
          props: {
            name: i > 0 ? `${name} (${i})` : name,
            phone,
            address,
          },
        })
      }
    },
  },
  {
    name: 'Create bill',
    inputs: [],
    trigger: async () => {
      const { header: auth } = getAuth() ?? {}
      if (auth) {
        const business = await Cb.listBusiness(undefined, config(auth)).then(Q.first)
        const projectId = window.location.pathname.match(/projects\/([a-z0-9-]*)/)?.at(1)

        CBTestUtils.createBill({
          auth,
          business,
          props: {
            projectId,
          },
        })
      }
    },
  },
  {
    name: 'Create invoice',
    inputs: [],
    trigger: async () => {
      const { header: auth } = getAuth() ?? {}
      if (auth) {
        const business = await Cb.listBusiness(undefined, config(auth)).then(Q.first)
        const projectId = window.location.pathname.match(/projects\/([a-z0-9-]*)/)?.at(1)

        CBTestUtils.createInvoice({
          auth,
          business,
          props: {
            projectId,
          },
        })
      }
    },
  },
  {
    name: 'Create estimate',
    inputs: [],
    trigger: async () => {
      const { header: auth } = getAuth() ?? {}
      if (auth) {
        const business = await Cb.listBusiness(undefined, config(auth)).then(Q.first)

        CBTestUtils.createEstimate({
          auth,
          business,
        })
      }
    },
  },
]

import Divider from 'compass-local/Divider'
import Table, { TableUtils } from 'compass-local/layout/Table'
import Metric from 'compass-local/Metric'
import Metrics from 'compass-local/Metrics'
import Typography from 'compass/data/Typography'
import { Format, Zero } from 'msutils'
import { cn } from 'msutils/classnames'
import { t } from 'content'
import { EstimateItemsInputUtils } from '../EstimateItemsInput'

type Ctx = {
  state: EstimateItemsInputUtils.ItemInput
  calculationContext: EstimateItemsInputUtils.EstimateCalculationContext
  totalColumnEnabled: boolean
  unitCostColumnEnabled: boolean
  unitPriceColumnEnabled: boolean
  quantityColumnEnabled: boolean
  markupColumnEnabled: boolean
  showSubSectionPricing: boolean
}
const col = TableUtils.colSpec<EstimateItemsInputUtils.ItemInput, Ctx>()
const columns = [
  col('Description', {
    size: { maximize: true },
    bold: ({ row }) => row.type.value === 'group',
    accessor: ({ row }) =>
      `${row.description.value || '--'}${
        row.type.value === 'percent-priced' ? ` (${Format.percent(row.fee.value || null)})` : ''
      }`,
  }),
  col('Quantity', {
    dynamic: { omit: (ctx) => !ctx.quantityColumnEnabled },
    align: 'right',
    void: ({ row }) => row.type.value !== 'fixed-priced',
    accessor: ({ row }) =>
      `${Format.decimal(row.quantity.value, 'at_most_3') || null} ${row.unit.value || ''}`,
  }),
  col.currency('Unit cost', {
    dynamic: { omit: (ctx) => !ctx.unitCostColumnEnabled },
    void: ({ row }) => row.type.value !== 'fixed-priced',
    hidden: ({ depth }, ctx) => depth > 0 && !ctx.showSubSectionPricing,
    accessor: ({ row }) => row.unitCost.value || null,
    format: (x) => Format.currency(x, { minDecimalPlaces: 0, maxDecimalPlaces: 5 }),
  }),
  col.percent('Markup', {
    dynamic: { omit: (ctx) => !ctx.markupColumnEnabled },
    void: ({ row }) => row.type.value !== 'fixed-priced',
    hidden: ({ depth }, ctx) => depth > 0 && !ctx.showSubSectionPricing,
    accessor: ({ row }) => row.markup.value || null,
  }),
  col.currency('Unit price', {
    dynamic: { omit: (ctx) => !ctx.unitPriceColumnEnabled },
    void: ({ row }) => row.type.value !== 'fixed-priced',
    hidden: ({ depth }, ctx) => depth > 0 && !ctx.showSubSectionPricing,
    accessor: ({ row }) =>
      (row.unitCost.value || Zero).multipliedBy((row.markup.value || Zero).plus(1)),
    format: (x) => Format.currency(x, { minDecimalPlaces: 0, maxDecimalPlaces: 5 }),
  }),
  col.currency('Total', {
    dynamic: { omit: (ctx) => !ctx.totalColumnEnabled },
    bold: ({ depth }) => depth === 0,
    hidden: ({ depth }, ctx) => depth > 0 && !ctx.showSubSectionPricing,
    accessor: ({ row }, ctx) => {
      return ctx.calculationContext.getLeafNodeAmount(row)
    },
  }),
]

type Props = Ctx & {
  showAsSection: boolean
  showZeroAmountItems: boolean
  additionalInformation: string
  typeName: string
  requireApprovalSignature: boolean
}

export default function SectionPreview(props: Props) {
  const spec = TableUtils.useSpec({
    columns,
    data: props.state.children.filter((x) => !x.postSubtotal.value && !x.isInactive.value),
    subrows: (x) => (x.hideInnerItems.value ? [] : x.children.filter((y) => !y.isInactive.value)),
    initValues: { expanded: true },
    defaultValues: { expanded: true },
    disableCollapse: true,
    hidden: props.showZeroAmountItems
      ? undefined
      : (x) =>
          x.type.value === 'fixed-priced' &&
          x.unitCost.value !== '' &&
          x.quantity.value !== '' &&
          (x.unitCost.value.isZero() || x.quantity.value.isZero()),
    ctx: props,
  })

  const estimateTotal = props.calculationContext.getL4Amount(props.state)
  const estimateSubtotal = props.calculationContext.getL3Amount(props.state)

  return (
    <div className="vflex gap-5">
      {props.showAsSection && (
        <Typography variant="title">{props.state.description.value}</Typography>
      )}
      <div className="vflex gap-8">
        <div className="px-5">
          <Table spec={spec} style="preview" />
        </div>
        <div className="flex gap-8">
          <div className="grow vflex gap-2.5">
            {props.additionalInformation && (
              <>
                <Typography variant="bodybold">{t('Additional info')}</Typography>
                <Typography preserveWhitespace>{props.additionalInformation || '--'}</Typography>
              </>
            )}
          </div>
          <div
            className={cn(
              'w-[320px] vflex gap-2.5 p-5 h-fit',
              !props.showAsSection && 'bg-th-bg-slate',
            )}
          >
            {!estimateSubtotal.eq(estimateTotal) &&
              props.state.children.some((x) => x.postSubtotal.value && !x.isInactive.value) && (
                <>
                  <Metrics layout="h" variant="preview/medium">
                    <Metric k="Subtotal" v={Format.currency(estimateSubtotal)} />
                    {props.state.children
                      .filter((x) => x.postSubtotal.value && !x.isInactive.value)
                      .map((item, i) =>
                        item.type.value === 'buffered-discount' ? (
                          props.calculationContext.getLeafNodeAmount(item) < Zero && (
                            <Metric
                              key={i}
                              k={`${item.description.value} (${Format.percent(
                                item.discountMultiplier.value,
                              )})`}
                              v={Format.currency(props.calculationContext.getLeafNodeAmount(item))}
                            />
                          )
                        ) : (
                          <Metric
                            key={i}
                            k={
                              item.type.value === 'percent-priced'
                                ? `${item.description.value} (${Format.percent(
                                    item.fee.value || null,
                                  )})`
                                : item.description.value
                            }
                            v={Format.currency(props.calculationContext.getLeafNodeAmount(item))}
                          />
                        ),
                      )}
                  </Metrics>
                  <Divider height="h-[2px]" color="black" />
                </>
              )}
            <Metric
              k={props.showAsSection ? 'Section total' : `${props.typeName} total`}
              v={Format.currency(estimateTotal)}
              layout="h"
              variant="preview/large"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

import BigNumber from 'bignumber.js'
import { Cb } from 'cb'
import { t } from 'content'
import { MSArray, MSDate, MSTimestamp, Zero } from 'msutils'
import { unreachable } from 'msutils/misc'
import { download, serializeFilename } from 'utils/file'
import { Tier } from 'root/global'

export namespace BillingUtils {
  export type InvoiceStatus =
    | 'void'
    | 'paid'
    | 'processing'
    | 'scheduled'
    | 'partially-paid'
    | 'rejected'
    | 'unsent'
    | 'overdue'
    | 'pending'

  type GetInvoiceStatusProps = {
    approvalState: Cb.InvoiceCreateApprovalState
    paymentAmount: string
    completedPaymentAmount: string
    initiatedPaymentAmount: string // ACH / processing
    startedPaymentAmount: string // scheduled
    dueDate: string
    numEmailsSent: number
    payerIsGuest: boolean
  }

  export function getInvoiceStatus({
    approvalState,
    paymentAmount,
    completedPaymentAmount,
    initiatedPaymentAmount,
    startedPaymentAmount,
    dueDate,
    numEmailsSent,
    payerIsGuest,
  }: GetInvoiceStatusProps): InvoiceStatus {
    if (approvalState === 'void') {
      return 'void'
    } else if (BigNumber(completedPaymentAmount).gte(BigNumber(paymentAmount))) {
      return 'paid'
    } else if (BigNumber(initiatedPaymentAmount).gte(BigNumber(paymentAmount))) {
      return 'processing'
    } else if (BigNumber(startedPaymentAmount).gte(BigNumber(paymentAmount))) {
      return 'scheduled'
    } else if (BigNumber(startedPaymentAmount).gt(Zero)) {
      return 'partially-paid'
    } else if (approvalState === 'rejected') {
      return 'rejected'
    } else if (payerIsGuest && numEmailsSent === 0) {
      return 'unsent'
    } else if (MSDate.init(dueDate).isPast()) {
      return 'overdue'
    } else {
      return 'pending'
    }
  }

  // eslint-disable-next-line mosaic-js/no-unnamed-boolean-arg
  export function getInvoiceStatusFromInvoice(invoice: Cb.Invoice, payerIsGuest: boolean) {
    return getInvoiceStatus({
      approvalState: invoice.approval_state,
      paymentAmount: invoice.payment_amount,
      completedPaymentAmount: invoice.completed_payment_amount,
      initiatedPaymentAmount: invoice.initiated_payment_amount,
      startedPaymentAmount: invoice.started_payment_amount,
      dueDate: invoice.due_date,
      numEmailsSent: invoice.num_guest_emails_sent,
      payerIsGuest,
    })
  }

  // eslint-disable-next-line mosaic-js/no-unnamed-boolean-arg
  export function getInvoiceStatusFromListRow(
    invoiceListRow: Cb.InvoiceListRow,
    payerIsGuest: boolean,
  ) {
    return getInvoiceStatus({
      approvalState: invoiceListRow.approval_state,
      paymentAmount: invoiceListRow.payment_amount,
      completedPaymentAmount: invoiceListRow.completed_payment_amount,
      initiatedPaymentAmount: invoiceListRow.initiated_payment_amount,
      startedPaymentAmount: invoiceListRow.started_payment_amount,
      dueDate: invoiceListRow.due_date,
      numEmailsSent: invoiceListRow.num_emails_sent,
      payerIsGuest,
    })
  }

  type ComputeOpenBalanceProps = {
    paymentAmount: string
    startedPaymentAmount: string
  }

  export function computeOpenBalance({
    paymentAmount,
    startedPaymentAmount,
  }: ComputeOpenBalanceProps) {
    return BigNumber(paymentAmount).minus(BigNumber(startedPaymentAmount)).toFixed(2)
  }

  export function computeOpenBalanceFromInvoice(invoice: Cb.Invoice | Cb.Bill) {
    return computeOpenBalance({
      paymentAmount: invoice.payment_amount,
      startedPaymentAmount: invoice.started_payment_amount,
    })
  }

  export function computeOpenBalanceFromInvoiceListRow(invoiceListRow: Cb.InvoiceListRow) {
    return computeOpenBalance({
      paymentAmount: invoiceListRow.payment_amount,
      startedPaymentAmount: invoiceListRow.started_payment_amount,
    })
  }

  export function collectProjectIds(invoice: Cb.Invoice | Cb.Bill) {
    return MSArray.collapse(MSArray.dedupe(invoice.line_items.map((x) => x.project_id)))
  }

  export function getReleasedRetainage(releases: Cb.RetainageRelease[]) {
    return MSArray.sumAmount(releases.map((x) => x.amount))
  }

  export function getEligibleDestinationAccounts(transactionAccounts: Cb.TransactionAccount[]) {
    return transactionAccounts
      .flatMap((x) =>
        x.dest_enabled &&
        !x.archived &&
        (x.type === 'external_bank_account' || x.type === 'internal_bank_account')
          ? [x]
          : [],
      )
      .sort((a) => (a.type === 'internal_bank_account' ? -1 : 1))
  }

  type GetDefaultDestinationAccountProps = {
    transactionAccounts: Cb.TransactionAccount[]
    invoiceConfig: Cb.InvoiceConfig
  }

  export function getDefaultDestinationAccount({
    transactionAccounts,
    invoiceConfig,
  }: GetDefaultDestinationAccountProps) {
    const eligibleDestinationAccounts = getEligibleDestinationAccounts(transactionAccounts)
    const defaultAccount = eligibleDestinationAccounts.find(
      (x) => x.id === invoiceConfig.default_dest_transaction_account_id,
    )
    const internalAccount = eligibleDestinationAccounts.find(
      (x) => x.type === 'internal_bank_account',
    )
    return defaultAccount ?? internalAccount ?? eligibleDestinationAccounts.at(0)
  }

  export function isPaymentComplete(p: Cb.InvoicePayment) {
    switch (p.method) {
      case 'card':
        return p.card_details.state === 'complete'
      case 'bank_account':
        return p.bank_account_details.state === 'complete'
      case 'external':
        return true
      default:
        return unreachable(p)
    }
  }

  export function isPaymentPending(p: Cb.InvoicePayment) {
    switch (p.method) {
      case 'card':
        return p.card_details.state !== 'failed'
      case 'bank_account':
        return p.bank_account_details.state !== 'failed'
      case 'external':
        return false
      default:
        return unreachable(p)
    }
  }

  export function getInvoiceUpdateNotAllowedReason(
    reason: Cb.InvoicePermissionUpdatePermissionNotAllowedReason | null,
  ) {
    switch (reason) {
      case null:
        return null
      case 'not_creator':
        return t('You can only edit invoices that you created.')
      case 'not_pending_approval':
        return t('You can only edit invoices that are in a pending state.')
      case 'payment_exists':
        // TODO branch on whether you can cancel the payment or not
        return t('You cannot edit invoices that have payments.')
      case 'another_invoice_with_same_contract_item_exists':
        return t('You cannot edit invoices if it is not the most recent invoice for the contract.')
      case 'has_retainage':
        return t('You cannot edit invoices that reference retainage contracts.')
      case 'is_void':
        return t('You cannot edit invoices that are void.')
      case 'is_approved':
        return t('You cannot edit invoices that are approved.')
      case 'is_markup_source':
        return t('You cannot edit bills that have been invoiced.')
      default:
        return unreachable(reason)
    }
  }

  export function getInvoiceAmountDue({ invoice }: { invoice: Cb.Invoice | Cb.Bill }) {
    return BigNumber(invoice.payment_amount)
      .minus(BigNumber(invoice.started_payment_amount))
      .toFixed(2)
  }

  export function invoiceNeedsPayment({ invoice }: { invoice: Cb.Invoice }) {
    return invoice.approval_state !== 'void' && BigNumber(getInvoiceAmountDue({ invoice })).gt(Zero)
  }

  export function getClientCardFeeAmount({ fee, bill }: { fee: string; bill: Cb.Bill }) {
    return bill.payer_pays_card_fees ? BigNumber(fee) : 0
  }

  export function downloadInvoice(invoice: Cb.Invoice | Cb.Bill, file: File) {
    const filename = `invoice_${invoice.number}_from_${invoice.payee_name}`
    download(file, serializeFilename(filename))
  }

  export function showCollectLienWaivers(tier: Tier) {
    return tier === 'scale'
  }

  export function showSubmitLienWaivers(tier: Tier) {
    return tier !== 'core'
  }

  export function getLienWaiverFilename({
    lienWaiver,
    invoice,
  }: {
    lienWaiver: Cb.LienWaiver
    invoice: Cb.Invoice | Cb.Bill
  }) {
    return `${lienWaiver.is_conditional ? 'conditional' : 'unconditional'}_${
      lienWaiver.is_final ? 'final' : 'progress'
    }_lien_waiver_for_invoice_${invoice.number}`
  }

  export function stateSupportsLienWaivers(state: string) {
    return ['CA', 'FL'].includes(state)
  }

  export function collectDestInvoiceIds(invoice: Cb.Invoice | Cb.Bill | Cb.ProjectExpense) {
    return MSArray.collapse(MSArray.dedupe(invoice.line_items.map((x) => x.markup_dest_invoice_id)))
  }

  export function collectLienWaiversFromPayee(
    project: Cb.Project,
    billingConfig: Cb.VendorBillingConfig | null,
  ) {
    if (billingConfig?.is_exempt_from_lien_waiver_requirement) {
      return false
    }
    return project.collect_lien_waivers
  }

  export function isReadyForPayment(bill: Cb.Bill) {
    return bill.approval_state === 'approved' && BigNumber(bill.started_payment_amount).isZero()
  }

  export function requiresPayment(bill: Cb.Bill) {
    return (
      bill.approval_state === 'approved' &&
      BigNumber(bill.initiated_payment_amount).lt(BigNumber(bill.payment_amount))
    )
  }

  export function getExpenseTotal(expense: Cb.ProjectExpense | Cb.ExpenseListRow) {
    return BigNumber.sum(Zero, ...expense.line_items.map((x) => BigNumber(x.amount)))
  }

  export function getTotalVendorAccountValue(props: {
    summaries: Cb.ContractProgressSummary[]
    bills: Cb.Bill[]
    expenses: Cb.ProjectExpense[]
  }) {
    return BigNumber.sum(
      Zero,
      ...props.summaries.filter((x) => !x.archived).map((x) => BigNumber(x.scheduled_value)),
      ...props.bills
        .filter(
          (x) => !x.contract_id && x.approval_state !== 'void' && x.approval_state !== 'rejected',
        )
        .map((x) => BigNumber(x.payment_amount)),
      ...props.expenses.filter((x) => !x.contract_id && !x.is_void).map(getExpenseTotal),
    )
  }

  export function getTotalVendorPaidAmount(props: {
    summaries: Cb.ContractProgressSummary[]
    bills: Cb.Bill[]
    expenses: Cb.ProjectExpense[]
  }) {
    return BigNumber.sum(
      Zero,
      ...props.summaries.filter((x) => !x.archived).map((x) => BigNumber(x.paid_amount)),
      ...props.bills
        .filter(
          (x) => !x.contract_id && x.approval_state !== 'void' && x.approval_state !== 'rejected',
        )
        .map((x) => BigNumber(x.completed_payment_amount)),
      // technically, there's a bug here with payments on a different sub's subcontract since that amount shouldn't get filtered out
      ...props.expenses.filter((x) => !x.contract_id && !x.is_void).map(getExpenseTotal),
    )
  }

  export function getTotalVendorUnpaidAmount(props: {
    summaries: Cb.ContractProgressSummary[]
    bills: Cb.Bill[]
    expenses: Cb.ProjectExpense[]
  }) {
    return BigNumber.sum(
      Zero,
      ...props.summaries
        .filter((x) => !x.archived)
        .map((x) => BigNumber(x.unpaid_amount).minus(BigNumber(x.in_process_amount))),
      ...props.bills
        .filter(
          (x) => !x.contract_id && x.approval_state !== 'void' && x.approval_state !== 'rejected',
        )
        .map((x) => BigNumber(x.payment_amount).minus(BigNumber(x.completed_payment_amount))),
    )
  }

  export function getTotalVendorProcessingAmount(props: {
    summaries: Cb.ContractProgressSummary[]
    bills: Cb.Bill[]
    expenses: Cb.ProjectExpense[]
  }) {
    return BigNumber.sum(
      Zero,
      ...props.summaries.filter((x) => !x.archived).map((x) => BigNumber(x.in_process_amount)),
      ...props.bills
        .filter(
          (x) => !x.contract_id && x.approval_state !== 'void' && x.approval_state !== 'rejected',
        )
        .map((x) =>
          BigNumber(x.initiated_payment_amount).minus(BigNumber(x.completed_payment_amount)),
        ),
    )
  }

  export function getTotalVendorBalanceToFinish(props: {
    summaries: Cb.ContractProgressSummary[]
  }) {
    return BigNumber.sum(
      Zero,
      ...props.summaries.filter((x) => !x.archived).map((x) => BigNumber(x.work_remaining)),
    )
  }

  export function getTotalVendorRetainageBalance(props: {
    summaries: Cb.ContractProgressSummary[]
  }) {
    return BigNumber.sum(
      Zero,
      ...props.summaries.filter((x) => !x.archived).map((x) => BigNumber(x.retainage_balance)),
    )
  }

  export function getTotalClientAccountValue(props: {
    projectSummaries: Cb.ProjectProgressSummaryV2[]
  }) {
    return BigNumber.sum(Zero, ...props.projectSummaries.map((p) => p.receivable.expected_amount))
  }

  export function getTotalClientPaidAmount(props: {
    projectSummaries: Cb.ProjectProgressSummaryV2[]
  }) {
    return BigNumber.sum(Zero, ...props.projectSummaries.map((p) => p.receivable.paid_amount))
  }

  export function getTotalClientProcessingAmount(props: {
    projectSummaries: Cb.ProjectProgressSummaryV2[]
  }) {
    return BigNumber.sum(Zero, ...props.projectSummaries.map((p) => p.receivable.in_process_amount))
  }

  export function getTotalClientUnpaidAmount(props: {
    projectSummaries: Cb.ProjectProgressSummaryV2[]
  }) {
    return BigNumber.sum(
      Zero,
      ...props.projectSummaries.map((p) => p.receivable.unpaid_amount),
    ).minus(getTotalClientProcessingAmount(props))
  }

  export function getTotalClientRetainageAmount(props: {
    projectSummaries: Cb.ProjectProgressSummaryV2[]
  }) {
    return BigNumber.sum(Zero, ...props.projectSummaries.map((p) => p.receivable.retainage_balance))
  }

  export function getTotalClientBalance(props: {
    projectSummaries: Cb.ProjectProgressSummaryV2[]
  }) {
    return BigNumber.sum(Zero, ...props.projectSummaries.map((p) => p.receivable.work_remaining))
  }

  /** Does not account for parent codes */
  export function getSubcontractCostToCostCodeOrType(
    subcontract: Cb.Contract,
    props: {
      costCode: Cb.CostCode | 'none' | 'any'
      costType: Cb.CostType | 'none' | 'any'
    },
  ) {
    const { costCode, costType } = props
    const matches = (c: Cb.CostCode | Cb.CostType | 'none' | 'any', y: string | null) =>
      c === 'any' || (c === 'none' && y === null) || (typeof c !== 'string' && c.id === y)

    return BigNumber.sum(
      Zero,
      ...subcontract.items
        .filter((x) => !x.archived)
        .filter((x) => matches(costCode, x.cost_code_id) && matches(costType, x.cost_type_id))
        .map((x) => BigNumber(x.current_amount)),
    )
  }

  /** Does not account for parent codes */
  export function getExpenseCostToCostCodeOrType(
    expense: Cb.ProjectExpense,
    props: {
      costCode: Cb.CostCode | 'none' | 'any'
      costType: Cb.CostType | 'none' | 'any'
    },
  ) {
    const { costCode, costType } = props
    const matches = (c: Cb.CostCode | Cb.CostType | 'none' | 'any', y: string | null) =>
      c === 'any' || (c === 'none' && y === null) || (typeof c !== 'string' && c.id === y)

    return BigNumber.sum(
      Zero,
      ...expense.line_items
        .filter((x) => matches(costCode, x.cost_code_id) && matches(costType, x.cost_type_id))
        .map((x) => BigNumber(x.amount)),
    )
  }

  /** Does not account for parent codes */
  export function getBillCostToCostCodeOrType(
    bill: Cb.Bill,
    props: {
      costCode: Cb.CostCode | 'none' | 'any'
      costType: Cb.CostType | 'none' | 'any'
    },
  ) {
    const { costCode, costType } = props
    const matches = (c: Cb.CostCode | Cb.CostType | 'none' | 'any', y: string | null) =>
      c === 'any' || (c === 'none' && y === null) || (typeof c !== 'string' && c.id === y)

    return BigNumber.sum(
      Zero,
      ...bill.line_items
        .filter((x) => matches(costCode, x.cost_code_id) && matches(costType, x.cost_type_id))
        .map((x) => BigNumber(x.payment_amount)),
    )
  }

  /** Does not account for parent codes */
  export function getTimeEntryCostToCostCodeOrType(
    timeEntry: Cb.TimeEntry,
    props: {
      costCode: Cb.CostCode | 'none' | 'any'
      costType: Cb.CostType | 'none' | 'any'
    },
  ) {
    const { costCode, costType } = props
    const matches = (c: Cb.CostCode | Cb.CostType | 'none' | 'any', y: string | null) =>
      c === 'any' || (c === 'none' && y === null) || (typeof c !== 'string' && c.id === y)

    return matches(costCode, timeEntry.expense_cost_code_id) && matches(costType, null)
      ? BigNumber(timeEntry.amount ?? 0)
      : Zero
  }

  export function getInvoiceIncomeForCostCode(
    invoice: Cb.Invoice,
    props: {
      costCode: Cb.CostCode | 'none'
    },
  ) {
    const { costCode } = props
    const matches = (c: Cb.CostCode | Cb.CostType | 'none' | 'any', y: string | null) =>
      c === 'any' || (c === 'none' && y === null) || (typeof c !== 'string' && c.id === y)

    return BigNumber.sum(
      Zero,
      ...invoice.line_items
        .filter((x) => matches(costCode, x.cost_code_id))
        .map((x) => x.payment_amount),
    )
  }

  export function getPaymentMethodLabel(payment: Cb.InvoicePayment) {
    switch (payment.method) {
      case 'card':
        return t('Card')
      case 'bank_account':
        return t('Online bank transfer')
      case 'external':
        return t('Paid outside of Beam')
      default:
        return unreachable(payment)
    }
  }

  export function getAmountPaidByClient(payments: Cb.InvoicePayment[]) {
    return BigNumber.sum(Zero, ...payments.map((x) => BigNumber(x.amount).plus(x.fee_amount)))
  }

  export function getBillPaymentAmountPlusFee(props: {
    bill: Cb.Bill
    method: 'card' | 'bank'
    cardFee: BigNumber
  }) {
    const amountDue = getInvoiceAmountDue({ invoice: props.bill })
    return props.method === 'bank'
      ? BigNumber(amountDue)
      : props.method === 'card'
      ? BigNumber(amountDue).plus(props.cardFee)
      : unreachable(props.method)
  }

  export function getRemainingMilestones(props: { contract: Cb.Contract; invoices: Cb.Invoice[] }) {
    return props.contract.contract_type === 'milestone'
      ? props.contract.items.filter(
          (m) =>
            !props.invoices.some((j) =>
              (j.invoice_type === 'milestone' ? j.line_items : []).some(
                (li) => li.contract_item_id === m.id,
              ),
            ),
        )
      : null
  }

  export function invoiceIsDue(invoice: Cb.Invoice | Cb.Bill) {
    return BigNumber(getInvoiceAmountDue({ invoice })).gt(0)
  }

  type RealPayment = Cb.InvoicePaymentTypeBankAccount | Cb.InvoicePaymentTypeCard

  type PaymentActivity = {
    dateOrTime: MSTimestamp | MSDate
    completed: boolean
  } & (
    | { type: 'recorded'; payment: Cb.InvoicePaymentTypeExternal }
    | { type: 'scheduled'; payment: RealPayment }
    | { type: 'initiated'; payment: RealPayment }
    | { type: 'completed'; payment: RealPayment }
    | { type: 'failed'; payment: RealPayment }
  )

  export function getPaymentActivity(payment: Cb.InvoicePayment): PaymentActivity[] {
    if (payment.method === 'external') {
      return [
        {
          dateOrTime: MSDate.init(payment.external_details.completed_date),
          completed: true,
          type: 'recorded',
          payment,
        },
      ]
    } else if (payment.method === 'bank_account') {
      const details = payment.bank_account_details
      return MSArray.collapse([
        {
          dateOrTime: MSTimestamp.init(payment.latest_employee_action_at ?? payment.updated_at),
          completed: true,
          type: 'scheduled',
          payment,
        },
        {
          dateOrTime: MSDate.init(details.initiated_date ?? details.estimated_initiated_date),
          completed: payment.bank_account_details.state !== 'new',
          type: 'initiated',
          payment,
        },
        payment.bank_account_details.state !== 'failed' && {
          dateOrTime: MSDate.init(details.completed_date ?? details.estimated_completed_date),
          completed: payment.bank_account_details.state === 'complete',
          type: 'completed',
          payment,
        },
        payment.bank_account_details.state === 'failed' && {
          dateOrTime: MSTimestamp.init(payment.updated_at),
          completed: true,
          type: 'failed',
          payment,
        },
      ])
    } else if (payment.method === 'card') {
      const details = payment.card_details
      return MSArray.collapse([
        details.initiated_date && {
          dateOrTime: MSDate.init(details.initiated_date),
          completed: true,
          type: 'initiated',
          payment,
        },
        {
          dateOrTime: MSDate.init(details.completed_date ?? details.estimated_completed_date),
          completed: details.state === 'complete',
          type: 'completed',
          payment,
        },
        details.state === 'failed' && {
          dateOrTime: MSTimestamp.init(payment.updated_at),
          completed: true,
          type: 'failed',
          payment,
        },
      ])
    } else {
      return unreachable(payment)
    }
  }
}

import { ReactNode, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useOnMount } from 'msutils'
import { BaseLayout } from '../baseLayout'
import { getScrollableParents, useElementSize } from '../dom-utils'
import { PortalRootId } from './constants'

type DOMCoordinate = [x: number, y: number]
type Scrollable = Window | HTMLElement

const Zero: DOMCoordinate = [0, 0]

function useScrollListeners(anchor: HTMLElement, onScrollAny: (targets: Scrollable[]) => void) {
  useOnMount(() => {
    const scrollableParents = getScrollableParents(anchor)
    const handleScroll = () => {
      onScrollAny(scrollableParents)
    }

    scrollableParents.forEach((sp) => sp.addEventListener('scroll', handleScroll))
    return () => {
      scrollableParents.forEach((sp) => sp.removeEventListener('scroll', handleScroll))
    }
  })
}

function useResizeListener(onResize: () => void) {
  useOnMount(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  })
}

function useScrollOffset(anchor: HTMLElement) {
  const [offset, setOffset] = useState(Zero)
  useScrollListeners(anchor, (targets) =>
    setOffset(
      targets.reduce((p, c): DOMCoordinate => {
        const scrollTop = c instanceof Window ? c.scrollY : c.scrollTop
        const scrollLeft = c instanceof Window ? c.scrollX : c.scrollLeft
        return [p[0] + scrollLeft, p[1] - scrollTop]
      }, Zero),
    ),
  )
  return offset
}

function useBoundingRect(anchor: HTMLElement) {
  const [boundingRect, setBoundingRect] = useState(() => anchor.getBoundingClientRect())
  useResizeListener(() => setBoundingRect(anchor.getBoundingClientRect()))
  return boundingRect
}

function FloatingWidget({
  left,
  top,
  anchorBoundingRect,
  allowReposition,
  children,
}: {
  left: number
  top: number
  allowReposition: boolean
  anchorBoundingRect: DOMRect
  children: ReactNode
}) {
  const [widgetRef, setWidgetRef] = useState<HTMLDivElement | null>(null)
  const widgetBoundingRect = useElementSize(widgetRef)

  const actualLeft = widgetBoundingRect
    ? Math.min(window.innerWidth - widgetBoundingRect.width, left)
    : left

  const actualTop =
    widgetBoundingRect && allowReposition && window.innerHeight < top + widgetBoundingRect.height
      ? top - widgetBoundingRect.height - anchorBoundingRect.height
      : top

  return (
    <BaseLayout.NativeDiv ref={setWidgetRef} position="absolute" left={actualLeft} top={actualTop}>
      {children}
    </BaseLayout.NativeDiv>
  )
}

type Props = {
  anchor: HTMLElement
  setInactive: () => void
  getAnchorOffset?: (boundingRect: DOMRect) => DOMCoordinate
  children: ReactNode
}

export function AnchoredPortal({ anchor, getAnchorOffset, setInactive, children }: Props) {
  useScrollListeners(anchor, setInactive)
  const scrollOffset = useScrollOffset(anchor)
  const boundingRect = useBoundingRect(anchor)

  const portalRoot = useMemo(() => document.getElementById(PortalRootId) as HTMLElement, [])
  const portal = useMemo(() => {
    const anchorOffset: DOMCoordinate = getAnchorOffset?.(boundingRect) ?? [0, boundingRect.height]
    return createPortal(
      <FloatingWidget
        left={boundingRect.left + anchorOffset[0] + scrollOffset[0]}
        top={boundingRect.top + anchorOffset[1] + scrollOffset[1]}
        anchorBoundingRect={boundingRect}
        allowReposition={!getAnchorOffset}
      >
        {children}
      </FloatingWidget>,
      portalRoot,
    )
  }, [children, portalRoot, boundingRect, scrollOffset, getAnchorOffset])

  return <>{portal}</>
}

import { useRef } from 'react'
import { Cb } from 'cb'
import { FileUtils } from 'components/misc/FileUtils'
import EstimateInput, { EstimateInputUtils } from 'components/inputs/EstimateInput'
import { t } from 'content'
import useScreenSize from 'compass/theme/useScreenSize'
import { useFormState } from 'utils/form-input'
import { RichAddressInputUtils } from 'components/inputs/RichAddressInput'
import { MSDate } from 'msutils'
import { EstimateItemsInputUtils } from 'components/inputs/EstimateItemsInput'
import { useAnalyticsContext } from 'root/global'
import { useBusinessContext } from 'root/user'
import { EstimateUtils } from 'features/estimates/utils'
import { UpdateEstimateFormUtils as Utils } from '../utils'

export default Utils.fc.Form(
  () => {
    const {
      estimate,
      client,
      addressExactMatch,
      costCodes,
      costTypes,
      renofiAd,
      generalProjectConfig,
      mostRecentEstimateWithCoverPage,
    } = Utils.fc.useContext()
    const { showZeroAmountLineItems } = useAnalyticsContext().flags
    const { business, employee } = useBusinessContext()
    const { state, useValidatedAction } = useFormState(EstimateInputUtils.schema, {
      initValue: {
        client,
        displayName: estimate.display_name,
        expiration: MSDate.init(estimate.expiration_date),
        number: estimate.number,
        address: RichAddressInputUtils.fromApi(estimate.address, { fallback: addressExactMatch }),
        lineItems: {
          type: 'group',
          description: '--group--',
          children: EstimateItemsInputUtils.fromApi(estimate.line_item_nodes, {
            costCodes,
            costTypes,
          }),
        },
        additionalInformation: estimate.additional_information ?? '',
        files: FileUtils.fromApi(estimate.files),
        requireApprovalSignature: estimate.approval_signature_required,
        ccEmails: client?.cc_emails,
        showMarkup: estimate.show_markup,
        showSubSectionPricing: estimate.show_sub_section_pricing,
        showQuantity: estimate.show_quantity,
        showUnitCosts: estimate.show_unit_costs,
        showZeroAmountItems: estimate.show_zero_amount_items,
        hideEstimateTotal: estimate.hide_total,
        enableRenofi: renofiAd?.enabled,
        coverPageType: estimate.cover_page
          ? 'beam'
          : estimate.uploaded_cover_page
          ? 'uploaded'
          : 'none',
        coverPage: estimate.cover_page
          ? {
              title: estimate.cover_page.title,
              body: estimate.cover_page.body,
              photo: estimate.cover_page.cover_photo
                ? FileUtils.fromApi([estimate.cover_page.cover_photo])
                : undefined,
            }
          : mostRecentEstimateWithCoverPage?.cover_page
          ? {
              title: mostRecentEstimateWithCoverPage.cover_page.title,
              body: mostRecentEstimateWithCoverPage.cover_page.body,
              photo: mostRecentEstimateWithCoverPage.cover_page.cover_photo
                ? FileUtils.fromApi([mostRecentEstimateWithCoverPage.cover_page.cover_photo])
                : undefined,
            }
          : {
              title: 'About us',
              body: EstimateInputUtils.getDefaultCoverPageBody({ business, employee }),
            },
        uploadedCoverPage: {
          file: estimate.uploaded_cover_page
            ? FileUtils.fromApi([estimate.uploaded_cover_page])
            : mostRecentEstimateWithCoverPage?.uploaded_cover_page
            ? FileUtils.fromApi([mostRecentEstimateWithCoverPage.uploaded_cover_page])
            : undefined,
        },
      },
      config: {
        client: { disabled: estimate.status !== 'draft' },
        displayName: {
          validate: (val) => {
            if (val === '') throw new Error('Required')
          },
        },
        title: { hidden: true },
        changeOrderAccountingCode: { hidden: true },
        showZeroAmountItems: { hidden: !showZeroAmountLineItems },
      },
      hooks: ({ client: clientInput, displayName, address, emailBody }) => ({
        onChangeClient: (newValue) => {
          if (newValue && address.fields.line1.value) {
            emailBody.update(
              Utils.getDefaultEmailBody({
                address: address.fields.line1.value,
                client: newValue,
                isSent: estimate.email_sent,
              }),
            )
          }
        },
        onChangeAddress: (newValue) => {
          if (newValue && !displayName.value) {
            displayName.update(newValue.line1)
          }
          if (newValue.line1 && clientInput.value) {
            emailBody.update(
              Utils.getDefaultEmailBody({
                address: newValue.line1,
                client: clientInput.value,
                isSent: estimate.email_sent,
              }),
            )
          }
        },
      }),
    })
    const estimateRef = useRef<Cb.Estimate>(estimate)

    const sendEstimate = useValidatedAction(Utils.useSendEstimate(estimateRef.current))
    const saveEstimateDraft = useValidatedAction(Utils.useSaveEstimateDraft(estimateRef.current))
    const autosaveEstimate = useValidatedAction(Utils.useAutosaveEstimate(estimateRef.current), {
      onSuccess: (newValue) => {
        estimateRef.current = newValue
      },
      disableErrorPropagation: true,
      disableSetInactiveOnSuccess: true,
    })

    return (
      <EstimateInput
        submit={sendEstimate}
        saveDraft={saveEstimateDraft}
        state={state}
        autosave={autosaveEstimate}
        estimateRef={estimateRef.current}
        copy={{
          title: t('Edit estimate'),
          submit: t('Send'),
          saveDraft: t('Save as draft'),
          typeName: t('Estimate'),
        }}
        context={{ generalProjectConfig }}
        showCoverPage
        disableRenofiAd={false}
        disableAddSection={false}
        capabilities={EstimateUtils.getCapabilities(employee)}
        variant="project"
      />
    )
  },
  () => {
    return useScreenSize() === 'sm' ? 'drawer' : 'modal'
  },
)

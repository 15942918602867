export type InputProps<T> = { value: T } & Partial<{
  update: (newValue: T) => void
  focus: () => void
  blur: () => void
  error: string | null
  willUpdate: (oldValue: T, newValue: T) => void | Promise<void>
  didUpdate: (oldValue: T, newValue: T) => void | Promise<void>
  disabled: boolean
}>

export function getInputProps<T, X extends object>(props: InputProps<T> & X) {
  return props
}

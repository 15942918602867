import axios from 'axios'
import { Cb } from 'cb'
import { SMARTY_STREETS_KEY } from 'env'

// TODO: use zod for validation

const BaseUrl = `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${SMARTY_STREETS_KEY}&max_results=6`

type SmartyRawResult = {
  city: string
  entries: number
  secondary: string
  state: string
  street_line: string
  zipcode: string
}

function buildParsedAddress(result: SmartyRawResult): Cb.RichAddress {
  return {
    line_1: result.street_line,
    line_2: result.secondary || null,
    city: result.city,
    state: result.state as any,
    zip_code: result.zipcode,
  }
}

export namespace Smarty {
  export async function predict(pre: string) {
    if (pre === '') return []

    const response = await axios.get(`${BaseUrl}&search=${pre}&source=all`)
    const predictions: SmartyRawResult[] = response.data.suggestions
    return predictions
      .filter((p: SmartyRawResult) => !p.secondary || p.entries === 1)
      .map((p: SmartyRawResult) => buildParsedAddress(p))
  }
}

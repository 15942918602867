import { t } from 'content'
import { MSLayout } from 'utils/layout'
import { Cb, Q } from 'cb'
import UpdateEstimateForm from 'components/forms/UpdateEstimateForm'
import { Action } from 'utils/actions'
import { Module } from 'modules/routes'
import { useSupportContext } from 'root/global'
import { useCurrentPath } from 'utils/router'
import { confirm } from 'utils/confirm'
import useMetadataFormProps from 'utils/useMetadataFormProps'

type Props = {
  tutorial: Cb.Tutorial
}

export default function TutorialBanner({ tutorial }: Props) {
  const q = Q.group({
    client: Cb.useListPayerContacts({ params: { payer_id: tutorial.client_id }, select: Q.get }),
    estimate: Cb.useListEstimates({
      params: { id: tutorial.estimate_id ?? Q.NullUuid },
      select: Q.opt,
    }),
    project: Cb.useListProjects({
      params: { id: tutorial.project_id ?? Q.NullUuid },
      select: Q.opt,
    }),
    contract: Cb.useListContracts({
      params: { payer_id: tutorial.client_id, project_id: tutorial.project_id ?? Q.NullUuid },
      select: Q.opt,
    }),
  })
  const updateTutorial = Cb.usePartialUpdateTutorialHook()
  const { setChatOpen } = useSupportContext()
  const pathname = useCurrentPath()

  const createSampleEstimate = Cb.useCreateSampleEstimateHook()
  const updateEstimateFormProps = useMetadataFormProps<Cb.Estimate>()

  if (q.status !== 'success') return null
  const { client, estimate, project, contract } = q.queries

  const onDismiss = async () => {
    if (
      await confirm(
        'Are you sure you want to skip this tutorial?',
        'It will help you get familiar with Beam’s key features, so you can manage your projects with confidence.',
      )
    )
      await updateTutorial(tutorial.id, { status: 'dismissed' })
  }

  return (
    <>
      {updateEstimateFormProps.metadata && (
        <UpdateEstimateForm
          {...updateEstimateFormProps}
          estimate={updateEstimateFormProps.metadata}
        />
      )}
      {tutorial.invoice_sent ? (
        <MSLayout.Banner
          theme="white"
          title={t("Congrats! You've created an estimate and sent an invoice")}
          icon="open-book"
          description={t(
            'Try these steps with a real client next. Need help? Contact our support team.',
          )}
          actions={[
            Action.button(t('Contact support'), {
              onClick: () => setChatOpen(true),
              theme: 'text-small',
            }),
            Action.mutation(t('Finish tutorial'), {
              mutate: () => updateTutorial(tutorial.id, { status: 'completed' }),
              theme: 'gold',
            }),
          ]}
        />
      ) : contract && project ? (
        <MSLayout.Banner
          theme="white"
          title={t('Create an invoice against the contract')}
          icon="open-book"
          description={t(
            'From the sample project page, create your first client invoice by clicking on the "Add new..." button. Send it to "{{ X }}" to experience what your clients will see.',
            { X: client.name },
          )}
          dismiss={onDismiss}
          actions={[
            Action.href(t('View project'), {
              href: Module(`/projects/${project.id}`, { t: 'income' }).href,
              theme: 'gold',
              endIcon: ['arrow', '90'],
              qualify: () => !pathname.includes(project.id),
            }),
          ]}
        />
      ) : project ? (
        <MSLayout.Banner
          theme="white"
          title={t('Create a contract for the project')}
          icon="open-book"
          description={t(
            'From the sample project page, create the prime contract. All your invoices for this project will be based off of this contract.',
          )}
          dismiss={onDismiss}
          actions={[
            Action.href(t('View project'), {
              href: Module(`/projects/${project.id}`, { t: 'income' }).href,
              theme: 'gold',
              endIcon: ['arrow', '90'],
              qualify: () => !pathname.includes(project.id),
            }),
          ]}
        />
      ) : estimate && estimate.status === 'approved' ? (
        <MSLayout.Banner
          theme="white"
          title={t('Convert the estimate into a project')}
          icon="open-book"
          description={t('Head over to the sample estimate page and click "Convert to project".')}
          dismiss={onDismiss}
          actions={[
            Action.href(t('View estimate'), {
              href: Module(`/estimates/${estimate.id}`).href,
              endIcon: ['arrow', '90'],
              theme: 'gold',
              qualify: () => !pathname.includes(estimate.id),
            }),
          ]}
        />
      ) : estimate && (estimate.status === 'sent' || estimate.email_sent) ? (
        <MSLayout.Banner
          theme="white"
          title={t('Approve the estimate')}
          icon="open-book"
          description={t(
            'Approve it via the link in the email, or manually mark it as approved under the "More actions" menu.',
          )}
          dismiss={onDismiss}
          actions={[
            Action.href(t('View estimate'), {
              href: Module(`/estimates/${estimate.id}`).href,
              endIcon: ['arrow', '90'],
              theme: 'gold',
              qualify: () => !pathname.includes(estimate.id),
            }),
          ]}
        />
      ) : estimate ? (
        <MSLayout.Banner
          theme="white"
          title={t('Finish editing and send the estimate')}
          icon="open-book"
          description={t(
            'Email the estimate to "{{ X }}" to see what your clients will experience.',
            { X: client.name },
          )}
          dismiss={onDismiss}
          actions={[
            Action.href(t('View estimate'), {
              href: Module(`/estimates/${estimate.id}`).href,
              endIcon: ['arrow', '90'],
              theme: 'gold',
              qualify: () => !pathname.includes(estimate.id),
            }),
          ]}
        />
      ) : (
        <MSLayout.Banner
          theme="white"
          title={t("Welcome to Beam! Let's start by creating a sample estimate")}
          icon="open-book"
          description={t(
            "This will help you get familiar with Beam's process, from estimating to invoicing.",
          )}
          dismiss={onDismiss}
          actions={[
            Action.mutation(t('Create sample estimate'), {
              mutate: async () => {
                const resp = await createSampleEstimate({})
                const newEstimate = await Cb.retrieveEstimate(resp.estimate_id)
                updateEstimateFormProps.setActive(newEstimate)
              },
              theme: 'gold',
            }),
          ]}
        />
      )}
    </>
  )
}

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListCostLibraryItemsOrdering =
  (typeof ListCostLibraryItemsOrdering)[keyof typeof ListCostLibraryItemsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListCostLibraryItemsOrdering = {
  unit_cost: 'unit_cost',
  '-unit_cost': '-unit_cost',
  unit_type: 'unit_type',
  '-unit_type': '-unit_type',
  description: 'description',
  '-description': '-description',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

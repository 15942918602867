import { ReactNode } from 'react'
import { BaseLayout } from '../../baseLayout'
import Typography from '../../data/Typography'

type Props = {
  title?: string
  error?: string | null
  children: ReactNode
}

export default function InputLayout({ title, error, children }: Props) {
  return (
    <BaseLayout.VStack gap={2}>
      {title && <Typography variant="label">{title}</Typography>}
      {children}
      {error && (
        <Typography variant="label" className="text-th-red-warning">
          {error}
        </Typography>
      )}
    </BaseLayout.VStack>
  )
}

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListProjectBudgetItemsOrdering =
  (typeof ListProjectBudgetItemsOrdering)[keyof typeof ListProjectBudgetItemsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListProjectBudgetItemsOrdering = {
  cost_code_name: 'cost_code_name',
  '-cost_code_name': '-cost_code_name',
  amount: 'amount',
  '-amount': '-amount',
  source_markup_multiplier: 'source_markup_multiplier',
  '-source_markup_multiplier': '-source_markup_multiplier',
  is_initial: 'is_initial',
  '-is_initial': '-is_initial',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

import { useState, useCallback, useMemo } from 'react'

export default function useMetadataFormProps<T extends {}>(defaultValue: T | null = null) {
  const [formProps, setFormProps] = useState(
    defaultValue === null
      ? {
          isActive: false as const,
          metadata: null,
        }
      : {
          isActive: true,
          metadata: defaultValue,
        },
  )

  const setActive = useCallback(
    (x: NonNullable<T>) => setFormProps({ isActive: true, metadata: x }),
    [],
  )
  const setInactive = useCallback(() => {
    setFormProps({ ...formProps, isActive: false })
    setTimeout(() => setFormProps({ isActive: false, metadata: null }), 300)
  }, [formProps])

  return useMemo(
    () =>
      formProps.metadata === null
        ? { metadata: null, setActive, setInactive, isActive: false as const }
        : { metadata: formProps.metadata, setActive, setInactive, isActive: formProps.isActive },
    [formProps.metadata, setActive, setInactive, formProps.isActive],
  )
}

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListExpenseListRowsOrdering =
  (typeof ListExpenseListRowsOrdering)[keyof typeof ListExpenseListRowsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListExpenseListRowsOrdering = {
  date: 'date',
  '-date': '-date',
  vendor_name: 'vendor_name',
  '-vendor_name': '-vendor_name',
  project_names: 'project_names',
  '-project_names': '-project_names',
  payment_amount: 'payment_amount',
  '-payment_amount': '-payment_amount',
} as const

import { ReactNode, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { PortalRootId } from './utils'

type Props = {
  element?: HTMLElement | null
  children: ReactNode
}

export default function Portal({ element, children }: Props) {
  const portalRoot = useMemo(() => document.getElementById(PortalRootId), [])
  const boundingRect = element?.getBoundingClientRect()
  const content = useMemo(
    () => (
      <div className="absolute" style={{ top: boundingRect?.top, left: boundingRect?.left }}>
        {children}
      </div>
    ),
    [children, boundingRect],
  )

  const portal = useMemo(
    () => (portalRoot ? createPortal(content, portalRoot) : null),
    [portalRoot, content],
  )

  return <>{portal}</>
}

import { useState } from 'react'
import { Cb, Q } from 'cb'
import { InputBaseUtils } from 'compass-local/InputBase'
import SelectInput from 'compass-local/SelectInput'
import { getInputProps, InputProps } from 'compass-local/utils/InputProps'
import CreateClientForm from 'components/forms/CreateClientForm'
import { t } from 'content'
import { includesIgnoreCase } from 'utils/string'
import useFormProps from 'utils/useFormProps'
import * as ClientSelectUtils from '../utils'

type Props = InputProps<Cb.PayerContact | null> &
  InputBaseUtils.ExternalProps & {
    allowCreate?: boolean
    onCreate?: ({ client }: ClientSelectUtils.OnCreateClient) => void
  }

export default function PayerContractSelect(props: Props) {
  const { update, onCreate, allowCreate } = getInputProps(props)

  const q = Q.group({ clients: Cb.useListPayerContacts({ params: { archived: false } }) })
  const options = q.status === 'success' ? q.queries.clients : null
  const createClientFormProps = useFormProps()
  const [initialName, setInitialName] = useState('')

  return (
    <>
      {allowCreate && (
        <CreateClientForm
          {...createClientFormProps}
          initialName={initialName}
          onSuccess={(newValue) => {
            update?.(newValue)
            onCreate?.({ client: newValue })
          }}
        />
      )}
      <SelectInput
        title={t('Client')}
        {...props}
        {...(q.status === 'error' && { error: 'There was an error loading clients' })}
        onCreate={
          allowCreate
            ? (search) => {
                if (search) setInitialName(search)
                createClientFormProps.setActive()
              }
            : undefined
        }
        filter={(search, c) =>
          includesIgnoreCase(c.name, search) || includesIgnoreCase(c.email, search)
        }
        isLoading={q.status === 'loading'}
        getId={(x) => x.id}
        getTitle={(c) => c.name}
        getOptionProps={(c) => ({ value: c, label: c.email || null })}
        options={options}
        emptyCreateTitle={t('Add your first client')}
      />
    </>
  )
}

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListYieldPaymentsOrdering =
  (typeof ListYieldPaymentsOrdering)[keyof typeof ListYieldPaymentsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListYieldPaymentsOrdering = {
  date: 'date',
  '-date': '-date',
  start_date: 'start_date',
  '-start_date': '-start_date',
  end_date: 'end_date',
  '-end_date': '-end_date',
  amount: 'amount',
  '-amount': '-amount',
  state: 'state',
  '-state': '-state',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

import { F } from 'msutils'

export namespace AdditionalContactInputUtils {
  export const schema = F.Group({
    spec: {
      name: F.Text({ required: true }),
      jobTitle: F.Text(),
      email: F.Email(),
      phone: F.Phone({ errorKey: 'phone_number' }),
    },
  })

  export type CopySpec = {
    title: string
    submit: string
  }
}

import { useEffect } from 'react'
import debounce from 'lodash.debounce'

export namespace TextArea2Utils {
  type GetDesiredHeightProps = {
    el: HTMLTextAreaElement
    max?: number
    min?: number
  }
  export function getDesiredHeight({ el, max = 400, min = 40 }: GetDesiredHeightProps) {
    const textHeight = el.scrollHeight
    if (textHeight < min) return min
    if (textHeight > max) return max
    return textHeight
  }

  type UseDynamicResizeProps = {
    inputRef: HTMLTextAreaElement | null
    value: string
    maxHeight?: number
    minHeight?: number
  }

  export function useDynamicResize({
    inputRef,
    value,
    maxHeight,
    minHeight,
  }: UseDynamicResizeProps) {
    // Note: using an effect with a dummy dependency on value because we have to wait for the value to
    // actually change in the input before trying to get its scroll height
    useEffect(() => {
      let prevWidth = 0
      const doResize = () => {
        if (inputRef) {
          if (inputRef.clientWidth === prevWidth) return
          prevWidth = inputRef.clientWidth

          // eslint-disable-next-line
        inputRef.style.height = '0'
          // eslint-disable-next-line
        inputRef.style.height = `${getDesiredHeight({ el: inputRef, max: maxHeight, min: minHeight })}px`
        }
      }
      doResize()
      // idk why this needs to debounce
      const observer = new ResizeObserver(debounce(doResize))

      if (inputRef) {
        observer.observe(inputRef)
        return () => {
          observer.disconnect()
        }
      } else {
        return () => {
          //
        }
      }
    }, [inputRef, maxHeight, minHeight, value])
  }
}

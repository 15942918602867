import { Cb } from 'cb'
import { EstimateUtils } from 'features/estimates/utils'
import { F, MSArray, random, Zero } from 'msutils'
import { unhandled } from 'msutils/misc'
import BigNumber from 'bignumber.js'

export namespace EstimateItemsInputUtils {
  const schemaWithoutChildren = F.Group({
    spec: {
      description: F.Text(),
      type: F.DefaultChoice<'group' | 'fixed-priced' | 'percent-priced' | 'buffered-discount'>(
        'fixed-priced',
      ),
      costCode: F.Choice<Cb.CostCode>(),
      costType: F.Choice<Cb.CostType>(),
      unitCost: F.Currency2({ initValue: Zero, required: true }),
      quantity: F.Decimal2({ initValue: BigNumber(1), required: true }),
      unit: F.Choice<(typeof EstimateUtils.UnitTypes)[number]['id']>(),
      markup: F.Decimal2({ initValue: Zero, required: true }),
      fee: F.Decimal2({ initValue: Zero, required: true }),
      bufferReverseMultiplier: F.Decimal2({ initValue: Zero, required: true }),
      discountMultiplier: F.Decimal2({ initValue: Zero, required: true }),
      isCommission: F.Toggle(),
      isInactive: F.Toggle(),
      hideInnerItems: F.Toggle(),
      postSubtotal: F.Toggle(),
      isSection: F.Toggle(),
      listId: F.Text(),
    },
  })

  type Schema = F.Recursive<typeof schemaWithoutChildren>

  const buildSchema = (depth: number): Schema =>
    F.Group({
      spec: {
        ...schemaWithoutChildren.spec,
        ...(depth <= 3 && ({ children: F.List({ spec: buildSchema(depth + 1) }) } as any)),
      },
      hooks: ({ description, unitCost, costCode }) => ({
        onChangeCostCode: (newValue) => {
          const newDescription = newValue?.description || newValue?.name || null
          const oldDescription = costCode.value?.description || costCode.value?.name || null
          if (newDescription && (!description.value || description.value === oldDescription)) {
            description.update(newDescription)
          }
          const newAmount = newValue?.unit_price ? BigNumber(newValue.unit_price) : null
          const oldAmount = costCode.value?.unit_price ? BigNumber(costCode.value.unit_price) : null
          if (newAmount && (!unitCost.value || unitCost.value.eq(oldAmount ?? Zero))) {
            unitCost.update(newAmount)
          }
        },
      }),
    })
  export const schema = buildSchema(0)
  export type ItemInput = F.Input<typeof schema>

  export function getAllItems(item: ItemInput): ItemInput[] {
    if (item.type.value === 'group') {
      return item.children.flatMap((x) => getAllItems(x)).concat(item)
    } else {
      return [item]
    }
  }

  type BaseAbstractItem = {
    listId: string
    isInactive: boolean
  }
  type AbstractGroupItem = {
    type: 'group'
    // eslint-disable-next-line
    children: AbstractItem[]
  } & BaseAbstractItem
  type AbstractFixedPricedItem = {
    type: 'fixed-priced'
    unitCost: BigNumber
    quantity: BigNumber
    markupMultiplier: BigNumber
    postSubtotal: boolean
  } & BaseAbstractItem
  type AbstractPercentPricedItem = {
    type: 'percent-priced'
    feeMultiplier: BigNumber
    postSubtotal: boolean
  } & BaseAbstractItem
  type AbstractBufferedDiscountItem = {
    type: 'buffered-discount'
    bufferReverseMultiplier: BigNumber
    discountMultiplier: BigNumber
    isCommission: boolean
  } & BaseAbstractItem
  type AbstractItem =
    | AbstractGroupItem
    | AbstractFixedPricedItem
    | AbstractPercentPricedItem
    | AbstractBufferedDiscountItem

  function buildAbstractItemFromItemInput(item: ItemInput): AbstractItem {
    if (item.type.value === 'group') {
      return {
        type: 'group',
        listId: item.listId.value,
        isInactive: item.isInactive.value,
        children: item.children.map((c) => buildAbstractItemFromItemInput(c)),
      }
    } else if (item.type.value === 'fixed-priced') {
      return {
        type: 'fixed-priced',
        listId: item.listId.value,
        isInactive: item.isInactive.value,
        unitCost: item.unitCost.value || Zero,
        quantity: item.quantity.value || Zero,
        markupMultiplier: item.markup.value || Zero,
        postSubtotal: item.postSubtotal.value,
      }
    } else if (item.type.value === 'percent-priced') {
      return {
        type: 'percent-priced',
        listId: item.listId.value,
        isInactive: item.isInactive.value,
        feeMultiplier: item.fee.value || Zero,
        postSubtotal: item.postSubtotal.value,
      }
    } else {
      return {
        type: 'buffered-discount',
        listId: item.listId.value,
        isInactive: item.isInactive.value,
        bufferReverseMultiplier: item.bufferReverseMultiplier.value || Zero,
        discountMultiplier: item.discountMultiplier.value || Zero,
        isCommission: item.isCommission.value,
      }
    }
  }

  function buildAbstractGroupItemFromItemInput(item: ItemInput): AbstractGroupItem {
    return {
      type: 'group',
      listId: item.listId.value,
      isInactive: item.isInactive.value,
      children: item.children.map((c) => buildAbstractItemFromItemInput(c)),
    }
  }

  function buildAbstractItemFromPostValidationState(
    item: F.OutputShape<typeof schema>,
  ): AbstractItem {
    if (item.type === 'group') {
      return {
        type: 'group',
        listId: item.listId,
        isInactive: item.isInactive,
        children: item.children.map((c) =>
          buildAbstractItemFromPostValidationState(c as F.OutputShape<typeof schema>),
        ),
      }
    } else if (item.type === 'fixed-priced') {
      return {
        type: 'fixed-priced',
        listId: item.listId,
        isInactive: item.isInactive,
        unitCost: item.unitCost || Zero,
        quantity: item.quantity || Zero,
        markupMultiplier: item.markup || Zero,
        postSubtotal: item.postSubtotal,
      }
    } else if (item.type === 'percent-priced') {
      return {
        type: 'percent-priced',
        listId: item.listId,
        isInactive: item.isInactive,
        feeMultiplier: item.fee || Zero,
        postSubtotal: item.postSubtotal,
      }
    } else {
      return {
        type: 'buffered-discount',
        listId: item.listId,
        isInactive: item.isInactive,
        bufferReverseMultiplier: item.bufferReverseMultiplier || Zero,
        discountMultiplier: item.discountMultiplier || Zero,
        isCommission: item.isCommission,
      }
    }
  }

  function buildAbstractGroupItemFromPostValidationState(
    item: F.OutputShape<typeof schema>,
  ): AbstractGroupItem {
    return {
      type: 'group',
      listId: item.listId,
      isInactive: item.isInactive,
      children: item.children.map((c) =>
        buildAbstractItemFromPostValidationState(c as F.OutputShape<typeof schema>),
      ),
    }
  }

  type GroupValues = {
    l1Amount: BigNumber
    l2Amount: BigNumber
    l3Amount: BigNumber
    l4Amount: BigNumber
    cost: BigNumber
    commission: BigNumber
  }

  type BufferedDiscountValues = {
    allocatedBufferAmount: BigNumber
    totalBufferAmount: BigNumber
    discountAmount: BigNumber
    commission: BigNumber
  }

  type EstimateValuesResult = {
    groupValues: GroupValues
    bufferedDiscountValues: BufferedDiscountValues
    amount: BigNumber
    childResults: Record<string, EstimateValuesResult>
  }

  function getScalarItemValue(item: AbstractItem, baseValue: BigNumber): BigNumber {
    if (item.isInactive) {
      return Zero
    } else {
      if (item.type === 'percent-priced') {
        return BigNumber(baseValue.multipliedBy(item.feeMultiplier).toFixed(2))
      } else if (item.type === 'fixed-priced') {
        return EstimateUtils.calculateLineItemTotal({
          unitCost: item.unitCost || Zero,
          markup: item.markupMultiplier || Zero,
          quantity: item.quantity || Zero,
        })
      } else {
        return Zero
      }
    }
  }

  function getScalarItemCost(item: AbstractItem): BigNumber {
    if (item.type === 'fixed-priced') {
      return EstimateUtils.calculateLineItemTotal({
        unitCost: item.unitCost || Zero,
        markup: Zero,
        quantity: item.quantity || Zero,
      })
    } else {
      return Zero
    }
  }

  function applyReverseMultiplier(baseValue: BigNumber, multiplier: BigNumber): BigNumber {
    const factor = BigNumber(multiplier.dividedBy(BigNumber(1).minus(multiplier)).toFixed(12))
    return BigNumber(baseValue.multipliedBy(factor).toFixed(2))
  }

  function getDiscountValue(l3Value: BigNumber, discountMultiplier: BigNumber): BigNumber {
    return BigNumber(l3Value.multipliedBy(discountMultiplier).toFixed(2))
  }

  function buildEstimateValuesMap(node: AbstractGroupItem): EstimateValuesResult {
    const groupChildren = node.children.filter((x) => x.type === 'group')
    const scalarChildren = node.children.filter((x) => x.type !== 'group')
    const l1Children = scalarChildren.filter((x) => x.type === 'fixed-priced' && !x.postSubtotal)
    const l2Children = scalarChildren.filter((x) => x.type === 'percent-priced' && !x.postSubtotal)
    const l3Children = scalarChildren
      .filter((x) => x.type === 'buffered-discount')
      .map((x) => x as AbstractBufferedDiscountItem)
    const l4Children = scalarChildren.filter(
      (x) => (x.type === 'fixed-priced' || x.type === 'percent-priced') && x.postSubtotal,
    )
    const groupChildResults = Object.fromEntries(
      groupChildren
        .filter((x) => !x.isInactive)
        // TODO I shouldn't have to do this as?
        .map((x) => [x.listId, buildEstimateValuesMap(x as AbstractGroupItem)]),
    )
    const groupChildFlatResults = Object.values(groupChildResults)
      .map((y) => y.childResults)
      .reduce((a, c) => {
        return { ...a, ...c }
      }, {})

    const l1Results = Object.fromEntries(
      l1Children.map((x) => [x.listId, getScalarItemValue(x, Zero)]),
    )
    const l1Amount = BigNumber.sum(
      Zero,
      ...Object.values(groupChildResults).map((x) => x.amount),
    ).plus(BigNumber.sum(Zero, ...Object.values(l1Results)))
    const l2Results = Object.fromEntries(
      l2Children.map((x) => [x.listId, getScalarItemValue(x, l1Amount)]),
    )
    const l2Amount = BigNumber.sum(l1Amount, ...Object.values(l2Results))
    const totalBufferReverseMultiplier = BigNumber.sum(
      Zero,
      ...l3Children.map((x) => x.bufferReverseMultiplier || Zero),
    )
    const totalBufferAmount = applyReverseMultiplier(l2Amount, totalBufferReverseMultiplier)
    const l3Amount = l2Amount.plus(totalBufferAmount)
    const l3ChildrenExcludingLast = l3Children.slice(0, l3Children.length - 1)
    const lastL3Child = l3Children[l3Children.length - 1]
    const allocatedBufferAmountsExcludingLast = Object.fromEntries(
      l3ChildrenExcludingLast.map((x) => [
        x.listId,
        BigNumber(l3Amount.multipliedBy(x.bufferReverseMultiplier).toFixed(2)),
      ]),
    )
    const allocatedBufferAmounts = Object.fromEntries(
      MSArray.collapse([
        lastL3Child && [
          lastL3Child.listId,
          totalBufferAmount.minus(
            BigNumber.sum(Zero, ...Object.values(allocatedBufferAmountsExcludingLast)),
          ),
        ],
        ...Object.entries(allocatedBufferAmountsExcludingLast),
      ]),
    )

    const l4Results = Object.fromEntries(
      l4Children.map((x) => [x.listId, getScalarItemValue(x, l3Amount)]),
    )
    const bufferedDiscountResults = Object.fromEntries(
      l3Children.map((x) => [
        x.listId,
        {
          discountAmount: getDiscountValue(l3Amount, x.discountMultiplier || Zero),
          allocatedBufferAmount: allocatedBufferAmounts[x.listId],
          totalBufferAmount,
          isCommission: x.isCommission,
          commission: x.isCommission
            ? allocatedBufferAmounts[x.listId].minus(
                getDiscountValue(l3Amount, x.discountMultiplier || Zero),
              )
            : Zero,
        },
      ]),
    )
    const l4Amount = BigNumber.sum(
      l3Amount,
      ...Object.values(l4Results),
      ...Object.values(bufferedDiscountResults).map((x) => -x.discountAmount),
    )
    const scalarChildResults = Object.fromEntries([
      ...Object.entries(l1Results),
      ...Object.entries(l2Results),
      ...Object.entries(l4Results),
    ])
    const childResults = Object.fromEntries([
      ...Object.entries(groupChildFlatResults),
      ...Object.entries(groupChildResults),
      ...Object.entries(scalarChildResults).map(([key, amount]) => [
        key,
        {
          groupValues: {
            l1Amount: amount,
            l2Amount: amount,
            l3Amount: amount,
            l4Amount: amount,
            // this isn't correct, if someone cares about item-level costs, track it and include it here
            cost: Zero,
            commission: Zero,
          },
          bufferedDiscountValues: {
            totalBufferAmount: Zero,
            allocatedBufferAmount: Zero,
            discountAmount: Zero,
            commission: Zero,
          },
          amount,
          childResults: {},
        },
      ]),
      ...Object.entries(bufferedDiscountResults).map(([key, bufferedDiscountValues]) => [
        key,
        {
          groupValues: {
            l1Amount: bufferedDiscountValues.discountAmount,
            l2Amount: bufferedDiscountValues.discountAmount,
            l3Amount: bufferedDiscountValues.discountAmount,
            l4Amount: bufferedDiscountValues.discountAmount,
            // this isn't correct, if someone cares about item-level costs, track it and include it here
            cost: Zero,
            commission: Zero,
          },
          bufferedDiscountValues: {
            totalBufferAmount,
            allocatedBufferAmount: bufferedDiscountValues.allocatedBufferAmount,
            discountAmount: bufferedDiscountValues.discountAmount,
            commission: bufferedDiscountValues.isCommission
              ? bufferedDiscountValues.allocatedBufferAmount.minus(
                  bufferedDiscountValues.discountAmount,
                )
              : Zero,
          },
          amount: -bufferedDiscountValues.discountAmount,
          childResults: {},
        },
      ]),
    ])

    const commission = BigNumber.sum(
      Zero,
      ...Object.values(groupChildResults).map((x) => x.groupValues.commission),
      ...Object.values(bufferedDiscountResults).map((x) => x.commission),
    )
    const cost = BigNumber.sum(
      Zero,
      ...Object.values(groupChildResults).map((r) => r.groupValues.cost),
      ...scalarChildren.filter((x) => x.type === 'fixed-priced').map((x) => getScalarItemCost(x)),
    )

    return {
      groupValues: {
        l1Amount,
        l2Amount,
        l3Amount,
        l4Amount,
        cost,
        commission,
      },
      bufferedDiscountValues: {
        totalBufferAmount: Zero,
        allocatedBufferAmount: Zero,
        discountAmount: Zero,
        commission: Zero,
      },
      amount: l4Amount,
      childResults,
    }
  }

  export type EstimateCalculationContext = {
    getL1Amount: (state: ItemInput) => BigNumber
    getL2Amount: (state: ItemInput) => BigNumber
    getL3Amount: (state: ItemInput) => BigNumber
    getL4Amount: (state: ItemInput) => BigNumber
    getCost: (state: ItemInput) => BigNumber
    getLeafNodeAmount: (state: ItemInput) => BigNumber
    getCommission: (state: ItemInput) => BigNumber
    getTotalBufferAmount: (state: ItemInput) => BigNumber
    getDiscountAmount: (state: ItemInput) => BigNumber
  }

  export type PostValidationEstimateCalculationContext = {
    getL1Amount: (listId: string) => BigNumber
    getL2Amount: (listId: string) => BigNumber
    getL3Amount: (listId: string) => BigNumber
    getL4Amount: (listId: string) => BigNumber
    getCost: (listId: string) => BigNumber
    getLeafNodeAmount: (listId: string) => BigNumber
    getCommission: (listId: string) => BigNumber
    getTotalBufferAmount: (listId: string) => BigNumber
    getDiscountAmount: (listId: string) => BigNumber
  }

  export function getCalculationContext(state: ItemInput): EstimateCalculationContext {
    const root = buildAbstractGroupItemFromItemInput(state)
    const baseValues = buildEstimateValuesMap(root)
    const values = Object.fromEntries([
      ...Object.entries(baseValues.childResults),
      [root.listId, baseValues],
    ])

    return {
      getL1Amount: (innerState: ItemInput) => values[innerState.listId.value].groupValues.l1Amount,
      getL2Amount: (innerState: ItemInput) => values[innerState.listId.value].groupValues.l2Amount,
      getL3Amount: (innerState: ItemInput) => values[innerState.listId.value].groupValues.l3Amount,
      getL4Amount: (innerState: ItemInput) => values[innerState.listId.value].groupValues.l4Amount,
      getCost: (innerState: ItemInput) => values[innerState.listId.value].groupValues.cost,
      getLeafNodeAmount: (innerState: ItemInput) => values[innerState.listId.value].amount,
      getCommission: (innerState: ItemInput) =>
        values[innerState.listId.value].groupValues.commission,
      getTotalBufferAmount: (innerState: ItemInput) =>
        values[innerState.listId.value].bufferedDiscountValues.totalBufferAmount,
      getDiscountAmount: (innerState: ItemInput) =>
        values[innerState.listId.value].bufferedDiscountValues.discountAmount,
    }
  }

  export function getPostValidationCalculationContext(state: F.OutputShape<typeof schema>) {
    const root = buildAbstractGroupItemFromPostValidationState(state)
    const baseValues = buildEstimateValuesMap(root)
    const values = Object.fromEntries([
      ...Object.entries(baseValues.childResults),
      [root.listId, baseValues],
    ])

    return {
      getL1Amount: (listId: string) => values[listId].groupValues.l1Amount,
      getL2Amount: (listId: string) => values[listId].groupValues.l2Amount,
      getL3Amount: (listId: string) => values[listId].groupValues.l3Amount,
      getL4Amount: (listId: string) => values[listId].groupValues.l4Amount,
      getCost: (listId: string) => values[listId].groupValues.cost,
      getLeafNodeAmount: (listId: string) => values[listId].amount,
      getCommission: (listId: string) => values[listId].groupValues.commission,
      getTotalBufferAmount: (listId: string) =>
        values[listId].bufferedDiscountValues.totalBufferAmount,
      getDiscountAmount: (listId: string) => values[listId].bufferedDiscountValues.discountAmount,
    }
  }

  export function removeItemWithId(node: ItemInput, id: string): ItemInput | null {
    const index = node.children.findIndex((x) => x.listId.value === id)
    if (index >= 0) {
      const item = node.children[index]
      node.children._controller.remove(index)
      return item
    } else {
      return node.children.find((x) => removeItemWithId(x, id)) ?? null
    }
  }

  export function getRowInitFromInput(input: ItemInput): F.InitValue<typeof schema> {
    return {
      listId: input.listId.value,
      costCode: input.costCode.value,
      costType: input.costType.value,
      unit: input.unit.value,
      unitCost: input.unitCost.value,
      markup: input.markup.value,
      fee: input.fee.value,
      discountMultiplier: input.discountMultiplier.value,
      bufferReverseMultiplier: input.bufferReverseMultiplier.value,
      postSubtotal: input.postSubtotal.value,
      isCommission: input.isCommission.value,
      hideInnerItems: input.hideInnerItems.value,
      description: input.description.value,
      quantity: input.quantity.value,
      children: input.children.map((x) => getRowInitFromInput(x)),
      type: input.type.value,
    }
  }

  export function getIndexInParent(item: ItemInput, parent: ItemInput): number | null {
    const index = parent.children.findIndex((x) => x.listId.value === item.listId.value)
    if (index < 0) {
      return null
    } else {
      return index
    }
  }

  export function fromApi(
    lineItemNodes: Cb.EstimateLineItemNodesItem[],
    { costCodes, costTypes }: { costCodes: Cb.CostCode[]; costTypes: Cb.CostType[] },
  ): any {
    return lineItemNodes.map((node) => {
      if (node.group_details) {
        return {
          type: 'group' as const,
          listId: node.id,
          isInactive: node.is_inactive,
          hideInnerItems: node.group_details.hide_line_items,
          description: node.group_details.description,
          children: fromApi(node.children as any, { costCodes, costTypes }),
          costCode: costCodes.find((x) => x.id === node.group_details?.cost_code_id),
          isSection: node.group_details.is_section,
        }
      } else if (node.buffered_discount_details) {
        return {
          listId: node.id,
          isInactive: node.is_inactive,
          costCode: costCodes.find((x) => x.id === node.buffered_discount_details?.cost_code_id),
          costType: costTypes.find((x) => x.id === node.buffered_discount_details?.cost_type_id),
          description: node.buffered_discount_details.description,
          discountMultiplier: BigNumber(node.buffered_discount_details.discount_multiplier),
          bufferReverseMultiplier: BigNumber(
            node.buffered_discount_details.buffer_reverse_multiplier,
          ),
          isCommission: node.buffered_discount_details.is_commission,
          postSubtotal: true,
          type: 'buffered-discount' as const,
          children: [],
        }
      } else if (node.amount_details) {
        return {
          ...(node.amount_details.fee_multiplier
            ? {
                type: 'percent-priced' as const,
                fee: BigNumber(node.amount_details.fee_multiplier),
              }
            : {
                type: 'fixed-priced' as const,
                unitCost: BigNumber(node.amount_details.unit_cost),
                unit: node.amount_details.unit_type as any,
                markup: BigNumber(node.amount_details.markup_multiplier),
                quantity: BigNumber(node.amount_details.quantity),
              }),
          listId: node.id,
          isInactive: node.is_inactive,
          postSubtotal: node.amount_details.is_post_subtotal,
          costCode: costCodes.find((x) => x.id === node.amount_details?.cost_code_id),
          costType: costTypes.find((x) => x.id === node.amount_details?.cost_type_id),
          description: node.amount_details.description,
          children: [],
        }
      } else {
        return unhandled('Unexpected node type')
      }
    })
  }

  export function fromXactApi(xactModel: Cb.GetEstimateInputFromFileViewOutput): any {
    const items = xactModel.line_item_groups.flatMap((node) => {
      if (node.line_items.length > 1) {
        return {
          type: 'group' as const,
          listId: random(),
          hideInnerItems: false,
          description: node.description,
          children: node.line_items.map((x) => ({
            listId: random(),
            type: 'fixed-priced' as const,
            unitCost: BigNumber(x.unit_cost),
            unit: x.unit_type as any,
            markup: BigNumber(x.markup_multiplier),
            quantity: BigNumber(x.quantity),
            description: x.description,
            children: [],
          })),
        }
      } else if (node.line_items.length === 1) {
        return {
          type: 'fixed-priced' as const,
          unitCost: BigNumber(node.line_items[0].unit_cost),
          unit: node.line_items[0].unit_type as any,
          markup: BigNumber(node.line_items[0].markup_multiplier),
          quantity: BigNumber(node.line_items[0].quantity),
          listId: random(),
          description: node.description,
          children: [],
        }
      } else {
        return []
      }
    })
    const feeItems = xactModel.fixed_fee_items.map((item) => ({
      listId: random(),
      type: 'fixed-priced' as const,
      unitCost: BigNumber(item.amount),
      unit: '',
      markup: Zero,
      quantity: BigNumber(1),
      description: item.description,
      children: [],
      postSubtotal: true,
    }))
    return items.concat(feeItems)
  }

  export function allowDeleteInGroup(group: ItemInput) {
    return group.children.filter((x) => !x.isInactive.value).length > 1
  }

  export function presubtotalCount(group: ItemInput) {
    return group.children.filter((x) => !x.postSubtotal.value).length
  }
}

import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'

export default function useDebouncedValue<T>(value: T, interval = 300): T {
  const [state, setState] = useState(value)

  const setDebouncedState = useMemo(() => {
    return debounce(setState, interval)
  }, [interval])

  useEffect(() => setDebouncedState(value), [setDebouncedState, value])

  return state
}

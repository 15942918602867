import { ReactNode } from 'react'
import { cn } from 'msutils/classnames'
import Typography from 'compass/data/Typography'
import { t } from 'content'
import { BorderColors } from 'compass-local/utils/colors'
import * as InputBaseUtils from '../utils'
import { useInputBaseContext } from '../component-utils'
import Annotation from './Annotation'

type Props = InputBaseUtils.ExternalProps & {
  cursorType?: `cursor-${string}`
  isFocused: boolean
  setFocus: () => void
  lineHeight?: string
  children: ReactNode
}

export default function InputBase(props: Props) {
  const {
    subtitle,
    annotation,
    optional,
    disabled,
    isFocused,
    setFocus,
    cursorType,
    endIcon,
    lineHeight,
    children,
  } = props

  const ctx = useInputBaseContext()
  const base = props.base ?? ctx?.base
  const title = ctx?.title === 'force-empty' ? null : props.title
  const annotationStyle = props.annotationStyle ?? ctx?.annotationStyle

  return (
    <div className={cn('test-inputbase vflex gap-2', base?.maxWidth ?? 'w-full')}>
      {(title || subtitle) && (
        <div className={cn(!!subtitle && 'vflex gap-1')}>
          <div className="flex gap-2 items-baseline">
            {title && <Typography variant="label">{title}</Typography>}
            {optional && (
              <Typography variant="label" className="text-th-text-secondary">
                {t('Optional')}
              </Typography>
            )}
          </div>
          {subtitle && <Typography variant="caption">{subtitle}</Typography>}
        </div>
      )}
      <div
        onClick={setFocus}
        className={cn(
          'border transition-all duration-100',
          base?.rounded ?? 'rounded-2',
          // border-color
          annotation?.color === 'red' ||
            (base?.hideBorder && !isFocused) ||
            (disabled && base?.name === 'thin')
            ? 'border-transparent'
            : 'border-th-warmgrey-1',
          // bg
          disabled ? 'bg-th-coolgrey-3' : 'bg-white',
          // cursor
          disabled ? 'cursor-not-allowed' : cursorType,
          isFocused && 'shadow',
        )}
      >
        <div
          className={cn(
            'relative w-full border-2',
            base?.rounded ?? 'rounded-2',
            lineHeight,
            // border-color
            isFocused
              ? 'border-th-warmgrey-1'
              : annotation?.active && annotation.color === 'red'
              ? BorderColors.red
              : 'border-transparent',
            // color
            disabled && 'text-th-text-disabled',
            base?.fontWeight ?? 'font-medium',
            base?.padding ?? 'px-3 py-3.5',
          )}
        >
          {children}
          {endIcon && (
            <div className="absolute right-2 inset-y-0 vflex justify-center">{endIcon}</div>
          )}
        </div>
      </div>
      {annotationStyle !== 'border-only' && (
        <Annotation {...(annotation ?? { active: false, message: '' })} />
      )}
    </div>
  )
}

import { isEmptyStringOrIncludes } from 'utils/string'

export namespace Select2Utils {
  export type OptionProps<TVal, TMeta> = {
    options: TMeta[]
    getValueFromOption: (meta: TMeta) => TVal
    getId: (value: TVal) => string
    getTitle: (value: TVal) => string
    getOptionProps?: (meta: TMeta) => {
      disabled?: boolean
      hidden?: boolean
      subtitle?: string
    }
  }

  function isValidOption<TVal, TMeta>(meta: TMeta, props: OptionProps<TVal, TMeta>) {
    if (!!props.getOptionProps && props.getOptionProps(meta).disabled) {
      return false
    }
    if (!!props.getOptionProps && props.getOptionProps(meta).hidden) {
      return false
    }
    return true
  }

  export function getValueWithId<TVal, TMeta>(id: string | null, props: OptionProps<TVal, TMeta>) {
    const option = props.options.find((x) => props.getId(props.getValueFromOption(x)) === id)
    return option ? props.getValueFromOption(option) : null
  }

  export function first<TVal, TMeta>(props: OptionProps<TVal, TMeta>) {
    const firstOption = props.options.find((x) => isValidOption(x, props))
    return firstOption ? props.getId(props.getValueFromOption(firstOption)) : null
  }

  export function last<TVal, TMeta>(props: OptionProps<TVal, TMeta>) {
    const lastOption = [...props.options].reverse().find((x) => isValidOption(x, props))
    return lastOption ? props.getId(props.getValueFromOption(lastOption)) : null
  }

  export function next<TVal, TMeta>(id: string | null, props: OptionProps<TVal, TMeta>) {
    const index = props.options.findIndex((x) => props.getId(props.getValueFromOption(x)) === id)
    const nextOption = props.options.find((x, i) => isValidOption(x, props) && i > index)
    return nextOption ? props.getId(props.getValueFromOption(nextOption)) : first(props)
  }

  export function prev<TVal, TMeta>(id: string | null, props: OptionProps<TVal, TMeta>) {
    const index = [...props.options]
      .reverse()
      .findIndex((x) => props.getId(props.getValueFromOption(x)) === id)
    const prevOption = [...props.options]
      .reverse()
      .find((x, i) => isValidOption(x, props) && i > index)
    return prevOption ? props.getId(props.getValueFromOption(prevOption)) : last(props)
  }

  export function search<TVal, TMeta>(props: OptionProps<TVal, TMeta>, s: string) {
    return props.options.filter((x) =>
      isEmptyStringOrIncludes(props.getTitle(props.getValueFromOption(x)), s),
    )
  }
}

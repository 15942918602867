import { t } from 'content'
import { unreachable } from 'msutils/misc'
import { useSupportContext } from 'root/global'
import { Action } from 'utils/actions'
import { MSLayout } from 'utils/layout'

type Props = {
  title: string
  description?: string
  variant: 'thin' | 'normal'
}

export default function ScaleTierBanner({ title, description, variant }: Props) {
  const { setChatOpen } = useSupportContext()

  return (
    <MSLayout.Banner
      theme={
        variant === 'thin' ? 'gold-thin' : variant === 'normal' ? 'gold' : unreachable(variant)
      }
      icon="arrow-in-circle"
      title={title}
      description={description}
      actions={[
        Action.button(t('Talk to sales'), { theme: 'gold', onClick: () => setChatOpen(true) }),
      ]}
    />
  )
}

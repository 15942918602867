import { Cb, Q } from 'cb'
import { RichAddressInputUtils } from 'components/inputs/RichAddressInput'
import { useBusinessContext } from 'root/user'

export const moduleId = 'business-settings'

export function useUrlParams() {
  return {
    //
  }
}

export function useQueries() {
  const { business } = useBusinessContext()

  return Q.group({
    businessLogo: Cb.useListBusinessLogoEmbeddedFiles({ select: Q.opt }),
    externalCommunicationConfig: Cb.useRetrieveSingletonExternalCommunicationConfig(),
    generalProjectConfig: Cb.useRetrieveSingletonGeneralProjectConfig(),
    addressExactMatch: RichAddressInputUtils.useExactMatch(business.address?.raw_address ?? ''),
  })
}

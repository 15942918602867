import { LOGROCKET_KEY } from 'env'
import LogRocket from 'logrocket'
import { addTag } from 'utils/string'

const DefaultSanitizedFields = [
  'password',
  'session_token',
  'ein',
  'ssn',
  'ssn_last_4',
  'account_number',
]

function bodySanitizer(body: string | undefined): string | undefined {
  try {
    const parsed = JSON.parse(body ?? '')
    DefaultSanitizedFields.forEach((f) => {
      if (parsed[f]) {
        parsed[f] = '<REDACTED>'
      }
    })
    return JSON.stringify(parsed)
  } catch {
    return body
  }
}

export namespace LogRocketUtils {
  export function init() {
    LogRocket.init(LOGROCKET_KEY, {
      network: {
        requestSanitizer: (req) => {
          req.body = bodySanitizer(req.body)
          return req
        },
        responseSanitizer: (res) => {
          res.body = bodySanitizer(res.body)
          return res
        },
      },
    })
  }

  export function identify(u: {
    id: string
    name: string
    isImpersonator: boolean
    businessName?: string
    isGuest: boolean
    email: string | null
    phoneNumber: string | null
  }) {
    LogRocket.identify(u.id, {
      name: addTag(
        u.businessName ? u.businessName : null,
        addTag(
          u.isGuest ? 'Guest' : null,
          addTag(u.isImpersonator ? 'Impersonator' : null, u.name),
        ),
      ),
      ...(u.email ? { email: u.email } : {}),
      ...(u.phoneNumber ? { phoneNumber: u.phoneNumber } : {}),
    })
  }

  export function getSessionUrl() {
    return Promise.race([
      new Promise<string | null>((res) => {
        setTimeout(() => res(null), 10000)
      }),
      new Promise<string>((res) => {
        LogRocket.getSessionURL((x) => res(x))
      }),
    ])
  }
}

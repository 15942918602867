import { Cb } from 'cb'
import { t } from 'content'
import { permissionRules, PermissionRules } from './config'

export function allowed(action: keyof PermissionRules, role: Cb.EmployeeRole) {
  return permissionRules[action].includes(role)
}

export function getActionProps(action: keyof PermissionRules, role: Cb.EmployeeRole) {
  if (allowed(action, role)) {
    return {}
  } else {
    return {
      disabled: true,
      disabledMessage: t('This action is only available to account owners'),
    }
  }
}

export function getMenuOptionProps(action: keyof PermissionRules, role: Cb.EmployeeRole) {
  if (allowed(action, role)) {
    return {}
  } else {
    return {
      disabled: true,
      disabledMessage: t('This action is only available to account owners'),
    }
  }
}

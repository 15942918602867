import { FC, useState } from 'react'
import { t } from 'content'
import useFormProps from 'utils/useFormProps'
import { MosaicHref } from 'modules/routes/types'
import { useNavigate } from 'utils/router'
import { download as downloadToDevice, serializeFilename } from 'utils/file'
import { base } from './base'
import { Config, Divider, Qualified, ThemeProps } from './types'
import { noOpMount } from './internal-utils'

type FormProps<TFormProps extends object> = ThemeProps & {
  Form: FC<TFormProps & { isActive: boolean; setInactive: () => void }>
} & ({} extends TFormProps
    ? { qualify?: () => Qualified<TFormProps & {}> }
    : { qualify: () => Qualified<TFormProps & {}> }) & {
    controlledFormProps?: ReturnType<typeof useFormProps>
  }

export function form<TFormProps extends object>(name: string, props: FormProps<TFormProps>) {
  const qualify = props.qualify ?? (() => ({}) as TFormProps)

  return base(name, {
    useMountCtx: useFormProps,
    getMountProps: ({ qualCtx, mountCtx }) => ({
      ...qualCtx,
      ...(props.controlledFormProps ?? mountCtx),
    }),
    Mount: props.Form,
    type: 'button',
    onClick: ({ mountCtx }) => {
      if (props.controlledFormProps) {
        return props.controlledFormProps.setActive()
      } else {
        return mountCtx.setActive()
      }
    },
    ...props,
    qualify,
  })
}

type HrefProps = ThemeProps & {
  href: MosaicHref
  qualify?: () => Qualified<{}>
  onClick?: () => void
}

export function href(name: string, props: HrefProps) {
  const qualify = props.qualify ?? (() => ({}))
  return base(name, {
    useMountCtx: useNavigate,
    getMountProps: () => ({}),
    Mount: noOpMount,
    type: 'button',
    ...props,
    onClick: ({ mountCtx: navigate }) => {
      navigate(props.href)
      props.onClick?.()
    },
    qualify,
  })
}

export function useDownloadProps() {
  return useState(false)
}

type DownloadProps<TCtx extends object | true> = {
  qualify?: () => Qualified<TCtx & {}>
  getFile: (
    ctx: TCtx,
  ) =>
    | Promise<[name: string, file: File | Blob]>
    | [name: string, file: File | Blob]
    | [name: string, file: Promise<File | Blob>]
}

export function download<TCtx extends object | true = true>(
  name: string,
  props: DownloadProps<TCtx>,
) {
  const qualify = props.qualify ?? (() => true as TCtx)

  return base(name, {
    ...props,
    qualify,
    type: 'button',
    useMountCtx: useDownloadProps,
    getMountProps: () => ({}),
    Mount: noOpMount,
    isLoading: ([isLoading]) => isLoading,
    icon: 'download',
    onClick: async ({ qualCtx, mountCtx: [, setIsLoading] }) => {
      setIsLoading(true)
      try {
        const [filename, fileP] = await Promise.resolve(props.getFile(qualCtx))
        const file = await fileP
        downloadToDevice(file as File, serializeFilename(filename))
      } catch (e: any) {
        // pass
      } finally {
        setIsLoading(false)
      }
    },
  })
}

type ButtonProps = ThemeProps & {
  type?: 'dropdown' | 'button'
  options?: (Config | Divider)[]
  onClick?: () => void
  qualify?: () => Qualified<true>
  isLoading?: boolean
}

export function button(name: string, props: ButtonProps) {
  const qualify = props.qualify ?? (() => true)
  return base(name, {
    type: props.type ?? 'button',
    Mount: noOpMount,
    getMountProps: () => ({}),
    useMountCtx: () => ({}),
    qualify,
    ...props,
  })
}

export const divider: Divider = { type: 'divider', name: 'divider' }

type DropdownProps = ThemeProps & {
  options: (Config | Divider)[]
  qualify?: () => Qualified<true>
}

export function dropdown(name: string, props: DropdownProps) {
  return button(name, { type: 'dropdown', ...props })
}

type MoreProps = {
  qualify?: () => Qualified<true>
}

export function more(options: (Config | Divider)[], props?: MoreProps): Config {
  return dropdown(t('More actions'), { theme: 'text', options, ...props })
}

export function ellipsis(options: (Config | Divider)[]): Config {
  return dropdown('', {
    theme: 'text-small',
    icon: 'ellipsis',
    options,
    internalVariantDONOTUSE: 'ellipsis',
  })
}

export function addNew(options: (Config | Divider)[]): Config {
  return dropdown(t('Add new...'), { options })
}

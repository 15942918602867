/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListCardProgramsOrdering =
  (typeof ListCardProgramsOrdering)[keyof typeof ListCardProgramsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListCardProgramsOrdering = {
  status: 'status',
  '-status': '-status',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

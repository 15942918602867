import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import AnimatedHeight from 'react-animate-height'
import { cn } from 'msutils/classnames'
import { F, useOnChange } from 'msutils'
import debounce from 'lodash.debounce'
import { t } from 'content'
import { compassId } from '../_internal/utils'
import Typography from '../data/Typography'
import Icon from '../data/Icon'

type NamedIcon = typeof Icon extends (props: { name: infer X }) => any ? X : never

export namespace InputUtils {
  export type Theme = {
    fixedHeight: number | null
    textColor: `text-${string}`
    bgColor: `bg-${string}`
    borderColor: `outline-${string}`
    borderWidth: number
    disabledTextColor: `text-${string}`
    disabledBorderColor: `outline-${string}`
    disabledBgColor: `bg-${string}`
    focusedBorderColor: `outline-${string}`
    focusedBorderWidth: number
    focusedShadow: `shadow-${string}`
    hoverBorderColor: `hover:outline-${string}`
    hoverBorderWidth: number
    annotationStyle: 'standard' | 'border-only' | 'none'
  }

  const defaultTheme: Theme = {
    fixedHeight: 49,
    textColor: 'text-th-text',
    bgColor: 'bg-white',
    borderColor: 'outline-th-warmgrey-1',
    borderWidth: 1,
    disabledTextColor: 'text-th-text-disabled',
    disabledBorderColor: 'outline-th-warmgrey-1',
    disabledBgColor: 'bg-th-coolgrey-3',
    focusedBorderColor: 'outline-th-warmgrey-1',
    focusedBorderWidth: 3,
    focusedShadow: 'shadow-[0_2px_4px_2px_rgba(0,0,0,0.05)]',
    hoverBorderColor: 'hover:outline-th-warmgrey-1',
    hoverBorderWidth: 1,
    annotationStyle: 'standard',
  }

  type InputBaseContext = {
    theme?: Theme
    title?: 'force-empty'
    align?: 'right' | 'left'
  }
  const ContainerCtx = createContext<InputBaseContext>({})

  type ContextProps = InputBaseContext & {
    children: ReactNode
  }

  export function Context({ children, ...props }: ContextProps) {
    const parentCtx = useContext(ContainerCtx)
    const mergedCtx = useMemo(() => ({ ...parentCtx, ...props }), [props, parentCtx])
    return <ContainerCtx.Provider value={mergedCtx}>{children}</ContainerCtx.Provider>
  }

  type InputBaseComponentProps = {
    title?: string
    subtitle?: string
    optional?: boolean
    theme?: Theme
    annotation?: {
      message: string
      borderWidth: number
      borderColor: `outline-${string}`
      textColor: `text-${string}`
      icon?: NamedIcon
      iconHeight?: number
    }
    disabled: boolean
    children: ReactNode
  }

  export function InputBase({
    title,
    subtitle,
    optional,
    annotation,
    disabled,
    children,
    ...props
  }: InputBaseComponentProps) {
    const ctx = useContext(ContainerCtx)
    const theme = { ...defaultTheme, ...ctx.theme, ...props.theme }
    const [isHovered, setIsHovered] = useState(false)
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)

    const isFocused = containerRef?.contains(document.activeElement) ?? false

    return (
      <div className="vflex w-full">
        <div className="vflex w-full gap-2">
          {title && ctx.title !== 'force-empty' && (
            <div className="flex items-baseline gap-2">
              <Typography variant="label">{title}</Typography>
              {optional && (
                <Typography variant="label" className="text-th-text-secondary">
                  {t('Optional')}
                </Typography>
              )}
            </div>
          )}
          {subtitle && (
            <Typography variant="caption" className="text-th-text-secondary">
              {subtitle}
            </Typography>
          )}
          <div
            ref={setContainerRef}
            style={{
              height: theme.fixedHeight || undefined,
              outlineOffset:
                annotation && theme.annotationStyle !== 'none'
                  ? -annotation.borderWidth
                  : disabled
                  ? -theme.borderWidth
                  : isFocused
                  ? -theme.focusedBorderWidth
                  : isHovered
                  ? -theme.hoverBorderWidth
                  : -theme.borderWidth,
              outlineWidth:
                annotation && theme.annotationStyle !== 'none'
                  ? annotation.borderWidth
                  : disabled
                  ? theme.borderWidth
                  : isFocused
                  ? theme.focusedBorderWidth
                  : isHovered
                  ? theme.hoverBorderWidth
                  : theme.borderWidth,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={cn(
              'w-full outline transition-all duration-100',
              disabled ? 'cursor-not-allowed' : 'cursor-text',
              isFocused && !disabled && theme.focusedShadow,
              disabled
                ? theme.disabledBgColor
                : isFocused
                ? theme.bgColor
                : isHovered
                ? theme.bgColor
                : theme.bgColor,
              annotation && theme.annotationStyle !== 'none'
                ? annotation.borderColor
                : disabled
                ? theme.disabledBorderColor
                : isFocused
                ? theme.focusedBorderColor
                : isHovered
                ? theme.hoverBorderColor
                : theme.borderColor,
              disabled
                ? theme.disabledTextColor
                : isFocused
                ? theme.textColor
                : isHovered
                ? theme.textColor
                : theme.textColor,
            )}
          >
            {children}
          </div>
        </div>
        {theme.annotationStyle !== 'border-only' && theme.annotationStyle !== 'none' && (
          <AnimatedHeight height={annotation ? 'auto' : 0} duration={100}>
            {annotation && (
              <>
                <div className="h-2" />
                <div className={cn('flex gap-2 items-center', annotation.textColor)}>
                  {annotation.icon && (
                    <Icon name={annotation.icon} height={annotation.iconHeight} />
                  )}
                  <Typography>{annotation?.message ?? ''}</Typography>
                </div>
              </>
            )}
          </AnimatedHeight>
        )}
      </div>
    )
  }

  export const InputClassnames =
    'text-[14px] md:text-[14px] font-medium md:font-medium leading-[19.4px] md:leading-[17px]'

  type InputContainerProps = {
    placeholder?: string
    isEmpty: boolean
    containerRef?: Dispatch<SetStateAction<HTMLDivElement | null>>
    inputEl: HTMLElement | null
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    onClick?: () => void
    align?: 'right' | 'left'
    minWidth?: `min-w-[${number}px]` | null
    icon?: ReactNode
    text: string
    children: ReactNode
  }

  export function InputContainer({
    text,
    onMouseEnter,
    onMouseLeave,
    onClick,
    containerRef,
    inputEl,
    placeholder,
    isEmpty,
    minWidth,
    align,
    icon,
    children,
  }: InputContainerProps) {
    return (
      <div
        className={cn(
          compassId('input-container'),
          InputClassnames,
          'grow h-full flex gap-1 truncate',
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => {
          onClick?.()
          inputEl?.focus()
        }}
        ref={containerRef}
      >
        <div className="relative grow truncate">
          <div
            className={cn(
              compassId('input-size-setter'),
              minWidth,
              align === 'right' && 'text-right',
              'opacity-0 whitespace-pre w-full pl-3 truncate',
            )}
          >
            {text || ' '}
          </div>
          {isEmpty && placeholder && (
            <div
              className={cn(
                compassId('input-placeholder'),
                'absolute inset-0 flex items-center pl-3',
              )}
            >
              <div
                className={cn(
                  'flex text-th-text-hint truncate w-full',
                  align === 'right' && 'justify-end',
                )}
              >
                {placeholder}
              </div>
            </div>
          )}
          <div className="absolute inset-0 pl-3">
            <div className="relative h-full w-full">{children}</div>
          </div>
        </div>
        <div className="px-1 pointer-events-none flex items-center">{icon}</div>
      </div>
    )
  }

  export type InputStateProps<T> = {
    value: T
    willUpdate?: (props: { newValue: T; oldValue: T }) => void
    shouldUpdate?: (props: { newValue: T; oldValue: T }) => boolean
    update?: (newValue: T) => void
    didUpdate?: (props: { newValue: T; oldValue: T }) => void
    focus?: () => void
    blur?: () => void
    debounceUpdates?: boolean
  }

  export type InputBaseProps = {
    title?: string
    subtitle?: string
    theme?: Theme
    optional?: boolean
    placeholder?: string | null
    disabled?: boolean
    hidden?: boolean
    error?: string | null
    autofocus?: boolean
  }

  export type TextInputProps = {
    autoCapitalize?: boolean
    maxLength?: number
  }

  export function useStateProps<T>({
    value,
    willUpdate,
    shouldUpdate,
    update,
    didUpdate,
    focus,
    blur,
  }: InputStateProps<T>) {
    const [innerValue, setInnerValue] = useState(value)

    const outerUpdateInLifecycle = useMemo(() => {
      return (newValue: T) => {
        const values = { newValue, oldValue: value }
        if (shouldUpdate && shouldUpdate(values) === false) {
          //
        } else {
          willUpdate?.(values)
          update?.(newValue)
          didUpdate?.(values)
        }
      }
    }, [value, willUpdate, shouldUpdate, update, didUpdate])
    const updateRef = useRef(outerUpdateInLifecycle)
    updateRef.current = outerUpdateInLifecycle
    const debouncedUpdate = useMemo(
      () => debounce((newValue: T) => updateRef.current(newValue), 500),
      [],
    )

    const updateInLifecycle = useMemo(() => {
      return (newValue: T) => {
        setInnerValue(newValue)
        debouncedUpdate(newValue)
      }
    }, [debouncedUpdate])

    const onBlur = useMemo(() => {
      return () => {
        debouncedUpdate.flush()
        setTimeout(() => blur?.(), 10)
      }
    }, [blur, debouncedUpdate])

    useOnChange([value], () => {
      if (value !== innerValue) {
        setInnerValue(value)
      }
    })

    return useMemo(
      () => ({ value: innerValue, update: updateInLifecycle, focus, blur: onBlur }),
      [innerValue, updateInLifecycle, focus, onBlur],
    )
  }

  export type BorderStyle = {
    width: 'sm' | 'lg'
    color: 'red' | 'grey'
  } | null

  export function getBorderForInput(s: F.InputCell<any>): BorderStyle {
    const isFocused = s.status === 'touched' || s.status === 'retouched' || s.status === 'editing'
    const hasUserError = s.hasErrorWithin && s.status !== 'clean'
    return {
      width: isFocused || hasUserError ? 'lg' : 'sm',
      color: hasUserError && !isFocused ? 'red' : 'grey',
    }
  }
}

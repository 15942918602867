/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListRawQboLinksOrdering =
  (typeof ListRawQboLinksOrdering)[keyof typeof ListRawQboLinksOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListRawQboLinksOrdering = {
  source_id: 'source_id',
  '-source_id': '-source_id',
  type: 'type',
  '-type': '-type',
  clean: 'clean',
  '-clean': '-clean',
  do_not_push: 'do_not_push',
  '-do_not_push': '-do_not_push',
  do_not_pull: 'do_not_pull',
  '-do_not_pull': '-do_not_pull',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

import { useState } from 'react'
import { Cb, Q } from 'cb'
import Table, { TableUtils } from 'compass-local/layout/Table'
import Modal from 'compass-local/Modal'
import SearchInput from 'compass-local/SearchInput'
import { t } from 'content'
import { Action } from 'utils/actions'
import { isEmptyStringOrIncludes } from 'utils/string'
import withInjectedQueriesDONOTUSE, { AwaitedQueryInjectedProps } from 'utils/withInjectedQueries'

const col = TableUtils.colSpec<Cb.CostLibraryItem>()
const columns = [
  col(t('Description'), { accessor: 'description', size: { maximize: true } }),
  col.currency(t('Unit cost'), { accessor: 'unit_cost' }),
]

function useQueries() {
  return Q.group({
    costLibraryItems: Cb.useListCostLibraryItems(),
  })
}

type Props = {
  onSuccess: (result: Cb.CostLibraryItem[]) => void
  _queryset: AwaitedQueryInjectedProps<typeof useQueries>
  setInactive?: () => void
}

const ImportFromCostLibrary = Modal.asModal(({ onSuccess, setInactive, _queryset }: Props) => {
  const { costLibraryItems } = _queryset
  const [search, setSearch] = useState('')
  const selections = TableUtils.useColumnState()

  const tableSpec = TableUtils.useSpec({
    data: costLibraryItems.filter(
      (x) => search.length < 2 || isEmptyStringOrIncludes(x.description ?? '', search),
    ),
    columns,
    allowSelect: true,
    state: { selected: selections },
    getId: (x) => x.id,
  })

  const numItems = Object.entries(selections.ids).filter(([, v]) => v).length

  return (
    <Modal.Body2
      title={t('Import from cost library')}
      actions={[
        Action.button(
          numItems === 0 || numItems === 1 ? t('Add') : t('Add {{ X }} items', { X: numItems }),
          {
            qualify: () => (numItems === 0 ? '' : true),
            onClick: () => {
              onSuccess(costLibraryItems.filter((x) => selections.isActive(x.id)))
              setInactive?.()
            },
          },
        ),
      ]}
    >
      <SearchInput value={search} update={setSearch} autofocus />
      <div className="h-[340px] overflow-y-auto px-5">
        <Table spec={tableSpec} />
      </div>
    </Modal.Body2>
  )
})

export default withInjectedQueriesDONOTUSE(useQueries, ImportFromCostLibrary, null, null)

import BigNumber from 'bignumber.js'
import { Cb } from 'cb'
import Select2 from 'compass-local/input/Select2'
import TextArea2 from 'compass-local/input/TextArea2'
import { getBorderForInput } from 'compass-local/input/utils'
import Table, { TableUtils } from 'compass-local/layout/Table'
import { Plus } from 'compass-local/legacy/icons'
import LinkButtonDONOTUSE from 'compass-local/legacy/LinkButtonDONOTUSE'
import Metric from 'compass-local/Metric'
import AccountingCodeSelect from 'components/inputs/AccountingCodeSelect'
import CostTypeSelect from 'components/inputs/CostTypeSelect'
import { t } from 'content'
import { EstimateUtils } from 'features/estimates/utils'
import { F, Format, MSArray, MSError2, random } from 'msutils'
import { unhandled, unreachable } from 'msutils/misc'
import { useAnalyticsContext } from 'root/global'
import { useBusinessContext } from 'root/user'
import TextInput from 'compass/input/TextInput'
import useScreenSize from 'compass/theme/useScreenSize'
import { theme2 } from 'theme2'
import { ref } from 'utils/misc'
import DropdownButton from 'compass/data/Button/DropdownButton'
import Icon from 'compass/data/Icon'
import Button from 'compass/data/Button'
import ImportFromCostLibrary from 'features/estimates/components/ImportFromCostLibrary'
import useFormProps from 'utils/useFormProps'
import { EstimateItemsInputUtils, EstimateItemsInputUtils as Utils } from '../utils'

type Ctx = {
  state: Utils.ItemInput
  calculationContext: Utils.EstimateCalculationContext
  generalProjectConfig: Cb.GeneralProjectConfig
  hasAccountingCodes: boolean
  hasCostTypes: boolean
  allowPostSubtotalConfiguration: boolean
  noFooters: boolean
  capabilities: EstimateUtils.Capability[]
}

type SubtotalFooterProps = {
  parentState: Utils.ItemInput
  calculationContext: EstimateItemsInputUtils.EstimateCalculationContext
}

// parent_state is the state of the parent of the row we're adding a header to.
function SubtotalFooter({ parentState, calculationContext }: SubtotalFooterProps) {
  const hasBufferedDiscounts = MSArray.isNonEmpty(
    parentState.children.filter((x) => x.type.value === 'buffered-discount'),
  )
  const subtotal = calculationContext.getL2Amount(parentState)
  // TODO only show this if there's a buffered discount
  // Also figure out whether we want to call the other one subtotal or costs subtotal?
  const clientFacingSubtotal = calculationContext.getL3Amount(parentState)
  return (
    <div className="px-3 py-2 flex justify-end gap-5">
      <Metric align="right" k={t('Subtotal')} v={Format.currency(subtotal)} />
      {hasBufferedDiscounts && (
        <Metric
          align="right"
          k={t('Client-facing subtotal')}
          v={Format.currency(clientFacingSubtotal)}
        />
      )}
    </div>
  )
}

type GroupFooterProps = {
  group: Utils.ItemInput
  ctx: Ctx
}

function GroupFooter({ group, ctx }: GroupFooterProps) {
  const lastItemIndex = ref(
    [...group.children].reverse().findIndex((x) => x.type.value === 'fixed-priced'),
  ).map((x) => (x < 0 ? null : group.children.length - x - 1))
  const preSubtotalInsertIndex = ref(group.children.findIndex((x) => x.postSubtotal.value)).map(
    (x) => (x < 0 ? group.children.length - 1 : Math.max(x - 1, 0)),
  )
  const defaultMarkup = ref(ctx.generalProjectConfig.default_markup_multiplier).map((x) =>
    BigNumber(x),
  )

  return (
    <div className="px-3 py-2 flex justify-between">
      <LinkButtonDONOTUSE
        icon={<Plus height={16} />}
        onClick={() => {
          group.children._controller.insertAt(preSubtotalInsertIndex, {
            type: 'fixed-priced',
            costCode: group.costCode.value,
            costType: group.costType.value,
            listId: random(),
            markup: lastItemIndex
              ? group.children.at(lastItemIndex)?.markup.value ?? defaultMarkup
              : defaultMarkup,
            children: [],
          })
        }}
      >
        {t('Add item')}
      </LinkButtonDONOTUSE>
    </div>
  )
}

function Footer({
  state,
  calculationContext,
  generalProjectConfig,
  didAddReversedDiscount,
}: Ctx & { didAddReversedDiscount?: () => void }) {
  const costCodes = Cb.useListCostCodes().data ?? []
  const sz = useScreenSize()
  const { allowCreateBufferedDiscounts, showCostLibrary } = useAnalyticsContext().flags
  const lastItemIndex = ref(
    [...state.children].reverse().findIndex((x) => x.type.value === 'fixed-priced'),
  ).map((x) => (x < 0 ? null : state.children.length - x - 1))
  const preSubtotalInsertIndex = ref(state.children.findIndex((x) => x.postSubtotal.value)).map(
    (x) => (x < 0 ? state.children.length - 1 : Math.max(x - 1, 0)),
  )
  const defaultMarkup = ref(generalProjectConfig.default_markup_multiplier).map((x) => BigNumber(x))
  const showCostLibraryProps = useFormProps()

  const buttonProps = MSArray.collapse([
    {
      name: t('Add item'),
      onClick: () =>
        state.children._controller.insertAt(preSubtotalInsertIndex, {
          listId: random(),
          markup: lastItemIndex
            ? state.children.at(lastItemIndex)?.markup.value ?? defaultMarkup
            : defaultMarkup,
          children: [],
        }),
    },
    {
      name: t('Add % fee'),
      onClick: () =>
        state.children._controller.append({
          listId: random(),
          type: 'percent-priced',
          postSubtotal: true,
          children: [],
        }),
    },
    {
      name: t('Add group'),
      onClick: () =>
        state.children._controller.insertAt(preSubtotalInsertIndex, {
          listId: random(),
          type: 'group',
          children: [
            {
              listId: random(),
              markup: defaultMarkup,
              children: [],
            },
          ],
        }),
    },
    allowCreateBufferedDiscounts && {
      name: t('Add reversed discount'),
      onClick: () => {
        didAddReversedDiscount?.()
        state.children._controller.append({
          listId: random(),
          type: 'buffered-discount',
          postSubtotal: true,
          children: [],
        })
      },
    },
    showCostLibrary && {
      name: t('Add from library'),
      onClick: showCostLibraryProps.setActive,
    },
  ])

  return (
    <div className="px-3 py-2 flex justify-between gap-4">
      <ImportFromCostLibrary
        {...showCostLibraryProps}
        onSuccess={(items) => {
          items.forEach((item) => {
            state.children._controller.append({
              listId: random(),
              type: 'fixed-priced',
              description: item.description ?? '0',
              unit: (item.unit_type ?? '') as any,
              unitCost: item.unit_cost ? BigNumber(item.unit_cost) : '',
              costCode: costCodes.find((x) => x.id === item.cost_code_id),
              markup: defaultMarkup,
              children: [],
            })
          })
        }}
      />
      {sz === 'sm' ? (
        <div className="w-min flex items-center">
          <DropdownButton
            icon={<Icon name="plus" />}
            theme={theme2.ButtonThemeTextOrange}
            closeButtonTheme={theme2.ButtonThemeLight}
            options={buttonProps.map((x) => ({
              ...x,
              icon: undefined,
            }))}
          >
            {t('Add new...')}
          </DropdownButton>
        </div>
      ) : (
        <div className="flex items-center gap-4">
          {buttonProps.map((x) => (
            <Button
              key={x.name}
              theme={theme2.ButtonThemeTextOrange}
              icon={<Icon name="plus" />}
              onClick={x.onClick}
            >
              {x.name}
            </Button>
          ))}
        </div>
      )}
      <Metric
        align="right"
        k={t('Total')}
        v={Format.currency(calculationContext.getL4Amount(state))}
      />
    </div>
  )
}

const col = TableUtils.colSpec<Utils.ItemInput, Ctx>()
const columns = [
  col('', { dynamic: { mobileOnly: () => true }, position: 'title', accessor: () => <></> }),
  col(t('Accounting code'), {
    border: ({ row }) => getBorderForInput(row.costCode),
    size: { minimize: true, maxWidth: 'max-w-[200px]' },
    dynamic: { omit: (ctx) => !ctx.hasAccountingCodes },
    accessor: ({ row }) => (
      <AccountingCodeSelect perspective="payer" v2 {...F.props(row.costCode)} />
    ),
  }),
  col(t('Cost type'), {
    border: ({ row }) => getBorderForInput(row.costType),
    dynamic: { omit: (ctx) => !ctx.hasCostTypes },
    size: { minimize: true },
    accessor: ({ row }) => <CostTypeSelect v2 {...F.props(row.costType)} />,
  }),
  col(t('Description'), {
    border: ({ row }) =>
      row.description.status === 'touched' ||
      row.description.status === 'retouched' ||
      row.description.status === 'editing'
        ? null
        : getBorderForInput(row.description),
    size: { maximize: true, minWidth: 'min-w-[140px]' },
    accessor: ({ row }) => <TextArea2 {...F.props(row.description)} defaultHeight={40} />,
  }),
  col(t('Unit cost'), {
    border: ({ row }) =>
      row.type.value === 'fixed-priced'
        ? getBorderForInput(row.unitCost)
        : row.type.value === 'percent-priced'
        ? getBorderForInput(row.fee)
        : row.type.value === 'buffered-discount'
        ? getBorderForInput(row.discountMultiplier)
        : null,
    align: 'right',
    void: ({ row }) => row.type.value === 'group',
    dynamic: { omit: (ctx) => !ctx.capabilities.includes('view-markup') },
    inputTitle: ({ row }) => (row.type.value === 'percent-priced' ? t('Fee') : undefined),
    accessor: ({ row }) =>
      row.type.value === 'fixed-priced' ? (
        <TextInput
          type="currency"
          {...F.props(row.unitCost)}
          theme={theme2.InputThemeNone}
          decimalPlaces={5}
        />
      ) : row.type.value === 'percent-priced' ? (
        <TextInput type="percent" {...F.props(row.fee)} theme={theme2.InputThemeNone} />
      ) : row.type.value === 'buffered-discount' ? (
        <TextInput
          type="percent"
          {...F.props(row.discountMultiplier)}
          disabled={row.isCommission.value}
          didUpdate={({ newValue }) => {
            if (!row.isCommission.value) {
              row.bufferReverseMultiplier.update(newValue)
            }
          }}
          theme={theme2.InputThemeNone}
        />
      ) : (
        unhandled('Unexpected row type for unit cost')
      ),
  }),
  col(t('Qty'), {
    border: ({ row }) => getBorderForInput(row.quantity),
    align: 'right',
    void: ({ row }) => row.type.value !== 'fixed-priced',
    accessor: ({ row }) => (
      <TextInput
        type="decimal"
        {...F.props(row.quantity)}
        theme={theme2.InputThemeNone}
        decimalPlaces={3}
      />
    ),
  }),
  col(t('Unit'), {
    border: ({ row }) => getBorderForInput(row.unit),
    void: ({ row }) => row.type.value !== 'fixed-priced',
    accessor: ({ row }) => (
      <Select2
        {...F.props(row.unit)}
        placeholder={null}
        options={EstimateUtils.UnitTypes}
        getTitle={(x) => x}
        getId={(x) => x}
        getValueFromOption={(x) => x.id}
      />
    ),
  }),
  col(t('Markup'), {
    border: ({ row }) => getBorderForInput(row.markup),
    align: 'right',
    dynamic: { omit: (ctx) => !ctx.capabilities.includes('view-markup') },
    void: ({ row }) => row.type.value !== 'fixed-priced',
    accessor: ({ row }) => (
      <TextInput type="percent" {...F.props(row.markup)} theme={theme2.InputThemeNone} />
    ),
  }),
  col(t('Total'), {
    align: 'right',
    size: { minWidth: 'min-w-[110px]' },
    bold: ({ depth }) => depth === 0,
    nonInput: true,
    accessor: ({ row }, ctx) => {
      return (
        <div className="py-2 md:px-3">
          {Format.currency(ctx.calculationContext.getLeafNodeAmount(row))}
        </div>
      )
    },
  }),
]

type Props = {
  state: F.Input<typeof Utils.schema>
  calculationContext: EstimateItemsInputUtils.EstimateCalculationContext
  generalProjectConfig: Cb.GeneralProjectConfig
  filter?: (item: F.Input<typeof Utils.schema>) => boolean
  noFooters?: boolean
  noHeader?: boolean
  didAddReversedDiscount?: () => void
  disablePostSubtotalConfiguration?: boolean
  capabilities: EstimateUtils.Capability[]
}

export default function EstimateItemsInput(props: Props) {
  const {
    state,
    calculationContext,
    generalProjectConfig,
    didAddReversedDiscount,
    capabilities,
    disablePostSubtotalConfiguration,
  } = props
  const { hasCostTypes, hasAccountingCodes } = useBusinessContext()
  const rowCount = state.children.flatMap((x) =>
    x.type.value === 'group' ? x.children : [x],
  ).length

  const spec = TableUtils.useSpec({
    data: props.filter ? state.children.filter(props.filter) : state.children,
    columns,
    ctx: {
      state,
      calculationContext,
      generalProjectConfig,
      hasAccountingCodes,
      hasCostTypes,
      allowPostSubtotalConfiguration: !disablePostSubtotalConfiguration,
      noFooters: props.noFooters ?? false,
      capabilities,
    },
    defaultValues: { expanded: rowCount < 50 },
    rowActions: (row, { id, parent, depth, ctx }) =>
      ctx.capabilities.includes('edit-structure')
        ? [
            row.type.value === 'group' && {
              label: row.hideInnerItems.value ? t('Show sub-items') : t('Hide sub-items'),
              onClick: () => row.hideInnerItems.update(!row.hideInnerItems.value),
            },
            (row.type.value === 'fixed-priced' || row.type.value === 'percent-priced') &&
              depth === 0 &&
              (row.postSubtotal.value || Utils.presubtotalCount(parent ?? ctx.state) > 1) &&
              Utils.allowDeleteInGroup(parent ?? ctx.state) &&
              ctx.allowPostSubtotalConfiguration && {
                label: row.postSubtotal.value ? t('Move pre-subtotal') : t('Move post-subtotal'),
                onClick: () => {
                  const parentState = parent ?? ctx.state
                  const index = Utils.getIndexInParent(row, parentState)
                  const preSubtotalInsertIndex = ref(
                    parentState.children.findIndex((x) => x.postSubtotal.value),
                  ).map((x) => (x < 0 ? parentState.children.length - 1 : Math.max(x - 1, 0)))

                  row.postSubtotal.update(!row.postSubtotal.value)
                  if (row.postSubtotal.value) {
                    if (index !== null) {
                      parentState.children._controller.move(index, 0)
                    }
                  } else {
                    if (index !== null) {
                      parentState.children._controller.move(index, preSubtotalInsertIndex)
                    }
                  }
                },
              },
            Utils.allowDeleteInGroup(parent ?? ctx.state) &&
              (row.postSubtotal.value || Utils.presubtotalCount(parent ?? ctx.state) > 1) &&
              row.type.value !== 'buffered-discount' && {
                label: t('Hide item'),
                onClick: () => row.isInactive.update(true),
              },
            { type: 'divider' },
            Utils.allowDeleteInGroup(parent ?? ctx.state) &&
              (row.postSubtotal.value || Utils.presubtotalCount(parent ?? ctx.state) > 1) && {
                label: t('Delete'),
                variant: 'danger',
                onClick: () => Utils.removeItemWithId(ctx.state, id),
              },
          ]
        : [],
    getId: (x) => x.listId.value,
    dragId: (x, { parent }) =>
      parent?.children.length === 1
        ? '<none>'
        : x.type.value === 'group'
        ? 'group'
        : x.type.value === 'percent-priced'
        ? 'percent-item'
        : x.type.value === 'fixed-priced'
        ? 'item'
        : x.type.value === 'buffered-discount'
        ? 'buffered-discount'
        : unreachable(x.type.value),
    dropIds: (row, { depth, ctx }) =>
      ctx.capabilities.includes('edit-structure')
        ? row.postSubtotal.value
          ? MSArray.collapse(['percent-item', ctx.allowPostSubtotalConfiguration && 'item'])
          : MSArray.collapse([
              'item',
              ctx.allowPostSubtotalConfiguration && depth === 0 && 'percent-item',
              depth === 0 && 'group',
            ])
        : [],
    handleMove: (fromRow, toRow) => {
      try {
        const fromParent = fromRow.parent ?? state
        const fromIndex = Utils.getIndexInParent(fromRow.row, fromParent)
        const toParent = toRow.parent ?? state
        const toIndex = Utils.getIndexInParent(toRow.row, toParent)
        const preSubtotalIndex = ref(toParent.children.findIndex((x) => x.postSubtotal.value)).map(
          (x) => (x < 0 ? null : x),
        )

        if (fromIndex !== null && toIndex !== null) {
          if (fromParent === toParent) {
            fromParent.children._controller.move(fromIndex, toIndex)
            toRow.row.postSubtotal.update(preSubtotalIndex !== null && toIndex >= preSubtotalIndex)
          } else {
            const rowToRemove = fromParent.children[fromIndex]
            toParent.children._controller.insertAt(toIndex, {
              ...Utils.getRowInitFromInput(rowToRemove),
              postSubtotal: preSubtotalIndex !== null && toIndex >= preSubtotalIndex,
            })
            fromParent.children._controller.remove(fromIndex)
          }
        } else {
          MSError2.report(
            new MSError2(`Unable to find index for to (${toIndex}) or from (${fromIndex})`, {
              name: 'DndError',
            }),
          )
        }
      } catch (e: any) {
        MSError2.report(MSError2.convertError(e))
      }
    },
    rowFooter: (row, { ctx }) => {
      if (row.type.value === 'group' && !ctx.noFooters && capabilities.includes('edit-structure')) {
        return { component: <GroupFooter group={row} ctx={ctx} /> }
      } else {
        return undefined
      }
    },
    rowHeader: (row, { ctx, parent }) => {
      const firstPostSubtotalItem = ctx.state.children.find((x) => x.postSubtotal.value)
      if (
        row.listId.value === firstPostSubtotalItem?.listId.value &&
        !ctx.noFooters &&
        MSArray.isNonEmpty(
          ctx.state.children.filter((x) => x.postSubtotal.value && !x.isInactive.value),
        )
      ) {
        return {
          component: (
            <SubtotalFooter
              calculationContext={calculationContext}
              parentState={parent ?? ctx.state}
            />
          ),
          showWhenInactive: true,
        }
      } else {
        return undefined
      }
    },
    isInactive: (row) => row.isInactive.value,
    setActive: (row) => row.isInactive.update(false),
    footer: (ctx) => ({
      component: !ctx.noFooters && ctx.capabilities.includes('edit-structure') && (
        <Footer {...ctx} didAddReversedDiscount={didAddReversedDiscount} />
      ),
    }),
    subrows: (x) => (x.type.value === 'group' ? x.children : undefined),
  })

  return <Table spec={spec} style="input-table" />
}

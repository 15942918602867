import { Cb } from 'cb'
import { unreachable } from 'msutils/misc'
import { t } from 'content'
import { MSArray, Zero } from 'msutils'
import { RichAddressInputUtils } from 'components/inputs/RichAddressInput'
import { unionConcatDONOTUSE } from 'utils/array'
import BigNumber from 'bignumber.js'

export namespace ProjectUtils {
  class NoProject {
    id = undefined
  }

  export const NoneProject = new NoProject()

  export type ProjectSelection = NoProject | Cb.Project

  export function isProject(val: Cb.Project | NoProject): val is Cb.Project {
    return !(val instanceof NoProject)
  }

  export function widenProjectSelection(value: ProjectSelection | null): ProjectSelection | null {
    return value
  }

  export function contractIsFullyBilled(summary: Cb.ContractProgressSummary) {
    return BigNumber(summary.billed_amount).gte(BigNumber(summary.scheduled_value))
  }

  export function getRetainageTotal(item: Cb.ContractItemsItem) {
    // round?
    return BigNumber(item.retainage_multiplier).multipliedBy(BigNumber(item.current_amount))
  }

  export function getSubcontractDisplayName(contract: { name: string; payee_name: string }) {
    return contract.name || contract.payee_name
  }

  export function getProjectDisplayName(project: {
    name: string
    address_2: Cb.RichOrRawAddress | null
  }) {
    return project.name || RichAddressInputUtils.formatApi(project.address_2) || ''
  }

  export function contractHasRetainage(contract: Cb.Contract) {
    return contract.items
      .filter((x) => !x.archived)
      .some((x) => BigNumber(x.retainage_multiplier).gt(Zero))
  }

  export function getProjectDeleteNotAllowedReasonMessage(
    reason: Cb.ProjectPermissionDeletePermissionNotAllowedReason | null,
  ) {
    switch (reason) {
      case null:
        return null
      case 'not_creator':
        return t('You can only delete a project if you are the creator.')
      case 'invoice_exists':
        return t('You can only delete a project if there are no invoices, bills, or expenses.')
      case 'unspecified_restriction_exists':
        return t('You cannot delete the project for an unknown reason. Please contact support.')
      default:
        return unreachable(reason)
    }
  }

  export function formatCardholder(
    x:
      | Cb.CardTransactionListRow
      | Cb.ExpenseCard
      | NonNullable<Cb.ExpenseListRow['expense_card_details']>,
  ) {
    const cardholder = x.cardholder_name
    const last4 = 'last_4' in x ? x.last_4 : x.card_last_4
    return t('{{ X1 }} ••••{{ X2 }}', { X1: cardholder, X2: last4 })
  }

  export function getProjectStatus(project: Cb.Project | Cb.ProjectListRow) {
    if (project.end_date) {
      return 'completed'
    } else {
      return 'active'
    }
  }

  export function projectIsComplete(project: Cb.Project) {
    return getProjectStatus(project) === 'completed'
  }

  export function getContractTotalDONOTUSE(contract: Cb.Contract) {
    return MSArray.sumAmount(contract.items.filter((x) => !x.archived).map((x) => x.current_amount))
  }

  export function getContractTotal(contract: Cb.Contract) {
    return BigNumber.sum(
      Zero,
      ...contract.items.filter((x) => !x.archived).map((x) => x.current_amount),
    )
  }

  export function contractHasPreviouslyBilled(contract: Cb.Contract) {
    return contract.items
      .filter((x) => !x.archived)
      .some((x) => BigNumber(x.amount_billed_externally).gt(Zero))
  }

  export function getContractRetainageDefault(contract: Cb.Contract) {
    const retainageItem = contract.items
      .filter((x) => !x.archived)
      .find((x) => BigNumber(x.retainage_multiplier).gt(Zero))
    return BigNumber(retainageItem?.retainage_multiplier ?? '0')
  }

  type ChangeOrder =
    | { key: 'co'; value: Cb.ChangeOrder }
    | { key: 'rco'; value: Cb.RemovalChangeOrder }

  export type ChangeOrderEstimate =
    | {
        changeOrder: ChangeOrder
        estimate: Cb.Estimate
        invoices?: Cb.Invoice[]
      }
    | {
        changeOrder: ChangeOrder
        estimate: null
        invoices?: Cb.Invoice[]
      }
    | {
        changeOrder: null
        estimate: Cb.Estimate
        invoices?: Cb.Invoice[]
      }

  type ZipChangeOrdersWithEstimatesAndInvoicesProps = {
    changeOrders: Cb.ChangeOrder[]
    removalChangeOrders: Cb.RemovalChangeOrder[]
    estimates: Cb.Estimate[]
    invoices?: Cb.Invoice[]
  }
  export function zipChangeOrdersWithEstimatesAndInvoices({
    changeOrders,
    removalChangeOrders,
    estimates,
    invoices,
  }: ZipChangeOrdersWithEstimatesAndInvoicesProps): ChangeOrderEstimate[] {
    const allChangeOrders = unionConcatDONOTUSE(
      changeOrders.map((c) => ({ key: 'co' as const, value: c })),
      removalChangeOrders.map((c) => ({ key: 'rco' as const, value: c })),
    )

    const entries: ChangeOrderEstimate[] = estimates.map((e) =>
      e.created_change_order_id
        ? {
            changeOrder:
              allChangeOrders.find((co) => co.value.id === e.created_change_order_id) ?? null,
            estimate: e,
            invoices: invoices?.filter((i) =>
              i.line_items.some(
                (li) =>
                  li.contract_item_id ===
                  allChangeOrders.find((co) => co.value.id === e.created_change_order_id)?.value
                    ?.schedule_of_values_item_id,
              ),
            ),
          }
        : { changeOrder: null, estimate: e, invoices: [] },
    )

    const recordedChangeOrders = allChangeOrders
      .filter((co) => !entries.some((e) => e.changeOrder?.value.id === co.value.id))
      .map((co) => ({
        changeOrder: co,
        estimate: null,
        invoices: invoices?.filter((i) =>
          i.line_items.some((li) => li.contract_item_id === co.value.schedule_of_values_item_id),
        ),
      }))

    return entries.concat(recordedChangeOrders).sort((v1, v2) => {
      const n1 = v1.changeOrder ? v1.changeOrder.value.number : v1.estimate.number
      const n2 = v2.changeOrder ? v2.changeOrder.value.number : v2.estimate.number
      if (n1 < n2) return -1
      if (n2 > n1) return 1
      return 0
    })
  }

  export function getExpenseDescription(expense: Cb.ExpenseListRow | Cb.ProjectExpense) {
    return MSArray.print(expense.line_items.map((x) => x.description))
  }

  export function getDefaultRetainageMultiplier(contract: Cb.Contract) {
    return BigNumber(contract.items.find((x) => !x.archived)?.retainage_multiplier ?? 0)
  }

  export function getContractDescription(contract: Cb.Contract) {
    return MSArray.print(contract.items.map((x) => x.description))
  }

  export function getContractBalanceToFinish(summary: Cb.ContractProgressSummary) {
    return BigNumber(summary.work_remaining)
  }

  export function getContractWorkCompleted(summary: Cb.ContractProgressSummary) {
    return BigNumber(summary.work_completed)
  }

  export function getContractRetainageBalance(summary: Cb.ContractProgressSummary) {
    return BigNumber(summary.retainage_balance)
  }

  export function getContractPaidAmount(summary: Cb.ContractProgressSummary) {
    return BigNumber(summary.paid_amount)
  }

  export function getContractUnpaidAmount(summary: Cb.ContractProgressSummary) {
    // cinderblock: billing/views/contract_progress_summary.py L80
    return BigNumber(summary.unpaid_amount).minus(BigNumber(summary.in_process_amount))
  }

  export function getContractProcessingAmount(summary: Cb.ContractProgressSummary) {
    return BigNumber(summary.in_process_amount)
  }

  export function getProjectReceivableExpectedTotal(summary: Cb.ProjectProgressSummaryV2) {
    return BigNumber(summary.receivable.expected_amount)
  }

  export function getProjectReceivablePaidAmount(summary: Cb.ProjectProgressSummaryV2) {
    return BigNumber(summary.receivable.paid_amount)
  }

  export function getProjectReceivableUnpaidAmount(summary: Cb.ProjectProgressSummaryV2) {
    return BigNumber(summary.receivable.unpaid_amount).minus(
      BigNumber(summary.receivable.in_process_amount),
    )
  }

  export function getProjectReceivableWorkCompleted(summary: Cb.ProjectProgressSummaryV2) {
    return BigNumber(summary.receivable.work_completed)
  }

  export function getProjectReceivableRetainageTotal(summary: Cb.ProjectProgressSummaryV2) {
    return BigNumber(summary.receivable.retainage_balance)
  }

  export function getProjectReceivableProcessingAmount(summary: Cb.ProjectProgressSummaryV2) {
    return BigNumber(summary.receivable.in_process_amount)
  }

  export function getProjectReceivableBalanceToFinish(summary: Cb.ProjectProgressSummaryV2) {
    return BigNumber(summary.receivable.work_remaining)
  }

  export const complexOwnershipTooltipMessage = t(
    'Some suggestions for complex ownership scenarios:' +
      '\n• Multiple owners — list all owners' +
      '\n• Work done for a tenant — list the tenant and the property owners' +
      '\n• Work managed by a construction manager — list the owners, not the managers' +
      '\n• Public projects — list the government office that commissioned the work' +
      '\n• P3 projects — list the developers',
  )

  export type ChangeOrderOrEstimate =
    | { type: 'change-order'; value: Cb.ChangeOrder }
    | { type: 'estimate'; value: Cb.Estimate }

  export function mergeChangeOrdersAndEstimates(props: {
    changeOrderEstimates: Cb.Estimate[]
    changeOrders: Cb.ChangeOrder[]
  }) {
    return [
      ...props.changeOrderEstimates.map((x) => ({ value: x, type: 'estimate' as const })),
      ...props.changeOrders
        .filter((x) => !props.changeOrderEstimates.some((e) => e.created_change_order_id === x.id))
        .map((x) => ({ value: x, type: 'change-order' as const })),
    ]
  }

  export function getPendingChangeOrderTotal(props: {
    changeOrderEstimates: Cb.Estimate[]
    changeOrders: Cb.ChangeOrder[]
  }) {
    const changeOrdersOrEstimates = mergeChangeOrdersAndEstimates(props)
    return BigNumber.sum(
      Zero,
      ...changeOrdersOrEstimates
        .filter((x) =>
          x.type === 'estimate'
            ? x.value.status === 'sent'
            : x.type === 'change-order'
            ? x.value.approval_state === 'pending'
            : unreachable(x),
        )
        .map((x) =>
          x.type === 'estimate'
            ? x.value.total_amount
            : x.type === 'change-order'
            ? x.value.contract_amount_added
            : unreachable(x),
        ),
    )
  }

  export function getSubcontractNameInList(contract: Cb.Contract, index: number) {
    return contract.name || t('Subcontract #{{ X }}', { X: index + 1 })
  }

  export function contractHasMultipleRetainageValues(contract: Cb.Contract) {
    return new Set(contract.items.map((x) => x.retainage_multiplier)).size > 1
  }
}

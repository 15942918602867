import { createContext, useContext as useBaseContext, FC, ReactNode, useMemo } from 'react'
import { Modal as BaseModal } from 'antd'
import useScreenSize from 'compass/theme/useScreenSize'
import { Action } from 'utils/actions'
import Body from './components/Body'

type TCtx = {
  setInactive: () => void
}
const Ctx = createContext<TCtx | undefined>(undefined)

function useContext() {
  const ctx = useBaseContext(Ctx)
  if (!ctx) throw new Error('Invalid modal context')
  return ctx
}

type OpenerProps = {
  isActive: boolean
  setInactive: () => void
}

type Props = {
  size?: 'small' | 'large'
  blur?: boolean
}
type Children = { children: ReactNode }

function Modal({
  isActive,
  setInactive,
  size = 'small',
  blur,
  children,
}: OpenerProps & Props & Children) {
  const sz = useScreenSize()
  const ctxValue = useMemo(() => ({ setInactive }), [setInactive])

  return (
    <Ctx.Provider value={ctxValue}>
      <BaseModal
        open={isActive}
        onOk={setInactive}
        onCancel={setInactive}
        rootClassName="relative z-[10040]"
        closable={false}
        destroyOnClose
        footer={null}
        centered={sz === 'sm' ? undefined : true}
        className={size === 'small' || sz === 'sm' ? 'min-w-[320px]' : 'min-w-[720px]'}
      >
        {children}
      </BaseModal>
      {blur && (
        <style>
          {`
          .ant-modal-mask {
            background: rgba(0,0,0,0.5) !important;
            backdrop-filter: blur(5px);
          }
`}
        </style>
      )}
      {sz === 'sm' && (
        <style>
          {`
          .ant-modal, .ant-modal-content {
            height: 80vh;
            width: calc(100vw - 40px);
            margin: 10px;
            top: 0;
          }
          .ant-modal-body {
            height: calc(80vh - 40px);
          }
        `}
        </style>
      )}
    </Ctx.Provider>
  )
}

function asModal<T>(Component: FC<T>, settings?: { blur?: boolean }): FC<OpenerProps & Props & T> {
  return function Inner(props: OpenerProps & T & Props) {
    return (
      <Modal {...props} blur={settings?.blur}>
        <Component {...props} />
      </Modal>
    )
  }
}

type BodyProps = {
  title: ReactNode
  subtitle?: ReactNode
  footer?: ReactNode
  actions?: Action.Config[]
  children?: ReactNode
}

function Body2(props: BodyProps) {
  const ctx = useContext()
  return <Body {...props} setInactive={ctx.setInactive} />
}

export default { Body, Body2, asModal, Modal }

import { ReactNode, useCallback } from 'react'
import { cn } from 'msutils/classnames'
import Typography from 'compass/data/Typography'
import { t } from 'content'
import { MSError } from 'msutils'
import { switchInline } from 'utils/misc'

type Props = {
  name?: string
  background?: 'white' | 'slate' | 'none'
  message?: string
  children: ReactNode
}

export default function SectionBoundary({ background = 'none', message, name, children }: Props) {
  const Fallback = useCallback(() => {
    return (
      <div
        className={cn(
          'p-5',
          switchInline(background, { none: '', white: 'bg-white', slate: 'bg-th-bg-slate' }),
        )}
      >
        <Typography className="text-th-text-secondary">
          {message ?? t('Something went wrong')}
        </Typography>
      </div>
    )
  }, [background, message])

  return (
    <MSError.Boundary Fallback={Fallback} name={name}>
      {children}
    </MSError.Boundary>
  )
}

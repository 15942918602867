/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListPayerGeneralProjectConfigsOrdering =
  (typeof ListPayerGeneralProjectConfigsOrdering)[keyof typeof ListPayerGeneralProjectConfigsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListPayerGeneralProjectConfigsOrdering = {
  is_payee_project_creation_blocked: 'is_payee_project_creation_blocked',
  '-is_payee_project_creation_blocked': '-is_payee_project_creation_blocked',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

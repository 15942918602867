import { ReactNode, useMemo } from 'react'
import ActivateAccountBanner from 'features/onboarding/ActivateAccountBanner'
import {
  Bell,
  Calculator,
  Card,
  Chat,
  DollarBills,
  DollarEntering,
  DollarExiting,
  Gear,
  Hammer,
  Helmet,
  Profile,
  Receipt,
} from 'compass-local/legacy/icons'
import { MSForm } from 'utils/form'
import { t } from 'content'
import { BalanceUtils } from 'features/balance'
import { Module } from 'modules/routes'
import { Format, MSArray } from 'msutils'
import { Footer } from 'root/component-utils'
import { useAnalyticsContext, useSupportContext } from 'root/global'
import useFormProps from 'utils/useFormProps'
import { Cb, Q } from 'cb'
import useScreenSize from 'compass/theme/useScreenSize'
import { useCurrentRoute } from 'utils/router'
import TutorialBanner from 'features/gtm/TutorialBanner'
import { PermissionsUtils } from 'features/permissions'
import Badge from 'compass/data/Badge'
import { theme2 } from 'theme2'
import Icon from 'compass/data/Icon'
import NotificationsList from '../NotificationsList'
import { useBusinessContext } from '../AppContext'
import {
  PageMaxWidth,
  PageScroller,
  NavAction,
  WithNavigation,
  PageHeight,
} from './component-utils'

type Props = {
  children: ReactNode
}

export function BusinessUiBase({ children }: Props) {
  const { business, employee, internalAccount } = useBusinessContext()
  const { hideCosts, hideIncome, showCrm } = useAnalyticsContext().flags
  const sz = useScreenSize()
  const { chatIsOpen, setChatOpen, chatUnreadMessagesCount } = useSupportContext()
  const route = useCurrentRoute()
  const topRoute = useMemo(() => route.split('/').at(1) ?? '', [route])
  const notificationDrawerProps = useFormProps()
  const tasks = Cb.useListTasks({ refetchInterval: 30000 }).data ?? []
  const iconSize = sz === 'sm' ? 20 : 16
  const tutorial =
    Cb.useListTutorials({ params: { status: 'in_progress' }, select: Q.opt }).data ?? null

  if (employee.role !== 'cardholder') {
    return (
      <WithNavigation
        name={business.name}
        accountHref={Module('/settings/business').href}
        showAlert={MSArray.isNonEmpty(tasks)}
        mainActions={[
          showCrm &&
            NavAction(t('Deals'), {
              icon: <Icon name="bar-chart" height={iconSize} />,
              href: Module('/deals').href,
              isActive: topRoute === 'deals',
            }),
          PermissionsUtils.allowed('viewEstimates', employee.role) &&
            NavAction(t('Estimates'), {
              icon: <Calculator height={iconSize} />,
              href: Module('/estimates').href,
              isActive: topRoute === 'estimates',
            }),
          { type: 'divider' },
          NavAction(t('Projects'), {
            icon: <Hammer height={iconSize} />,
            href: Module('/projects').href,
            isActive: ['projects', 'subcontracts', 'contracts', 'change-orders'].includes(topRoute),
          }),
          PermissionsUtils.allowed('viewClients', employee.role) &&
            NavAction(t('Clients'), {
              icon: <Profile height={iconSize} />,
              href: Module('/clients').href,
              isActive: topRoute === 'clients',
            }),
          business.has_onboarding_intent &&
            !hideCosts &&
            PermissionsUtils.allowed('viewVendors', employee.role) &&
            NavAction(t('Vendors'), {
              icon: <Helmet height={iconSize} />,
              href: Module('/vendors').href,
              isActive: topRoute === 'vendors',
            }),
          { type: 'divider' },
          !hideIncome &&
            PermissionsUtils.allowed('viewInvoices', employee.role) &&
            NavAction(t('Invoices'), {
              icon: <DollarEntering height={iconSize} />,
              href: Module('/invoices').href,
              isActive: topRoute === 'invoices',
            }),
          business.has_onboarding_intent &&
            !hideCosts &&
            PermissionsUtils.allowed('viewBills', employee.role) &&
            NavAction(t('Bills'), {
              icon: <DollarExiting height={iconSize} />,
              href: Module('/bills').href,
              isActive: topRoute === 'bills',
            }),
          business.has_onboarding_intent &&
            !hideCosts &&
            PermissionsUtils.allowed('viewExpenses', employee.role) &&
            NavAction(t('Expenses'), {
              icon: <Receipt height={iconSize} />,
              href: Module('/expenses').href,
              isActive: topRoute === 'expenses',
            }),
          { type: 'divider' },
          business.has_onboarding_intent &&
            PermissionsUtils.allowed('viewExpenseCards', employee.role) &&
            NavAction(t('Expense cards'), {
              icon: <Card height={iconSize} />,
              href: Module('/expense-cards').href,
              isActive: topRoute === 'expense-cards',
            }),
          business.has_onboarding_intent &&
            PermissionsUtils.allowed('viewBeamBalance', employee.role) &&
            NavAction(t('Beam Balance'), {
              icon: <DollarBills height={iconSize} />,
              href: Module('/balance').href,
              isActive: topRoute === 'balance',
              caption:
                internalAccount &&
                Format.currency(BalanceUtils.getAccountAvailableBalance(internalAccount)),
            }),
        ]}
        secondaryActions={[
          NavAction(t('Notifications'), {
            icon: <Bell height={iconSize} />,
            alert: MSArray.isNonEmpty(tasks),
            badge: MSArray.isNonEmpty(tasks) && (
              <Badge theme={theme2.BadgeThemeLightOrange}>{tasks.length.toFixed(0)}</Badge>
            ),
            onClick: notificationDrawerProps.setActive,
            isActive: notificationDrawerProps.isActive,
          }),
          NavAction(t('Support'), {
            icon: <Chat height={iconSize} />,
            badge: (chatUnreadMessagesCount ?? 0) > 0 && (
              <Badge theme={theme2.BadgeThemeLightOrange}>
                {chatUnreadMessagesCount?.toFixed(0) ?? ''}
              </Badge>
            ),
            onClick: () => setChatOpen(!chatIsOpen),
            isActive: chatIsOpen,
          }),
          PermissionsUtils.allowed('viewSettings', employee.role) &&
            NavAction(t('Settings'), {
              icon: <Gear height={iconSize} />,
              href: Module('/settings').href,
              isActive: topRoute === 'settings',
            }),
        ]}
      >
        <MSForm.Drawer {...notificationDrawerProps} title={t('Notifications')}>
          <NotificationsList tasks={tasks} close={notificationDrawerProps.setInactive} />
        </MSForm.Drawer>
        <PageScroller>
          {business.has_onboarding_intent && <ActivateAccountBanner />}
          <PageMaxWidth>
            <div className="vflex w-full">
              <PageHeight>
                <div className="vflex grow gap-5">
                  {tutorial && <TutorialBanner tutorial={tutorial} />}
                  {children}
                </div>
              </PageHeight>
              <div className="hidden md:block">
                <Footer />
              </div>
            </div>
          </PageMaxWidth>
        </PageScroller>
      </WithNavigation>
    )
  } else {
    return (
      <div className="relative h-full vflex">
        <PageScroller>
          <PageMaxWidth>
            <div className="vflex gap-2 w-full">
              <div className="grow">{children}</div>
              <div className="hidden md:block">
                <Footer />
              </div>
            </div>
          </PageMaxWidth>
        </PageScroller>
      </div>
    )
  }
}

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListContractItemsOrdering =
  (typeof ListContractItemsOrdering)[keyof typeof ListContractItemsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListContractItemsOrdering = {
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
  number: 'number',
  '-number': '-number',
  description: 'description',
  '-description': '-description',
  original_amount: 'original_amount',
  '-original_amount': '-original_amount',
  amount_billed_externally: 'amount_billed_externally',
  '-amount_billed_externally': '-amount_billed_externally',
  retainage_multiplier: 'retainage_multiplier',
  '-retainage_multiplier': '-retainage_multiplier',
  retainage_amount: 'retainage_amount',
  '-retainage_amount': '-retainage_amount',
  approval_state: 'approval_state',
  '-approval_state': '-approval_state',
  archived: 'archived',
  '-archived': '-archived',
} as const

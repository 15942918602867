import { ReactNode } from 'react'
import { Action } from 'utils/actions'
import { BaseLayout } from 'compass/baseLayout'
import Typography from 'compass/data/Typography'
import useScreenSize from 'compass/theme/useScreenSize'
import Divider from 'compass/data/Divider'

type Props = {
  title: string
  actions?: Action.Config[]
  headerRow?: ReactNode
  children: ReactNode
}

export function NoScrollModule({ title, actions, headerRow, children }: Props) {
  const sz = useScreenSize()

  // should be gap-0, but the root layout is all borked
  return (
    <BaseLayout.VStack view={{ fillHeight: true }} gap={5}>
      {/* insetX because the root layout is all borked */}
      <BaseLayout.HStack gap={4} justify="between" view={{ insetXDONOTUSE: 5 }}>
        <Typography variant="header">{title}</Typography>
        {sz !== 'sm' &&
          Action.visibleCount(actions) > 0 &&
          actions?.map((x, i) => (
            <BaseLayout.View key={`${x.name}-${i}`}>
              <Action.Mount {...x} />
            </BaseLayout.View>
          ))}
      </BaseLayout.HStack>
      <BaseLayout.VStack view={{ fillHeight: true, background: 'white' }}>
        {headerRow && (
          <>
            {headerRow}
            <Divider />
          </>
        )}
        {children}
      </BaseLayout.VStack>
    </BaseLayout.VStack>
  )
}

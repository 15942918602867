import { ReactNode } from 'react'
import { BaseLayout } from 'compass/baseLayout'
import Typography from 'compass/data/Typography'

type KanbanColumn = {
  id: string
  title: string
  content: ReactNode
  subtitle?: string
  footer?: ReactNode
}

type Props = {
  columns: KanbanColumn[]
}

export function KanbanBoard({ columns }: Props) {
  return (
    <BaseLayout.Scroller scrollX ignoreNaturalSize>
      <BaseLayout.HStack gap={5} view={{ fillHeight: true, inset: 5 }}>
        {columns.map((col) => (
          <BaseLayout.VStack gap={5} key={col.id} view={{ fillHeight: true, fixedWidth: 312 }}>
            <BaseLayout.VStack gap={2}>
              <Typography variant="bodybold">{col.title}</Typography>
              {col.subtitle && (
                <Typography variant="label" className="text-th-text-secondary">
                  {col.subtitle}
                </Typography>
              )}
            </BaseLayout.VStack>
            <BaseLayout.Scroller scrollY>{col.content}</BaseLayout.Scroller>
          </BaseLayout.VStack>
        ))}
      </BaseLayout.HStack>
    </BaseLayout.Scroller>
  )
}

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListIncomeCodesOrdering =
  (typeof ListIncomeCodesOrdering)[keyof typeof ListIncomeCodesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListIncomeCodesOrdering = {
  name: 'name',
  '-name': '-name',
  description: 'description',
  '-description': '-description',
  unit_price: 'unit_price',
  '-unit_price': '-unit_price',
  qbo_class_source_id: 'qbo_class_source_id',
  '-qbo_class_source_id': '-qbo_class_source_id',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

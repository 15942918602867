import { useEffect, useState } from 'react'
import Typography from 'compass/data/Typography'
import LinkButtonDONOTUSE from 'compass-local/legacy/LinkButtonDONOTUSE'
import { t, Trans } from 'content'
import LoadingPage from 'components/misc/LoadingPage'
import { useSupportContext } from 'root/global'
import * as RootUtils from 'root/utils'
import { MSError, MSError2, useOnMount } from 'msutils'

type Props = {
  error: MSError.Error2
  halt?: number
}

export default function ErrorPage({ error, halt }: Props) {
  const { setChatOpen } = useSupportContext()
  const [haltState, setHaltState] = useState(!!halt)

  useOnMount(() => {
    MSError2.report(MSError2.convertError(error))
  })

  useEffect(() => {
    if (halt) setTimeout(() => setHaltState(false), halt)
  }, [halt])

  if (haltState) return <LoadingPage />
  else
    return (
      <div className="flex justify-center p-4">
        <div className="vflex max-w-[400px] gap-4">
          <Typography variant="drawerheader">{error.message}</Typography>
          <Typography>
            <Trans
              tKey="Try refreshing the page or going back. Still need help? Please <X1>contact support</X1> or <X2>log out</X2> and log back in."
              X1={<LinkButtonDONOTUSE onClick={() => setChatOpen(true)} />}
              X2={
                <LinkButtonDONOTUSE
                  onClick={() => {
                    RootUtils.setAuth(null)
                    setTimeout(() => window.location.reload(), 100)
                  }}
                />
              }
            />
          </Typography>
          <Typography className="text-th-coolgrey-1">{t('Failed to load')}</Typography>
        </div>
      </div>
    )
}

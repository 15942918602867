import { useRef, useState } from 'react'
import { ErrorIcon } from 'compass-local/legacy/icons'
import { Select } from 'antd'
import InputBase, { InputBaseUtils } from 'compass-local/InputBase'
import { SelectInputUtils } from 'compass-local/SelectInput'
import { getInputProps, InputProps } from 'compass-local/utils/InputProps'
import useHtmlId from 'compass-local/utils/useHtmlId'
import { theme } from 'styles/theme'
import { t } from 'content'

type Props = InputProps<string[]> &
  InputBaseUtils.ExternalProps & {
    placeholder?: string
  }

export default function MultiEmailInput(props: Props) {
  const { value, update, focus, blur, error, disabled, placeholder, hidden } = getInputProps(props)
  const inputRef = useRef<SelectInputUtils.SelectRef | null>(null)
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const id = useHtmlId()

  if (hidden) return null

  return (
    <InputBase
      {...props}
      cursorType="cursor-text"
      isFocused={containerRef?.contains(document.activeElement) ?? false}
      setFocus={() => inputRef.current?.focus()}
      {...(error && {
        annotation: {
          active: true,
          message: error,
          color: 'red',
          icon: <ErrorIcon height={12} />,
        },
      })}
    >
      <div id={id} ref={setContainerRef} className="-my-[7px] -mx-3">
        <Select
          mode="tags"
          open={false}
          disabled={disabled}
          value={value}
          onChange={(newValues) => update?.(newValues.map((x) => x.trim()))}
          onFocus={focus}
          onBlur={blur}
          bordered={false}
          optionLabelProp="title"
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          showAction={['focus', 'click']}
          tokenSeparators={[',', ' ']}
          ref={inputRef}
          suffixIcon={null}
          notFoundContent={null}
          placeholder={placeholder ?? t('Enter email addresses, separated by commas')}
          style={{ width: '100%', fontWeight: 500 }}
        />
        <style>{`
  .ant-select-selection-search-input {
    font-size: ${(theme?.fontSize as any)?.base?.at(0)};
  }
        `}</style>
      </div>
    </InputBase>
  )
}

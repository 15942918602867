import { Cb, Q } from 'cb'
import { EstimateItemsInputUtils } from 'components/inputs/EstimateItemsInput'
import { t } from 'content'
import { EstimateUtils } from 'features/estimates/utils'
import { F } from 'msutils'
import { unreachable } from 'msutils/misc'
import { Mutator } from 'utils/form-input'
import { EstimateInputUtils as Utils } from '../utils'
import { AdditionalDetailsPage } from './AdditionalDetailsPage'
import { AmountsPage } from './AmountsPage'
import { CoverPagePage } from './CoverPagePage'
import { DetailsPage } from './DetailsPage'
import { ReviewEmailPage } from './ReviewEmailPage'

type Props = {
  state: F.Input<typeof Utils.schema>
  estimateRef: Cb.Estimate | null
  submit: Mutator<any>
  saveDraft: Mutator<any>
  autosave: Mutator<any>
  copy: Utils.CopySpec
  context: Utils.Context
  showCoverPage?: boolean
  disableRenofiAd: boolean
  disableAddSection: boolean
  capabilities: EstimateUtils.Capability[]
  variant: 'project' | 'change-order'
}

export default Utils.pageManager.wrap((props: Props) => {
  const {
    state,
    submit,
    autosave,
    saveDraft,
    estimateRef,
    copy,
    context,
    showCoverPage = false,
    disableAddSection,
    capabilities,
    variant,
  } = props
  const { page } = Utils.pageManager.useContext()
  const calculationContext = EstimateItemsInputUtils.getCalculationContext(state.lineItems)
  Utils.usePeriodicAutosave({ autosave, state })

  const freshEstimate = Cb.useListEstimates({
    params: { id: estimateRef?.id },
    refetchInterval: 4 * 60 * 1000,
    select: estimateRef ? Q.get : Q.none,
  }).data

  const versionMismatch =
    freshEstimate && estimateRef
      ? freshEstimate.update_version > estimateRef.update_version
        ? t(
            'Someone else has made changes to this estimate, please refresh the page to view the latest version.',
          )
        : null
      : null

  switch (page.id) {
    case 'details':
      return (
        <DetailsPage
          state={state}
          calculationContext={calculationContext}
          copy={copy}
          autosave={autosave}
          globalError={versionMismatch}
          pageNumber={showCoverPage ? '1 / 5' : '1 / 4'}
        />
      )
    case 'amounts':
      return (
        <AmountsPage
          state={state}
          calculationContext={calculationContext}
          copy={copy}
          saveDraft={saveDraft}
          autosave={autosave}
          context={context}
          pageNumber={showCoverPage ? '2 / 5' : '2 / 4'}
          disableAddSection={disableAddSection}
          capabilities={capabilities}
          variant={variant}
        />
      )
    case 'additional-details':
      return (
        <AdditionalDetailsPage
          state={state}
          calculationContext={calculationContext}
          copy={copy}
          saveDraft={saveDraft}
          autosave={autosave}
          showCoverPage={showCoverPage}
          pageNumber={showCoverPage ? '3 / 5' : '3 / 4'}
          capabilities={capabilities}
        />
      )
    case 'cover-page':
      return (
        <CoverPagePage
          state={state}
          copy={copy}
          autosave={autosave}
          saveDraft={saveDraft}
          pageNumber="4 / 5"
        />
      )
    case 'review-email':
      return (
        <ReviewEmailPage
          state={state}
          calculationContext={calculationContext}
          copy={copy}
          submit={submit}
          saveDraft={saveDraft}
          autosave={autosave}
          client={page.client}
          disableRenofiAd={props.disableRenofiAd}
          context={context}
          estimateId={estimateRef?.id ?? null}
          pageNumber={showCoverPage ? '5 / 5' : '4 / 4'}
        />
      )
    default:
      return unreachable(page)
  }
})

import { Dispatch, ReactNode, SetStateAction, useMemo, useRef, useState } from 'react'
import { cn } from 'msutils/classnames'
import { compassId } from 'compass-local/root/utils'
import { F, useOnChange } from 'msutils'
import debounce from 'lodash.debounce'

export const InputClassnames =
  'text-[16px] md:text-[14px] font-medium md:font-medium leading-[19.4px] md:leading-[17px]'

type InputContainerProps = {
  placeholder?: string
  isEmpty: boolean
  containerRef?: Dispatch<SetStateAction<HTMLDivElement | null>>
  inputEl: HTMLElement | null
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onClick?: () => void
  align?: 'right' | 'left'
  minWidth?: `min-w-[${number}px]` | null
  icon?: ReactNode
  text: string
  children: ReactNode
}

export function InputContainer({
  text,
  onMouseEnter,
  onMouseLeave,
  onClick,
  containerRef,
  inputEl,
  placeholder,
  isEmpty,
  minWidth,
  align,
  icon,
  children,
}: InputContainerProps) {
  return (
    <div
      className={cn(
        compassId('input-container'),
        InputClassnames,
        'grow h-[40px] flex gap-1 cursor-text truncate',
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => {
        onClick?.()
        inputEl?.focus()
      }}
      ref={containerRef}
    >
      <div className="relative grow truncate">
        <div
          className={cn(
            compassId('input-size-setter'),
            minWidth,
            align === 'right' && 'text-right',
            'opacity-0 whitespace-pre w-full pl-3 truncate',
          )}
        >
          {text || ' '}
        </div>
        {isEmpty && placeholder && (
          <div
            className={cn(
              compassId('input-placeholder'),
              align === 'right' && 'justify-end',
              'absolute inset-0 flex items-center pl-3',
            )}
          >
            <div className="text-th-text-hint truncate w-full">{placeholder}</div>
          </div>
        )}
        <div className="absolute inset-0 pl-3">
          <div className="relative h-full w-full">{children}</div>
        </div>
      </div>
      <div className="px-1 pointer-events-none flex items-center">{icon}</div>
    </div>
  )
}

export type InputStateProps<T> = {
  value: T
  willUpdate?: (props: { newValue: T; oldValue: T }) => void
  shouldUpdate?: (props: { newValue: T; oldValue: T }) => boolean
  update?: (newValue: T) => void
  didUpdate?: (props: { newValue: T; oldValue: T }) => void
  focus?: () => void
  blur?: () => void
  debounceUpdates?: boolean
}

export type InputBaseProps = {
  title?: string
  placeholder?: string | null
  hidden?: boolean
  autofocus?: boolean
}

export type TextInputProps = {
  autoCapitalize?: boolean
  maxLength?: number
}

export function useStateProps<T>({
  value,
  willUpdate,
  shouldUpdate,
  update,
  didUpdate,
  focus,
  blur,
}: InputStateProps<T>) {
  const [innerValue, setInnerValue] = useState(value)

  const outerUpdateInLifecycle = useMemo(() => {
    return (newValue: T) => {
      const values = { newValue, oldValue: value }
      if (shouldUpdate && shouldUpdate(values) === false) {
        //
      } else {
        willUpdate?.(values)
        update?.(newValue)
        didUpdate?.(values)
      }
    }
  }, [value, willUpdate, shouldUpdate, update, didUpdate])
  const updateRef = useRef(outerUpdateInLifecycle)
  updateRef.current = outerUpdateInLifecycle
  const debouncedUpdate = useMemo(
    () => debounce((newValue: T) => updateRef.current(newValue), 500),
    [],
  )

  const updateInLifecycle = useMemo(() => {
    return (newValue: T) => {
      setInnerValue(newValue)
      debouncedUpdate(newValue)
    }
  }, [debouncedUpdate])

  const onBlur = useMemo(() => {
    return () => {
      debouncedUpdate.flush()
      setTimeout(() => blur?.(), 10)
    }
  }, [blur, debouncedUpdate])

  useOnChange([value], () => {
    if (value !== innerValue) {
      setInnerValue(value)
    }
  })

  return useMemo(
    () => ({ value: innerValue, update: updateInLifecycle, focus, blur: onBlur }),
    [innerValue, updateInLifecycle, focus, onBlur],
  )
}

export type BorderStyle = {
  width: 'sm' | 'lg'
  color: 'red' | 'grey'
} | null

export function getBorderForInput(s: F.InputCell<any>): BorderStyle {
  const isFocused = s.status === 'touched' || s.status === 'retouched' || s.status === 'editing'
  const hasUserError = s.hasErrorWithin && s.status !== 'clean'
  return {
    width: isFocused || hasUserError ? 'lg' : 'sm',
    color: hasUserError && !isFocused ? 'red' : 'grey',
  }
}

import { FC, useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { MSError } from 'msutils'
import ErrorPage from '../ErrorPage'

type ConvertErrorProps = {
  error: any
  name: string
}
function ConvertError({ error, name }: ConvertErrorProps) {
  return <ErrorPage error={new MSError.Error2('Unexpected error', { name, wrappedError: error })} />
}

export default function withBoundary<T extends object>(Component: FC<T>, name: string) {
  return function Inner(props: T) {
    const fn = useCallback(
      ({ error }: { error: any }) => <ConvertError error={error} name={name} />,
      [],
    )

    return (
      <ErrorBoundary fallbackRender={fn}>
        <Component {...props} />
      </ErrorBoundary>
    )
  }
}

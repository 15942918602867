/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListOnboardingSurveysOrdering =
  (typeof ListOnboardingSurveysOrdering)[keyof typeof ListOnboardingSurveysOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListOnboardingSurveysOrdering = {
  is_home_builder: 'is_home_builder',
  '-is_home_builder': '-is_home_builder',
  is_remodeler: 'is_remodeler',
  '-is_remodeler': '-is_remodeler',
  is_trade_contractor: 'is_trade_contractor',
  '-is_trade_contractor': '-is_trade_contractor',
  is_light_commercial: 'is_light_commercial',
  '-is_light_commercial': '-is_light_commercial',
  num_projects_completed_yearly: 'num_projects_completed_yearly',
  '-num_projects_completed_yearly': '-num_projects_completed_yearly',
  wants_invoicing: 'wants_invoicing',
  '-wants_invoicing': '-wants_invoicing',
  wants_bill_payment: 'wants_bill_payment',
  '-wants_bill_payment': '-wants_bill_payment',
  wants_estimating: 'wants_estimating',
  '-wants_estimating': '-wants_estimating',
  wants_beam_card: 'wants_beam_card',
  '-wants_beam_card': '-wants_beam_card',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

import { Cb } from 'cb'
import { t } from 'content'

export namespace ExpenseCardUtils {
  export function availableInState(state: string) {
    return !['CA', 'ND', 'SD', 'NV'].includes(state)
  }
  export function formatExpenseCard(card: Cb.ExpenseCard) {
    return t('Card ••••{{ X }}', { X: card.last_4 })
  }
}

import BigNumber from 'bignumber.js'
import Modal from 'compass-local/Modal'
import TextInput from 'compass/input/TextInput'
import { EstimateItemsInputUtils } from 'components/inputs/EstimateItemsInput'
import { t } from 'content'
import { F } from 'msutils'
import { unreachable } from 'msutils/misc'
import { Action } from 'utils/actions'

function setMarkupForLineItems(lineItems: EstimateItemsInputUtils.ItemInput, newValue: BigNumber) {
  lineItems.children.forEach((x) => {
    if (x.type.value === 'fixed-priced') {
      x.markup.update(newValue)
    } else if (x.type.value === 'group') {
      setMarkupForLineItems(x, newValue)
    } else if (x.type.value === 'percent-priced' || x.type.value === 'buffered-discount') {
      // pass
    } else {
      unreachable(x.type.value)
    }
  })
}

type Props = {
  setInactive?: () => void
  lineItems: EstimateItemsInputUtils.ItemInput
}
function SetMarkupModal({ setInactive, lineItems }: Props) {
  const markupInput = F.useInputState(F.Decimal2())

  return (
    <Modal.Body
      title={t('Set markup for all items')}
      setInactive={() => setInactive?.()}
      actions={[
        Action.button(t('Set'), {
          qualify: () => (!markupInput.value ? '' : true),
          onClick: () => {
            if (markupInput.value) {
              setMarkupForLineItems(lineItems, markupInput.value)
              setInactive?.()
            }
          },
        }),
      ]}
    >
      <TextInput
        type="percent"
        title={t('Markup')}
        align="left"
        subtitle={t('Markups in all existing items will be set to this value')}
        {...F.props(markupInput)}
      />
    </Modal.Body>
  )
}

export default Modal.asModal(SetMarkupModal)

import { Cb } from 'cb'
import { Action } from 'utils/actions'
import MultiEmailInput from 'compass-local/MultiEmailInput'
import { StaticSelectInput } from 'compass-local/SelectInput'
import TextInputDONOTUSE from 'compass-local/TextInput'
import RichAddressInput from 'components/inputs/RichAddressInput'
import { t } from 'content'
import { F } from 'msutils'
import { MSForm } from 'utils/form'
import { Mutator } from 'utils/form-input'
import { ClientInputUtils as Utils } from '../utils'

type Props = {
  state: F.Input<typeof Utils.schema>
  copy: Utils.CopySpec
  submit: Mutator<Cb.PayerContact>
}

export default function ClientInput(props: Props) {
  const { state, copy, submit } = props

  return (
    <MSForm.Body
      title={copy.title}
      warnOnClose={state._controller.hasChanged}
      error={submit.error}
      size="small"
      actions={[Action.mutation(copy.submit, { mutate: submit.mutateAsync })]}
    >
      <StaticSelectInput
        title={t('Client type')}
        options={Utils.ClientTypes}
        {...F.props(state.contactType)}
      />
      <TextInputDONOTUSE
        type="name"
        title={state.contactType.value === 'homeowner' ? t('Name') : t('Business name')}
        placeholder={
          state.contactType.value === 'homeowner' ? t('Joe Smith') : t('Sample Business Inc.')
        }
        {...F.props(state.name)}
      />
      <TextInputDONOTUSE
        type="email"
        placeholder={
          state.contactType.value === 'homeowner'
            ? t('joe.smith@gmail.com')
            : t('joe@business-name.com')
        }
        {...F.props(state.email)}
        optional
      />
      <MultiEmailInput {...F.props(state.ccEmails)} title={t('Additional emails')} optional />
      <RichAddressInput
        state={state.address}
        title={
          state.contactType.value === 'homeowner' ? t('Personal address') : t('Business address')
        }
        optional
      />
      <TextInputDONOTUSE {...F.props(state.phone)} type="phone" optional />
      {state.contactType.value !== 'homeowner' && (
        <TextInputDONOTUSE
          {...F.props(state.ein)}
          type="ein"
          optional
          placeholder={t('Enter EIN')}
        />
      )}
    </MSForm.Body>
  )
}

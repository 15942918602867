/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListCostCodesOrdering =
  (typeof ListCostCodesOrdering)[keyof typeof ListCostCodesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListCostCodesOrdering = {
  name: 'name',
  '-name': '-name',
  description: 'description',
  '-description': '-description',
  expense_supported: 'expense_supported',
  '-expense_supported': '-expense_supported',
  income_supported: 'income_supported',
  '-income_supported': '-income_supported',
  income_or_expense_supported: 'income_or_expense_supported',
  '-income_or_expense_supported': '-income_or_expense_supported',
  item_name: 'item_name',
  '-item_name': '-item_name',
  ledger_account_name: 'ledger_account_name',
  '-ledger_account_name': '-ledger_account_name',
  is_v2: 'is_v2',
  '-is_v2': '-is_v2',
  v2_is_linked_to_qb: 'v2_is_linked_to_qb',
  '-v2_is_linked_to_qb': '-v2_is_linked_to_qb',
  unit_price: 'unit_price',
  '-unit_price': '-unit_price',
  unit_cost: 'unit_cost',
  '-unit_cost': '-unit_cost',
  hidden_for_migration: 'hidden_for_migration',
  '-hidden_for_migration': '-hidden_for_migration',
  is_archived: 'is_archived',
  '-is_archived': '-is_archived',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

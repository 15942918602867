import { Cell, CellField } from './base'
import { Branded, FieldError } from './utils'

type NonemptyString = Branded<string, 'nonempty'>
type BlankableString = Branded<string, 'blankable'>

export type Text<V> = CellField<string, V>

type TextProps<TReq> = {
  required?: TReq
  initValue?: string
  treatEmptyAs?: string
  errorKey?: string
  minLength?: number
  maxLength?: number
}

export function Text<TReq extends boolean = false>(
  props?: TextProps<TReq>,
): Text<TReq extends true ? NonemptyString : BlankableString> {
  const validate: any = (x: string) => {
    if (props?.required && !x.trim()) {
      throw new FieldError('Required')
    }
    if (props?.minLength !== undefined && x.length < props?.minLength) {
      throw new FieldError(`Must be at least ${props.minLength} characters`)
    }
    if (props?.maxLength !== undefined && x.length > props?.maxLength) {
      throw new FieldError(`Must be at most ${props.maxLength} characters`)
    }
    return x || props?.treatEmptyAs || ''
  }
  return Cell({
    initValue: props?.initValue ?? '',
    validate,
    errorKey: props?.errorKey,
  })
}

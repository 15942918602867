import { Cb, Q } from 'cb'
import { getInputProps, InputProps } from 'compass-local/utils/InputProps'
import { t } from 'content'
import SelectInput from 'compass-local/SelectInput'
import CreateAdditionalContactForm from 'components/forms/CreateAdditionalContactForm'
import useFormProps from 'utils/useFormProps'

export type ContactEmail = {
  email: string
  name: string
  id: string
}

type Props = InputProps<ContactEmail | null> & {
  client: Cb.PayerContact
}

// Once we have a use case for it, we can make this support payee emails too
export default function ContactEmailSelect(props: Props) {
  const { value, update, focus, blur, client } = getInputProps({ ...props })
  const createAdditionalContactFormProps = useFormProps()
  const q = Q.group({
    additionalContacts: Cb.useListAdditionalContacts({
      params: { payer_contact_id: client.id },
      select: Q.filter((x) => x.email !== null),
    }),
  })
  const options =
    q.status === 'success'
      ? [{ email: client.email, name: '', id: client.id }].concat(
          q.queries.additionalContacts.flatMap((x) =>
            x.email ? { id: x.id, name: x.name, email: x.email } : [],
          ),
        )
      : null

  return (
    <>
      <CreateAdditionalContactForm
        {...createAdditionalContactFormProps}
        onSuccess={(newValue) => update?.(newValue)}
        client={props.client}
        vendor={null}
      />
      <SelectInput
        value={value}
        update={update}
        focus={focus}
        blur={blur}
        onCreate={() => {
          createAdditionalContactFormProps.setActive()
        }}
        title={t('To')}
        placeholder={t('Select recipient...')}
        isLoading={q.status === 'loading'}
        createNewMessage={t('Add additional contact')}
        getId={(x) => x.id}
        getTitle={(x) => x.email}
        getOptionProps={(c) => {
          return {
            value: c,
            label: c.name,
          }
        }}
        options={options ?? []}
      />
    </>
  )
}

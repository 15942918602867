import { ReactNode, useState } from 'react'
import { cn } from 'msutils/classnames'
import { Chevron } from 'compass-local/legacy/icons'
import { unreachable } from 'msutils/misc'
import useScreenSize from 'compass/theme/useScreenSize'
import { xor } from 'msutils'
import { useFormContext } from './openerContext'

type Props = {
  rightContent?: ReactNode
  disableRightContentExpand?: boolean
  flipRightAndLeft?: boolean
  disableEqualWidth?: boolean
  scrollable?: boolean
  children: ReactNode
}

function DrawerPanels({
  disableEqualWidth,
  rightContent,
  flipRightAndLeft,
  scrollable,
  children,
}: Props) {
  return (
    <div
      className={cn(
        'gap-8 h-full',
        disableEqualWidth ? 'flex' : 'flex-equal',
        flipRightAndLeft && 'flex-row-reverse',
        scrollable && 'overflow-auto',
      )}
    >
      <div className="vflex gap-5 max-w-full grow">{children}</div>
      {rightContent}
    </div>
  )
}

const knobWidth = 32
function ModalPanels({
  rightContent,
  disableRightContentExpand,
  flipRightAndLeft,
  children,
}: Props) {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const { splitRatio, setSplitRatio } = useFormContext()
  const actualSplitRatio = disableRightContentExpand ? 1 : splitRatio
  const rightPanelVisible = actualSplitRatio < 0.9
  const sz = useScreenSize()

  return (
    <div
      className="grow basis-0 flex h-full relative overflow-hidden bg-th-bg-slate"
      ref={setContainerRef}
    >
      <style>{`
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
      `}</style>
      {containerRef && (
        <>
          <div
            className={cn(
              'absolute top-[12px] border rounded-full bg-th-bg-white shadow-lg flex justify-center items-center cursor-pointer z-10',
              disableRightContentExpand && 'hidden',
            )}
            style={{
              left: flipRightAndLeft ? undefined : containerRef.clientWidth * actualSplitRatio - 16,
              right: flipRightAndLeft
                ? containerRef.clientWidth * actualSplitRatio - 16
                : undefined,
              height: knobWidth,
              width: knobWidth,
            }}
            onClick={() => {
              if (!disableRightContentExpand) {
                if (rightPanelVisible) setSplitRatio(0.97)
                else if (sz === 'md') setSplitRatio(0.03)
                else setSplitRatio(0.6)
              }
            }}
          >
            <Chevron
              thickness={2.6}
              height={16}
              className={cn(
                'text-th-coolgrey-1 transition-all duration-100',
                xor(!rightPanelVisible, !!flipRightAndLeft) && 'rotate-180',
              )}
            />
          </div>
          {flipRightAndLeft ? (
            <>
              <div
                style={{ width: containerRef.clientWidth * (1 - actualSplitRatio) }}
                className="h-full max-h-full overflow-auto vflex justify-center max-w-[900px] m-auto"
              >
                {rightPanelVisible && (
                  <div className="relative h-full overflow-auto">{rightContent}</div>
                )}
              </div>
              <div
                style={{ width: containerRef.clientWidth * actualSplitRatio }}
                className="h-full max-h-full overflow-auto border-x border-th-warmgrey-2 bg-th-bg-white shadow-[-2px_0_4px_0px_rgba(128,128,128,0.2)]"
              >
                <div
                  className={cn(
                    'm-auto min-h-full px-8 py-5 vflex gap-5',
                    disableRightContentExpand ? 'max-w-[640px]' : 'max-w-[1280px]',
                  )}
                >
                  {children}
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{ width: containerRef.clientWidth * actualSplitRatio }}
                className="h-full max-h-full overflow-auto border-r border-th-warmgrey-2 bg-th-bg-white shadow-[2px_0_4px_0px_rgba(128,128,128,0.2)]"
              >
                <div
                  className={cn(
                    'm-auto min-h-full px-8 py-5 vflex gap-5',
                    disableRightContentExpand ? 'max-w-[640px]' : 'max-w-[1280px]',
                  )}
                >
                  {children}
                </div>
              </div>
              <div
                style={{ width: containerRef.clientWidth * (1 - actualSplitRatio) }}
                className="h-full max-h-full overflow-auto vflex justify-center max-w-[900px] m-auto"
              >
                {rightPanelVisible && (
                  <div className="relative h-full overflow-auto">{rightContent}</div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export function Panels(props: Props) {
  const { ui } = useFormContext()

  switch (ui) {
    case 'modal':
      return <ModalPanels {...props} />
    case 'drawer':
      return <DrawerPanels {...props} />
    default:
      return unreachable(ui)
  }
}

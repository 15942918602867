import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { MSError } from 'msutils'
import { MSObject, ToSnake } from 'msutils/object'

// Settings

export function settings<T, S>(
  query: UseQueryOptions<T, unknown, S>,
): { query: UseQueryOptions<T, unknown, S> } {
  return { query }
}

// for some reason, this doesn't work unless tsconfig has
// "moduleResolution": "node10", but setting that breaks
// JSON files
export function snake<T extends object>(obj: T): ToSnake<T> {
  return MSObject.toSnake(obj)
}

// Extractors

export type Paginated<T> = {
  results: T[]
}

export function identity<T>(data: T): T {
  return data
}

export function paginated<T extends Paginated<any>>(data: T): T {
  return data
}

export function all<T>(data: Paginated<T>): T[] {
  return data.results
}

export function opt<T>(data: Paginated<T>): T | null {
  return data.results.at(0) ?? null
}

export function get<T>(data: Paginated<T>): T {
  if (data.results.length > 1) {
    throw new MSError.Error2('Expected exactly one element, got multiple')
  }
  const d = data.results.at(0)
  if (d === undefined) {
    throw new MSError.Error2('Expected exactly one element, got none')
  }
  return d
}

export function first<T>(data: Paginated<T>): T {
  const f = data.results.at(0)
  if (f === undefined) {
    throw new MSError.Error2('Expected an element in list')
  } else {
    return f
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function none<T>(_data: Paginated<T>): null {
  return null
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function empty<T>(_data: Paginated<T>): [] {
  return []
}

export function notRequired<T>(data: T | undefined): T | null {
  return data ?? null
}

export function required<T>(data: T | undefined): T {
  if (!data) throw new MSError.Error2('q-required')
  return data
}

export function filter<T>(fn: (_: T) => boolean): (data: Paginated<T>) => T[] {
  return (data: Paginated<T>) => data.results.filter(fn)
}

export function flatMap<T, S>(fn: (_: T) => [S] | []): (data: Paginated<T>) => S[] {
  return (data: Paginated<T>) => data.results.flatMap(fn)
}

type QueryGroup<R extends object> = {
  [K in keyof R]: UseQueryResult<R[K], any>
}

type Metadata<R extends object> = {
  isRefetching: boolean
  _queries: QueryGroup<R>
}

export type Queryset<R extends object> = Metadata<R> &
  (
    | {
        status: 'error'
        queries: null
      }
    | {
        status: 'loading'
        queries: null
      }
    | {
        status: 'success'
        queries: R
      }
  )

export function group<R extends object>(g: QueryGroup<R>): Queryset<R> {
  const values = Object.values<QueryGroup<R>[keyof R]>(g)

  const metadata = { isRefetching: values.some((v) => v.isRefetching), _queries: g }

  if (values.some((q) => !!q.error)) {
    return { ...metadata, status: 'error', queries: null }
  } else if (values.some((q) => q.isLoading)) {
    return { ...metadata, status: 'loading', queries: null }
  } else if (values.every((q) => q.status === 'success')) {
    return { ...metadata, status: 'success', queries: MSObject.map(g, (q) => q.data as any) }
  } else {
    return { ...metadata, status: 'error', queries: null }
  }
}

export const RefetchIntervalShort = 60 * 1000

export const NullUuid = '00000000-0000-0000-0000-000000000000'

import Head from 'next/head'
import { theme } from 'styles/theme'

export default function GlobalBrowserMetadata() {
  return (
    <Head>
      {/* eslint-disable-next-line mosaic-js/no-raw-text-jsx */}
      <title>Beam</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <meta name="theme-color" content={(theme?.colors as any)?.th?.bg?.['beam-black']} />
    </Head>
  )
}

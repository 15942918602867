import { ReactNode, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { Format, MSDate, Zero } from 'msutils'
import { Collapsable, MSArray } from 'msutils/array'
import { useControlledOrUncontrolled } from 'utils/misc'
import { useFrozen } from 'utils/useFrozen'
import { CompassTypes } from 'compass-local'
import { MenuOption } from 'compass-local/Menu'
import { unreachable } from 'msutils/misc'
import {
  CellMetaCallback,
  ColumnDef,
  RowMetaCallback,
  ExternallyUsableColumnProps,
  Row,
  TableElement,
} from './internal-utils'

export namespace TableUtils {
  export function colSpec<TData, TCtx = undefined>() {
    type Accessor = keyof TData | CellMetaCallback<any, TData, TCtx>
    type ConstrainedAccessor<TConstraint> =
      | (keyof TData &
          keyof { [K in keyof TData as TData[K] extends TConstraint ? K : never]: any })
      | CellMetaCallback<TConstraint, TData, TCtx>

    type DataType<TAccessor extends Accessor> = TAccessor extends keyof TData
      ? TData[TAccessor]
      : TAccessor extends CellMetaCallback<infer X, TData, TCtx>
      ? X
      : never

    type BaseProps<TAccessor extends Accessor> = ExternallyUsableColumnProps<
      DataType<TAccessor>,
      TData,
      TCtx
    > & {
      accessor: TAccessor
    }

    type PropsWithConstraint<
      TConstraint,
      TAccessor extends ConstrainedAccessor<TConstraint>,
    > = BaseProps<TAccessor>

    function base<TAccessor extends Accessor>(
      header: string,
      { accessor, ...props }: BaseProps<TAccessor>,
    ): ColumnDef<DataType<TAccessor>, TData, TCtx> {
      return {
        header,
        accessor:
          typeof accessor === 'function' ? accessor : ({ row }) => row[accessor as keyof TData],
        ...props,
      }
    }

    type TextProps<TAccessor extends Accessor> = BaseProps<TAccessor>

    function any<TAccessor extends Accessor>(header: string, props: TextProps<TAccessor>) {
      return base(header, props)
    }

    function status<TAccessor extends Accessor>(header: string, props: TextProps<TAccessor>) {
      return base(header, { position: 'status', ...props })
    }

    function badge<TAccessor extends Accessor>(header: string, props: TextProps<TAccessor>) {
      return base(header, { position: 'badge', align: 'right', ...props })
    }

    function menu<TAccessor extends Accessor>(props: TextProps<TAccessor>) {
      return badge('', props)
    }

    type TitleProps<
      TitleAccessor extends ConstrainedAccessor<string>,
      SubtitleAccessor extends ConstrainedAccessor<string | null>,
    > = ExternallyUsableColumnProps<any, TData, TCtx> & {
      title: TitleAccessor
      subtitle: SubtitleAccessor
      href?: CellMetaCallback<CompassTypes['href'] | null, TData, TCtx>
      status?: CellMetaCallback<ReactNode, TData, TCtx>
      icon?: CellMetaCallback<ReactNode, TData, TCtx>
      secondary?: boolean
    }

    function title<
      TitleAccessor extends ConstrainedAccessor<string>,
      SubtitleAccessor extends ConstrainedAccessor<string | null>,
    >(header: string, props: TitleProps<TitleAccessor, SubtitleAccessor>) {
      return base(header, {
        position: 'title',
        accessor: (x, ctx) => {
          const _title =
            typeof props.title === 'function'
              ? props.title(x, ctx)
              : (x.row[props.title as keyof TData] as string)
          const subtitle =
            typeof props.subtitle === 'function'
              ? props.subtitle(x, ctx)
              : (x.row[props.subtitle as keyof TData] as string | null)

          return (
            <TableElement
              title={_title}
              subtitle={subtitle}
              secondary={props.secondary}
              status={props.status?.(x, ctx)}
              icon={props.icon?.(x, ctx)}
              href={props.href?.(x, ctx)}
              position={props.position === 'row' ? 'row' : 'title'}
            />
          )
        },
        ...props,
      })
    }

    type DecimalType = BigNumber | string | null
    function currency<TAccessor extends ConstrainedAccessor<DecimalType>>(
      header: string,
      props: PropsWithConstraint<DecimalType, TAccessor> & { style?: 'default' | 'ledger' },
    ) {
      const style = props.style ?? 'default'
      const format: any =
        style === 'ledger'
          ? (x: any) => {
              const amount = BigNumber(x)
              if (amount.lt(Zero)) {
                return Format.currency(amount)
              } else {
                return (
                  <div className="text-th-green-success">
                    {Format.currency(amount, { sign: 'positive-and-negative' })}
                  </div>
                )
              }
            }
          : style === 'default'
          ? Format.currency
          : unreachable(style)
      return base(header, { align: 'right', format, ...props })
    }

    function decimal<TAccessor extends ConstrainedAccessor<DecimalType>>(
      header: string,
      props: PropsWithConstraint<DecimalType, TAccessor>,
    ) {
      return base(header, { align: 'right', format: Format.decimal as any, ...props })
    }

    function percent<TAccessor extends ConstrainedAccessor<DecimalType>>(
      header: string,
      props: PropsWithConstraint<DecimalType, TAccessor>,
    ) {
      return base(header, { align: 'right', format: Format.percent as any, ...props })
    }

    type DateType = MSDate | null
    function date<TAccessor extends ConstrainedAccessor<DateType>>(
      header: string,
      props: PropsWithConstraint<DateType, TAccessor>,
    ) {
      return base(header, {
        align: 'right',
        whitespace: 'nowrap',
        format: (x) => (x as any)?.format() ?? '--',
        ...props,
      })
    }

    return Object.assign(any, {
      title,
      badge,
      status,
      menu,
      currency,
      decimal,
      percent,
      date,
    })
  }

  function flatten<TData, TCtx>(
    data: TData[],
    props: {
      subrows?: RowMetaCallback<TData[] | undefined, TData, TCtx>
      depth: number
      path: number[]
      parent: { data: TData; index: number } | null
      getId: (value: TData, index: number) => string
      ctx: TCtx
    },
  ): Row<TData>[] {
    const { subrows: getSubrows, depth, getId, path, parent, ctx } = props
    return data.reduce((p, c) => {
      const index = (parent?.index ?? -1) + p.length + 1
      const newPath = [...path, index]
      const id = getId(c, index)
      const subrows = getSubrows?.(c, { id, ctx, index, depth, parent: parent?.data ?? null }) ?? []
      return [
        ...p,
        { data: c, depth, parent, hasChildren: MSArray.isNonEmpty(subrows), path: newPath },
        ...flatten(subrows, {
          depth: depth + 1,
          subrows: getSubrows,
          getId,
          ctx,
          path: newPath,
          parent: { data: c, index },
        }),
      ]
    }, [] as Row<TData>[])
  }

  export type ColumnState = {
    isActive: (idOrIndex: string) => boolean
    set: (idOrIndex: string, value: boolean) => void
    ids: { [key: string]: boolean }
  }

  export function useColumnState(props?: {
    initValues?: { [key: string]: boolean }
    defaultValue?: boolean
  }): ColumnState {
    const [state, setState] = useState<{ [key: string]: boolean }>(props?.initValues ?? {})
    return useMemo(
      () => ({
        isActive: (idOrIndex: string) =>
          idOrIndex in state ? state[idOrIndex] : props?.defaultValue ?? false,
        // eslint-disable-next-line mosaic-js/no-unnamed-boolean-arg
        set: (idOrIndex: string, value: boolean) => setState((x) => ({ ...x, [idOrIndex]: value })),
        ids: state,
      }),
      [state, props?.defaultValue],
    )
  }

  // eslint-disable-next-line mosaic-js/no-unnamed-boolean-arg
  export function setAllColumnState(state: ColumnState, value: boolean) {
    Object.keys(state.ids).map((x) => state.set(x, value))
  }

  export function buildControlledColumnState(props: {
    values: { [key: string]: boolean }
    // eslint-disable-next-line mosaic-js/no-unnamed-boolean-arg
    set: (idOrIndex: string, newValue: boolean) => void
  }): ColumnState {
    return {
      isActive: (idOrIndex: string) =>
        idOrIndex in props.values ? props.values[idOrIndex] : false,
      set: props.set,
      ids: props.values,
    }
  }

  type RowMeta<TData> = {
    row: TData
    parent: TData | null
    index: number
    depth: number
    id: string
  }

  type UseTableSpecReturn<TData, TCtx> = {
    rows: Row<TData>[]
    columns: ColumnDef<any, TData, TCtx>[]
    ctx: TCtx
    state: {
      expanded: ColumnState & { rows: TData[] }
      selected: ColumnState & { rows: TData[] }
    }
    getId: (value: TData, i: number) => string
    disableCollapse: boolean
    allowSelect: boolean
    rowActions?: RowMetaCallback<Collapsable<MenuOption[]>, TData, TCtx>
    dragId?: RowMetaCallback<string, TData, TCtx>
    dropIds?: RowMetaCallback<string[], TData, TCtx>
    handleMove?: (fromRow: RowMeta<TData>, toRow: RowMeta<TData>) => void
    isInactive?: RowMetaCallback<boolean, TData, TCtx>
    setActive?: RowMetaCallback<void, TData, TCtx>
    href?: RowMetaCallback<CompassTypes['href'] | undefined, TData, TCtx>
    rowHeader?: RowMetaCallback<
      { component: ReactNode; showWhenInactive?: boolean } | undefined,
      TData,
      TCtx
    >
    rowFooter?: RowMetaCallback<
      { component: ReactNode; showWhenInactive?: boolean } | undefined,
      TData,
      TCtx
    >
    footer?: (ctx: TCtx) => { component: ReactNode } | undefined
    getSelectDisabledReason?: CellMetaCallback<string | null, TData, TCtx>
    disableSelectAll?: boolean
    hidden?: RowMetaCallback<boolean, TData, TCtx>
  }

  type UseTableSpecProps<TData, TCtx> = {
    data: TData[]
    columns: Collapsable<ColumnDef<any, TData, TCtx>[]>
    state?: {
      expanded?: ColumnState
      selected?: ColumnState
    }
    initValues?: {
      expanded?: boolean
    }
    defaultValues?: {
      expanded?: boolean
    }
    disableCollapse?: boolean
    allowSelect?: boolean
    getSelectDisabledReason?: CellMetaCallback<string | null, TData, TCtx>
    disableSelectAll?: boolean
    hidden?: RowMetaCallback<boolean, TData, TCtx>
    /** TODO: Only works in desktop */
    rowActions?: RowMetaCallback<Collapsable<MenuOption[]>, TData, TCtx>
    href?: RowMetaCallback<CompassTypes['href'] | undefined, TData, TCtx>
    dragId?: RowMetaCallback<string, TData, TCtx>
    dropIds?: RowMetaCallback<string[], TData, TCtx>
    handleMove?: (fromRow: RowMeta<TData>, toRow: RowMeta<TData>) => void
    isInactive?: RowMetaCallback<boolean, TData, TCtx>
    setActive?: RowMetaCallback<void, TData, TCtx>
    subrows?: RowMetaCallback<TData[] | undefined, TData, TCtx>
    rowHeader?: RowMetaCallback<
      { component: ReactNode; showWhenInactive?: boolean } | undefined,
      TData,
      TCtx
    >
    rowFooter?: RowMetaCallback<
      { component: ReactNode; showWhenInactive?: boolean } | undefined,
      TData,
      TCtx
    >
    footer?: (ctx: TCtx) => { component: ReactNode } | undefined
    getId?: (x: TData, i: number) => string
  } & (undefined extends TCtx ? { ctx?: undefined } : { ctx: TCtx })

  export function useSpec<TData, TCtx = undefined>({
    data,
    ctx,
    state,
    href,
    getId = (_, i) => `${i}`,
    rowActions,
    disableCollapse,
    defaultValues,
    allowSelect,
    getSelectDisabledReason,
    disableSelectAll = false,
    hidden,
    dragId,
    dropIds,
    handleMove,
    footer,
    rowFooter,
    rowHeader,
    isInactive,
    setActive,
    ...props
  }: UseTableSpecProps<TData, TCtx>): UseTableSpecReturn<TData, TCtx> {
    const subrows = useFrozen(() => props.subrows)
    const rows = useMemo(
      () => flatten(data, { subrows, depth: 0, parent: null, getId, ctx: ctx as TCtx, path: [] }),
      [data, subrows, getId, ctx],
    )
    const columns = useMemo(() => MSArray.collapse(props.columns), [props.columns])

    const expandedState = useControlledOrUncontrolled(
      state?.expanded,
      useColumnState({
        initValues:
          props.initValues?.expanded || disableCollapse
            ? Object.fromEntries(rows.map((x, i) => [getId(x.data, i), true]))
            : undefined,
        defaultValue: defaultValues?.expanded,
      }),
    )

    const selectedState = useControlledOrUncontrolled(state?.selected, useColumnState())

    return {
      rows,
      columns,
      ctx: ctx as TCtx,
      state: {
        expanded: {
          ...expandedState,
          rows: data.filter((x, i) => expandedState.ids[getId(x, i)] ?? false),
        },
        selected: {
          ...selectedState,
          rows: data.filter((x, i) => selectedState.ids[getId(x, i)] ?? false),
        },
      },
      allowSelect: allowSelect ?? false,
      disableCollapse: disableCollapse ?? false,
      rowActions,
      getSelectDisabledReason,
      disableSelectAll,
      hidden,
      dragId,
      dropIds,
      handleMove,
      getId,
      footer,
      rowFooter,
      rowHeader,
      isInactive,
      setActive,
      href,
    }
  }
}

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListBudgetConfigsOrdering =
  (typeof ListBudgetConfigsOrdering)[keyof typeof ListBudgetConfigsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListBudgetConfigsOrdering = {
  default_markup_multiplier: 'default_markup_multiplier',
  '-default_markup_multiplier': '-default_markup_multiplier',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

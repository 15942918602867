export namespace TypographyUtils {
  export const Styles = {
    header: 'text-[20px] md:text-[32px] font-black leading-[24.2px] md:leading-[38.7px]',
    drawerheader: 'text-[20px] leading-[24.2px] font-black md:text-[24px] md:leading-[29.1px]',
    title:
      'text-[18px] md:text-[16px] font-extrabold md:font-black leading-[21.8px] md:leading-[19.4px]',
    subtitle:
      'text-[18px] md:text-[16px] font-semibold md:font-semibold leading-[21.8px] md:leading-[19.4px]',
    bodybold:
      'text-[16px] md:text-[14px] font-bold md:font-bold leading-[19.4px] md:leading-[17px]',
    body: 'text-[16px] md:text-[14px] font-medium md:font-medium leading-[19.4px] md:leading-[17px]',
    section:
      'text-[14px] md:text-[12px] font-bold md:font-bold leading-[16.9px] md:leading-[14.5px] tracking-[0.03em]',
    tabletext: 'text-[14px] font-semibold', // only mobile ?
    label:
      'text-[14px] md:text-[12px] font-medium md:font-medium leading-[16.9px] md:leading-[14.5px]',
    captionbold:
      'text-[12px] font-bold leading-[12px] md:text-[10px] md:font-bold md:leading-[12px]',
    caption:
      'text-[12px] md:text-[10px] font-medium md:font-normal leading-[14.5px] md:leading-[12px]',
    pill: 'text-[10px] md:text-[10px] font-bold md:font-bold leading-[10px] tracking-[0.05em] uppercase',
    smalltext: 'text-[8px] font-normal', // only mobile ?
    signature: 'font-cursive text-[16px]',
    'preview/title': 'text-[20px] font-extrabold md:font-black leading-[24.2px]',
  }

  export type Variant = keyof typeof Styles
}

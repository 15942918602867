import { useCallback, useEffect, useState } from 'react'
import { Cb, config } from 'cb'
import SelectInput from 'compass-local/SelectInput'
import { includesIgnoreCase } from 'utils/string'
import { Auth, getAuth, setAuth, setDeepLink } from 'root/utils'
import Button from 'compass/data/Button'
import { theme2 } from 'theme2'
import { MSArray } from 'msutils'

type AuthConf = {
  name: string
  token: string
}

function readAuths() {
  const authOpt = window.localStorage.getItem('__DEVTOOLS_AUTH__')
  return authOpt ? (JSON.parse(authOpt) as AuthConf[]) : null
}

function appendAuth(value: AuthConf) {
  const auths = readAuths()
  const newAuths = [...(auths ?? []), value]
  window.localStorage.setItem('__DEVTOOLS_AUTH__', JSON.stringify(newAuths))
}

function removeAuth(key: string) {
  const auths = readAuths()
  const newAuths = auths?.filter((x) => x.token !== key)
  setAuth(null)
  window.localStorage.setItem('__DEVTOOLS_AUTH__', JSON.stringify(newAuths ?? []))
}

export default function AuthManager() {
  const invalidateAll = useCallback((path?: string) => {
    setTimeout(() => {
      if (path) window.location.pathname = path
      else window.location.reload()
    }, 200)
  }, [])

  const auths = readAuths()
  const [actualAuth, setActualAuth] = useState<Auth | null>(getAuth())

  const authsMissingActualAuth = !!actualAuth && !auths?.some((x) => x.token === actualAuth.header)

  useEffect(() => {
    if (authsMissingActualAuth) {
      const actualAuth2 = getAuth()
      if (actualAuth2) {
        Cb.listUsers(undefined, config(actualAuth2.header)).then(({ results }) => {
          const email = results.at(0)?.email
          appendAuth({
            name: email?.slice(0, email.indexOf('@')) ?? 'current',
            token: actualAuth2.header,
          })
        })
      }
    }
  }, [authsMissingActualAuth, invalidateAll])

  const value = actualAuth?.header
    ? {
        token: actualAuth.header,
        name: auths?.find((x) => x.token === actualAuth.header)?.name ?? 'current',
      }
    : null

  if (auths && MSArray.isNonEmpty(auths)) {
    return (
      <div className="w-fit">
        <SelectInput
          title={
            <div className="flex gap-3 items-center">
              Current user
              {value && (
                <div className="w-fit">
                  <Button
                    theme={theme2.ButtonThemeTextOrange}
                    onClick={() => {
                      removeAuth(value.token)
                      invalidateAll()
                    }}
                  >
                    Log out & remove
                  </Button>
                </div>
              )}
            </div>
          }
          value={value}
          options={auths}
          getId={(x) => x.token}
          getTitle={(x) => x.name}
          getOptionProps={(x) => ({ value: x })}
          update={(newAuth) => {
            setDeepLink(null)
            if (newAuth) {
              setAuth({ header: newAuth.token }, 'local')
              setActualAuth({ type: 'default', header: newAuth.token })
            } else {
              setAuth(null)
            }
            invalidateAll('/')
          }}
          filter={(search, x) => includesIgnoreCase(x.name, search)}
        />
      </div>
    )
  } else {
    return null
  }
}

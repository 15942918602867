import { ReactNode } from 'react'
import Tooltip from '../../layout/Tooltip'

type Props = {
  onClick?: () => void
  onFocus?: () => void
  onBlur?: () => void
  disabled?: boolean | string
  children: ReactNode
}

export default function InteractionTarget({ onClick, onFocus, onBlur, disabled, children }: Props) {
  const disabledMessage = disabled && typeof disabled === 'string' ? disabled : null

  return (
    <Tooltip message={disabledMessage} inactive={!disabledMessage}>
      <div
        onClick={disabled ? undefined : onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        style={{
          cursor: onClick ? (disabled ? 'not-allowed' : 'pointer') : undefined,
        }}
      >
        {children}
      </div>
    </Tooltip>
  )
}

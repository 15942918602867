import { t, Trans } from 'content'
import Card from 'compass/layout/Card'
import Typography from 'compass/data/Typography'
import Alert from 'compass-local/Alert'
import Modal from 'compass-local/Modal'
import { F } from 'msutils'
import { Cb } from 'cb'
import { useFormState } from 'utils/form-input'
import { Info } from 'compass-local/legacy/icons'
import { Action } from 'utils/actions'
import { LinkEXPERIMENTAL } from 'components/misc/LinkEXPERIMENTAL'
import { FileUtils } from 'components/misc/FileUtils'
import { unhandled } from 'msutils/misc'

type Props = {
  setInactive?: () => void
  onSuccess: (x: Cb.GetEstimateInputFromFileResult) => void
}
function ImportEstimateFromXactRemodelForm({ setInactive, onSuccess }: Props) {
  const { state, useValidatedAction } = useFormState(
    F.Group({
      spec: {
        file: FileUtils.input({ required: true }),
      },
    }),
  )
  const getEstimateInputFromFile = Cb.useGetEstimateInputFromFileHook()
  const getEstimateInputFromFileAction = useValidatedAction(
    (validData) => getEstimateInputFromFile({ file_id: validData.file[0].fileId }),
    {
      disableSetInactiveOnSuccess: true,
      onSuccess: (v) => {
        onSuccess(v)
        setInactive?.()
      },
    },
  )

  return (
    <Modal.Body
      title={t('Import from XactRemodel')}
      setInactive={() => setInactive?.()}
      actions={[
        Action.mutation(t('Import'), { mutate: getEstimateInputFromFileAction.mutateAsync }),
      ]}
    >
      {getEstimateInputFromFileAction.error?.message && (
        <Alert>{getEstimateInputFromFileAction.error.message}</Alert>
      )}
      <Card className="vflex gap-3">
        <div className="flex gap-1 items-center">
          <Info width={16} className="text-th-coolgrey-1" />
          <Typography variant="bodybold">{t('Instructions')}</Typography>
        </div>
        <div className="vflex gap-1">
          <Typography variant="label">
            <Trans
              tKey="1. <X>Sign in</X> to your XactRemodel account."
              X={
                <LinkEXPERIMENTAL.External href="https://app.xactremodel.com/?utm_source=xactremodel-lp2&utm_medium=referral&utm_campaign=pes-xwmodel-xactremodel-2023-08-01&utm_content=sign_in_here" />
              }
            />
          </Typography>
          <Typography variant="label">{t('2. Open the project you want to import.')}</Typography>
          <Typography variant="label">{t('3. Navigate to Reports.')}</Typography>
          <Typography variant="label">
            {t('4. Click on CSV Export under Reports section.')}
          </Typography>
          <Typography variant="label">{t('5. Upload the downloaded CSV file here.')}</Typography>
        </div>
      </Card>
      <FileUtils.Input
        {...F.props(state.file)}
        required
        download={() => unhandled('')}
        title={t('Upload XactRemodel CSV')}
        description={t('CSV from XactRemodel')}
        maxFiles={1}
        fileTypes="csv"
      />
    </Modal.Body>
  )
}

export default Modal.asModal(ImportEstimateFromXactRemodelForm)

import Typography from 'compass/data/Typography'
import { Cb } from 'cb'
import { t } from 'content'
import { MSArray } from 'msutils'
import ApproveInvoices from './components/ApproveInvoices'
import UploadCOIDocument from './components/UploadCOIDocument'
import AdminApproveInvoice from './components/AdminApproveInvoice'
import NonadminResolveInvoice from './components/NonadminResolveInvoice'
import SignUnconditionalLienWaiver from './components/SignUnconditionalLienWaiver'
import SignConditionalLienWaiver from './components/SignConditionalLienWaiver'
import ScheduleBillPayment from './components/ScheduleBillPayment'
import ConvertApprovedEstimateAsPayee from './components/ConvertApprovedEstimateAsPayee'
import ConvertApprovedEstimateAsPayer from './components/ConvertApprovedEstimateAsPayer'

type Props = {
  tasks: Cb.Task[]
  close: () => void
}

export default function NotificationsList({ tasks, close }: Props) {
  return (
    <div className="vflex divide-y divide-th-warmgrey-3 border-t border-t-th-warmgrey-3">
      {MSArray.isNonEmpty(tasks) ? (
        <>
          {tasks.map((task, i) => {
            switch (task.type) {
              case 'approve_invoices': {
                const details = task.approve_invoices_details
                return details ? <ApproveInvoices key={i} details={details} close={close} /> : null
              }
              case 'upload_coi_document': {
                return <UploadCOIDocument key={i} close={close} />
              }
              case 'admin_approve_invoice': {
                const details = task.admin_approve_invoice_details
                return details ? (
                  <AdminApproveInvoice key={i} details={details} close={close} />
                ) : null
              }
              case 'nonadmin_resolve_invoice': {
                const details = task.nonadmin_resolve_invoice_details
                return details ? (
                  <NonadminResolveInvoice key={i} details={details} close={close} />
                ) : null
              }
              case 'convert_approved_estimate_as_payee': {
                const details = task.convert_approved_estimate_as_payee_details
                return details ? (
                  <ConvertApprovedEstimateAsPayee key={i} details={details} close={close} />
                ) : null
              }
              case 'convert_approved_estimate_as_payer': {
                const details = task.convert_approved_estimate_as_payer_details
                return details ? (
                  <ConvertApprovedEstimateAsPayer key={i} details={details} close={close} />
                ) : null
              }
              case 'sign_conditional_lien_waiver': {
                const details = task.sign_conditional_lien_waiver_details
                return details ? (
                  <SignConditionalLienWaiver key={i} details={details} close={close} />
                ) : null
              }
              case 'sign_unconditional_lien_waiver': {
                const details = task.sign_unconditional_lien_waiver_details
                return details ? (
                  <SignUnconditionalLienWaiver key={i} details={details} close={close} />
                ) : null
              }
              case 'schedule_bill_payment': {
                const details = task.schedule_bill_payment_details
                return details ? (
                  <ScheduleBillPayment key={i} details={details} close={close} />
                ) : null
              }
              default: {
                // TODO: Implement support for other task types
                return null
              }
            }
          })}
        </>
      ) : (
        <div className="text-th-coolgrey-1 h-[64px] flex items-center">
          <Typography>{t('No new notifications')}</Typography>
        </div>
      )}
    </div>
  )
}

import { cn } from 'msutils/classnames'
import Typography from 'compass/data/Typography'
import useScreenSize from 'compass/theme/useScreenSize'
import {
  getAlignment,
  Table as BaseTable,
  TBody,
  TD,
  TH,
  THead,
  TR,
  SelectControl,
  Style,
  getHeaderPaddingForStyle,
  getHeaderVariantForStyle,
  getHeaderColorForStyle,
} from '../internal-utils'
import { TableUtils as Utils } from '../utils'
import Row from './Row'
import { MobileRow } from './MobileRow'

type Props<TData, TCtx> = {
  spec: ReturnType<typeof Utils.useSpec<TData, TCtx>>
  empty?: string
  style?: Style
  noHeader?: boolean
}

export function Table<TData, TCtx>({
  spec,
  noHeader,
  style = 'item-list',
  empty,
}: Props<TData, TCtx>) {
  const { rows, ctx, state, getId, allowSelect, getSelectDisabledReason, disableSelectAll } = spec
  const columns = spec.columns.filter(
    (x) => !x.dynamic?.omit?.(ctx) && !x.dynamic?.mobileOnly?.(ctx),
  )
  const sz = useScreenSize()
  const showAsTable = sz !== 'sm' || style === 'preview'

  if (rows.length === 0) {
    return (
      <div className="p-5 text-center">
        <Typography className="text-th-coolgrey-1">{empty}</Typography>
      </div>
    )
  }

  const selectableIds = rows.flatMap((x, i) =>
    getSelectDisabledReason?.({ row: x.data, depth: x.depth }, ctx) ? [] : getId(x.data, i),
  )
  const headerSelectionState: boolean | 'partial' | 'indeterminate' =
    selectableIds.length === 0
      ? 'indeterminate'
      : selectableIds.every(state.selected.isActive)
      ? true
      : selectableIds.some(state.selected.isActive)
      ? 'partial'
      : false

  const border = style === 'input-table' ? { color: 'grey' as const, width: 'sm' as const } : null

  if (!showAsTable) {
    const footer = spec.footer?.(ctx)
    return (
      <div className="vflex gap-5">
        {rows.map((row, i) => {
          const rowId = getId(row.data, i)
          return <MobileRow key={rowId} spec={spec} style={style} row={row} index={i} ctx={ctx} />
        })}
        {footer?.component && <div className="bg-th-bg-slate py-2">{footer.component}</div>}
      </div>
    )
  } else {
    const footer = spec.footer?.(ctx)
    return (
      <BaseTable style={style}>
        {!noHeader && (
          <THead style={style}>
            <TR>
              <TD border={border}>
                {allowSelect && !disableSelectAll && (
                  <SelectControl
                    selected={headerSelectionState}
                    setSelected={(newValue) =>
                      selectableIds.map((x) => state.selected.set(x, newValue))
                    }
                    disabledMessage={null}
                  />
                )}
              </TD>
              {columns.map((c, j) => {
                return (
                  <TH key={j} border={border}>
                    <Typography
                      variant={getHeaderVariantForStyle(style)}
                      className={cn(
                        'whitespace-nowrap !block',
                        getHeaderColorForStyle(style),
                        getHeaderPaddingForStyle(style),
                        typeof c.size?.minWidth === 'string' && c.size?.minWidth,
                        getAlignment(c.align),
                      )}
                    >
                      {c.dynamic?.header?.(ctx) ?? c.header}
                    </Typography>
                  </TH>
                )
              })}
              <TD border={border} />
            </TR>
          </THead>
        )}
        <TBody>
          {rows.map((row, i) => {
            const rowId = getId(row.data, i)
            return <Row key={rowId} spec={spec} style={style} row={row} index={i} ctx={ctx} />
          })}
        </TBody>
        {footer?.component && (
          <tfoot>
            <TR className="bg-th-bg-slate">
              <TD border={null} />
              <TD colSpan={columns.length} border={null}>
                {footer.component}
              </TD>
              <TD border={null} />
            </TR>
          </tfoot>
        )}
      </BaseTable>
    )
  }
}

import { cn } from 'msutils/classnames'
import Icon from '../Icon'
import { BadgeUtils } from './utils'

type Props = {
  theme: BadgeUtils.Theme
  icon?: Parameters<typeof Icon>[0]['name']
  children: string
}

export default function Badge({ theme, icon, children }: Props) {
  return (
    <div
      className={cn(
        'text-[10px] leading-[10px] font-bold tracking-[0.05em] uppercase w-[18px] min-w-fit p-1 rounded-4 h-fit whitespace-nowrap flex gap-1 justify-center items-center',
        theme.bgColor,
        theme.textColor,
      )}
    >
      {icon && <Icon name={icon} height={10} />}
      {children}
    </div>
  )
}

import { MSForm } from 'utils/form'
import { t } from 'content'
import { Cb } from 'cb'
import { showToast } from 'utils/toast'
import { Module } from 'modules/routes'
import { useFormState } from 'utils/form-input'
import ClientInput, { ClientInputUtils } from 'components/inputs/ClientInput'
import { RichAddressInputUtils } from 'components/inputs/RichAddressInput'
import { useBusinessContext } from 'root/user'

type Props = {
  onSuccess?: (_: Cb.PayerContact) => void
  initialName?: string
}

const fc = MSForm.Controller<Props>()
export default fc.Form(() => {
  const { onSuccess, initialName } = fc.useContext()
  const { business } = useBusinessContext()
  const { state, useValidatedAction } = useFormState(ClientInputUtils.schema, {
    initValue: { name: initialName },
  })

  const createClient = Cb.useCreatePayerContactHook()
  const createClientAction = useValidatedAction(
    (validData) => {
      return createClient({
        name: validData.name,
        address: validData.address ? RichAddressInputUtils.toApi(validData.address) : null,
        email: validData.email,
        cc_emails: validData.ccEmails,
        contact_type: validData.contactType,
        payee_id: business.id,
        phone: validData.phone,
        ein: validData.ein || null,
      })
    },
    {
      onSuccess: (newValue) => {
        showToast({
          title: t('New client added'),
          label: t('Go to client page'),
          navigateTo2: Module(`/clients/${newValue.id}`).href,
        })
        onSuccess?.(newValue)
      },
    },
  )

  return (
    <ClientInput
      state={state}
      submit={createClientAction}
      copy={{ title: t('Add client'), submit: t('Add') }}
    />
  )
})

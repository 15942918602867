import { useEffect, useState } from 'react'
import { TypographyUtils } from 'compass/data/Typography/utils'

type Props = {
  variant: TypographyUtils.Variant
  children: string
}
export function SmartTextWrap({ variant, children }: Props) {
  const [ref, setRef] = useState<HTMLSpanElement | null>(null)
  useEffect(() => {
    const onResize = () => {
      if (ref) {
        ref.style.width = 'auto'
        const range = document.createRange()
        const text = ref.childNodes[0]
        range.setStartBefore(text)
        range.setEndAfter(text)
        const clientRect = range.getBoundingClientRect()
        ref.style.width = `${clientRect.width}px`
      }
    }
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [ref, children])

  return (
    <span className={TypographyUtils.Styles[variant]} ref={setRef}>
      {children}
    </span>
  )
}

import { Cb } from 'cb'

function define<T extends string>(rules: Record<T, Cb.EmployeeRole[]>) {
  return rules
}

export const permissionRules = define({
  addEmployee: ['admin'],
  deleteEmployee: ['admin'],
  onboardAccount: ['admin'],
  updateBusiness: ['admin'],
  updateCompliance: ['admin'],
  linkIntegrations: ['admin'],
  linkBank: ['admin'],
  createApprovalRule: ['admin'],
  deleteApprovalRule: ['admin'],

  createProject: ['admin', 'member'],
  updateProject: ['admin', 'member'],
  createContract: ['admin', 'member'],
  updateContract: ['admin', 'member'],
  createInvoice: ['admin', 'member'],
  updateInvoice: ['admin', 'member'],
  createBill: ['admin', 'member'],
  updateBill: ['admin', 'member'],
  approveBill: ['admin', 'member', 'project_manager'],
  createExpense: ['admin', 'member'],
  updateExpense: ['admin', 'member'],
  createTransfer: ['admin', 'member'],
  recordChangeOrder: ['admin', 'member'],
  proposeChangeOrder: ['admin', 'member', 'estimator', 'project_manager', 'salesperson'],

  createAccountingCode: ['admin', 'member'],
  updateAccountingCode: ['admin', 'member'],
  createCostType: ['admin', 'member'],
  updateCostType: ['admin', 'member'],
  createVendor: ['admin', 'estimator', 'member'],
  createClient: ['admin', 'estimator', 'member'],

  viewProjectFinances: ['admin', 'member', 'estimator', 'project_manager'],
  viewProjects: [
    'admin',
    'member',
    'estimator',
    'project_manager',
    'field_crew_member',
    'salesperson',
  ],
  viewEstimates: ['admin', 'member', 'estimator', 'project_manager', 'salesperson'],
  viewClients: ['admin', 'member', 'estimator', 'project_manager', 'salesperson'],
  viewVendors: ['admin', 'member', 'estimator', 'project_manager', 'salesperson'],
  viewExpenses: ['admin', 'member', 'estimator', 'project_manager'],
  viewBills: ['admin', 'member', 'estimator', 'project_manager'],
  viewInvoices: ['admin', 'member', 'estimator', 'project_manager'],
  viewBeamBalance: ['admin', 'member'],
  viewExpenseCards: ['admin', 'member'],
  viewSettings: [
    'admin',
    'member',
    'estimator',
    'project_manager',
    'field_crew_member',
    'salesperson',
  ],
  viewBusinessSettings: ['admin', 'member'],
})

export type PermissionRules = typeof permissionRules

import { useEffect } from 'react'
import Modal from 'compass-local/Modal'
import Typography from 'compass/data/Typography'
import { t } from 'content'
import { createRoot } from 'react-dom/client'
import { Action } from './actions'
import useFormProps from './useFormProps'

type ConfirmationModalProps = {
  title: string
  message?: string
  onConfirm: () => void
  setInactive: () => void
}

const ConfirmationModal = Modal.asModal(
  ({ onConfirm, setInactive, title, message }: ConfirmationModalProps) => {
    return (
      <Modal.Body
        title={title}
        setInactive={setInactive}
        actions={[
          Action.button(t('Cancel'), { onClick: setInactive, theme: 'secondary' }),
          Action.button(t('Confirm'), { onClick: onConfirm }),
        ]}
      >
        {message && <Typography className="text-th-text-secondary">{message}</Typography>}
      </Modal.Body>
    )
  },
)

function AutoOpenConfirmation({ onConfirm, setInactive, title, message }: ConfirmationModalProps) {
  const formProps = useFormProps()
  const setActive = formProps.setActive
  useEffect(() => setActive(), [setActive])

  return (
    <ConfirmationModal
      {...formProps}
      onConfirm={() => {
        formProps.setInactive()
        setTimeout(onConfirm, 100)
      }}
      setInactive={() => {
        formProps.setInactive()
        setTimeout(setInactive, 100)
      }}
      title={title}
      message={message}
    />
  )
}

export function confirm(title: string, message?: string) {
  const el = document.createElement('div')
  document.body.appendChild(el)
  return new Promise<boolean>((res) => {
    const root = createRoot(el)
    root.render(
      <AutoOpenConfirmation
        onConfirm={() => {
          document.body.removeChild(el)
          res(true)
        }}
        setInactive={() => {
          document.body.removeChild(el)
          res(false)
        }}
        title={title}
        message={message}
      />,
    )
  })
}

export function getFirstOptDONOTUSE<T extends Exclude<any, undefined>>(
  arr: readonly T[],
): T | null {
  return arr.at(0) ?? null
}

export function getFirstDONOTUSE<T extends Exclude<any, undefined>>(arr: readonly T[]): T {
  const firstOpt = getFirstOptDONOTUSE(arr)
  if (firstOpt === null) throw new Error('Expected element in array')
  return firstOpt
}

export function unionConcatDONOTUSE<T1, T2>(arr1: T1[], arr2: T2[]): (T1 | T2)[] {
  const base = [] as (T1 | T2)[]
  return base.concat(arr1).concat(arr2)
}

export function isEmptyOrIncludes<T>(arr: readonly T[], item: T) {
  if (arr.length === 0) return true
  return arr.includes(item)
}

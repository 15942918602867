import BigNumber from 'bignumber.js'
import { Zero } from '../common'
import { Cell, CellField } from './base'
import { FieldError } from './utils'

type DecimalField2<V> = CellField<'' | BigNumber, V>

type DecimalProps<TReq> = {
  required?: TReq
  initValue?: '' | BigNumber
  treatEmptyAs?: BigNumber
  nonzero?: boolean
  positive?: boolean
  errorKey?: string
}

export function Decimal2<TReq extends boolean = false>(
  props?: DecimalProps<TReq>,
): DecimalField2<TReq extends true ? BigNumber : BigNumber | ''> {
  const validate: any = (x: BigNumber | '') => {
    if (props?.required) {
      if (!x) {
        throw new FieldError('Required')
      } else if (x.isNaN()) {
        throw new FieldError('Please enter a valid amount')
      } else if (props.nonzero && x.eq(Zero)) {
        throw new FieldError('Please enter a nonzero amount')
      } else if (props.positive && x.lt(Zero)) {
        throw new FieldError('Please enter a value greater than zero')
      } else {
        return x
      }
    } else {
      if (!x) {
        return props?.treatEmptyAs ?? ''
      } else if (x.isNaN()) {
        throw new FieldError('Please enter a valid amount')
      } else if (props?.nonzero && x.eq(Zero)) {
        throw new FieldError('Please enter a nonzero amount')
      } else if (props?.positive && x.lt(Zero)) {
        throw new FieldError('Please enter a value greater than zero')
      } else {
        return x
      }
    }
  }
  return Cell({
    initValue: props?.initValue ?? '',
    validate,
    errorKey: props?.errorKey,
  })
}

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListWorkCompletedSourcesOrdering =
  (typeof ListWorkCompletedSourcesOrdering)[keyof typeof ListWorkCompletedSourcesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListWorkCompletedSourcesOrdering = {
  markup_multiplier: 'markup_multiplier',
  '-markup_multiplier': '-markup_multiplier',
  unit_value: 'unit_value',
  '-unit_value': '-unit_value',
  quantity: 'quantity',
  '-quantity': '-quantity',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

import { createContext, FC, ReactNode, useCallback, useContext, useEffect } from 'react'
import { Q } from 'cb'
import { t } from 'content'
import { MSError } from 'msutils'
import { unreachable } from 'msutils/misc'
import { toast } from 'react-toastify'

type BoundaryProps = {
  isActive: boolean
  setInactive: () => void
  children: ReactNode
}

function Boundary({ isActive, setInactive, children }: BoundaryProps) {
  const Fallback = useCallback(
    function FallbackInner() {
      useEffect(() => {
        if (isActive) {
          setInactive()
          toast.error(t('Something went wrong'))
        }
      }, [])

      return null
    },
    [isActive, setInactive],
  )

  return (
    <MSError.Boundary name="FormBoundary" Fallback={Fallback}>
      {children}
    </MSError.Boundary>
  )
}

export type OpenerProps = {
  isActive: boolean
  setInactive: () => void
}

function wrap<T extends OpenerProps>(Component: FC<T>): FC<T> {
  return function Inner(props: T) {
    return (
      <Boundary isActive={props.isActive} setInactive={props.setInactive}>
        <Component {...props} />
      </Boundary>
    )
  }
}

export type QueryContext<T extends object, S extends object> = {
  use: () => S & T
  wrap: <P extends OpenerProps & S>(Component: FC<P>) => FC<P>
}

export function initContext<T extends object, S extends object>(
  useQueries: (props: S) => Q.Queryset<T>,
): QueryContext<T, S> {
  const Context = createContext<(S & T) | undefined>(undefined)
  return {
    use: () => {
      // eslint-disable-next-line
      const ctx = useContext(Context)
      if (ctx === undefined) throw new Error('Query context must be used within Query provider')
      return ctx
    },
    wrap: <P extends OpenerProps & S>(Component: FC<P>) => {
      return wrap((props: P) => {
        const queryset = useQueries(props)
        switch (queryset.status) {
          case 'error':
            throw new MSError.Error2('Error in queryset')
          case 'loading':
            return null
          case 'success':
            return (
              <Context.Provider value={{ ...props, ...queryset.queries }}>
                <Component {...props} />
              </Context.Provider>
            )
          default:
            return unreachable(queryset)
        }
      })
    },
  }
}

import { FC, ReactNode, useCallback } from 'react'
import { MSError } from 'msutils'
import attach from 'utils/attach'

type Props = {
  name?: string
  children: ReactNode
}

function WidgetBoundary({ name, children }: Props) {
  const Fallback = useCallback(() => <></>, [])
  return (
    <MSError.Boundary name={name} Fallback={Fallback}>
      {children}
    </MSError.Boundary>
  )
}

function wrap<T extends object>(Component: FC<T>): FC<T> {
  return function Inner(props) {
    return (
      <WidgetBoundary name={`${Component.name}Widget`}>
        <Component {...props} />
      </WidgetBoundary>
    )
  }
}

export default attach(WidgetBoundary, { wrap })

import { useState, useEffect, useRef, ReactNode } from 'react'
import { cn } from 'msutils/classnames'

type Props = {
  classNames?: {
    container?: () => string
  }
  children: ReactNode
}

export default function AutoScale({ classNames, children }: Props) {
  const [settled, setSettled] = useState(false)
  const [scale, setScale] = useState(1)
  const [desiredHeight, setDesiredHeight] = useState(0)

  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Note: ignore observer entries in callback since I already have the refs
    const t = setTimeout(() => setSettled(true), 50)
    const observer = new ResizeObserver(() => {
      setSettled(true)
      const containerWidth = containerRef.current?.clientWidth
      const contentWidth = contentRef.current?.clientWidth
      const contentHeight = contentRef.current?.clientHeight

      if (contentWidth && containerWidth) {
        const newScale = containerWidth / contentWidth
        setScale(newScale)

        if (contentHeight) {
          setDesiredHeight(newScale * contentHeight)
        }
      }
    })

    if (containerRef.current && contentRef.current) {
      observer.observe(containerRef.current)
      observer.observe(contentRef.current)

      return () => {
        observer.disconnect()
      }
    }

    return () => {
      clearTimeout(t)
    }
  }, [containerRef, contentRef])

  return (
    <div
      ref={containerRef}
      className={cn('relative box-content', classNames?.container?.())}
      style={{ height: `${desiredHeight}px`, opacity: settled ? 1 : 0 }}
    >
      <div
        ref={contentRef}
        className="absolute top-0 left-0 origin-top-left"
        style={{ transform: `scale(${scale.toFixed(3)})` }}
      >
        {children}
      </div>
    </div>
  )
}

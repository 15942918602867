/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeChargeType =
  (typeof BeamBalanceTransactionTypeChargeType)[keyof typeof BeamBalanceTransactionTypeChargeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeChargeType = {
  charge: 'charge',
} as const

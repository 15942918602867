import { ReactNode } from 'react'

export namespace TabGroupUtils {
  type OptionProps = {
    icon?: ReactNode
    badge?: ReactNode
  }

  export class Option<T extends string> {
    id: T

    name: ReactNode

    icon?: ReactNode

    badge?: ReactNode

    constructor(id: T, name: ReactNode, optionProps?: OptionProps) {
      this.id = id
      this.name = name
      this.icon = optionProps?.icon
      this.badge = optionProps?.badge
    }
  }

  export function option<T extends string>(...props: ConstructorParameters<typeof Option<T>>) {
    return new Option(...props)
  }

  export type OptionListProps<T extends string> =
    | {
        type?: 'card'
        /** non-static values */
        metrics: {
          [K in T]: string
        }
      }
    | {
        /** Used for list pages */
        type: 'table'
        /** non-static values */
        descriptions: {
          [K in T]: ReactNode
        }
      }
    | {
        /** Used when the parent layout has multiple sections */
        type: 'section'
      }
    | {
        /** Used for only children of mainContent */
        type: 'top-section'
      }
}

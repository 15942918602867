/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListEstimateAsPayeeListRowsOrdering =
  (typeof ListEstimateAsPayeeListRowsOrdering)[keyof typeof ListEstimateAsPayeeListRowsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListEstimateAsPayeeListRowsOrdering = {
  type: 'type',
  '-type': '-type',
  number: 'number',
  '-number': '-number',
  display_name: 'display_name',
  '-display_name': '-display_name',
  payer_name: 'payer_name',
  '-payer_name': '-payer_name',
  status: 'status',
  '-status': '-status',
  expiration_date: 'expiration_date',
  '-expiration_date': '-expiration_date',
  total_amount: 'total_amount',
  '-total_amount': '-total_amount',
  has_unpublished_changes: 'has_unpublished_changes',
  '-has_unpublished_changes': '-has_unpublished_changes',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

import { Children, CSSProperties, forwardRef, ReactNode } from 'react'
import { unreachable } from 'msutils/misc'

export namespace BaseLayout {
  const Grain = 4

  type Children = { children?: ReactNode }
  type ViewProps = {
    inset?: number
    insetXDONOTUSE?: number
    insetYDONOTUSE?: number
    border?: string
    outline?: string
    borderRadius?: number
    background?: string
    fillHeight?: boolean
    fillWidth?: boolean
    fixedWidth?: number
    collapseWidth?: boolean
  }

  function getViewStyles({
    inset,
    insetXDONOTUSE = inset,
    insetYDONOTUSE = inset,
    border,
    outline,
    borderRadius,
    background,
    fillHeight,
    fillWidth,
    fixedWidth,
    collapseWidth,
  }: ViewProps): CSSProperties {
    return {
      paddingTop: insetYDONOTUSE ? insetYDONOTUSE * Grain : undefined,
      paddingBottom: insetYDONOTUSE ? insetYDONOTUSE * Grain : undefined,
      paddingRight: insetXDONOTUSE ? insetXDONOTUSE * Grain : undefined,
      paddingLeft: insetXDONOTUSE ? insetXDONOTUSE * Grain : undefined,
      outline,
      border,
      borderRadius: borderRadius ? borderRadius * Grain : undefined,
      background,
      height: fillHeight ? '100%' : undefined,
      width: fixedWidth ?? (fillWidth ? '100%' : collapseWidth ? 'fit-content' : undefined),
      minWidth: fixedWidth,
    }
  }

  export const View = forwardRef<HTMLDivElement, ViewProps & Children>((props, ref) => {
    return (
      <div ref={ref} style={getViewStyles(props)}>
        {props.children}
      </div>
    )
  })

  type StackProps = {
    gap?: number
    justify?: 'between' | 'start' | 'end' | 'center'
    align?: 'center' | 'start' | 'end'
    orientation: 'vertical' | 'horizontal'
    view?: ViewProps
  }

  export const Stack = forwardRef<HTMLDivElement, StackProps & Children>((props, ref) => {
    const {
      gap = 0,
      justify = 'start',
      orientation,
      align = orientation === 'vertical' ? undefined : 'center',
      view,
      children,
    } = props

    if (!ref && !view && Children.toArray(children).length <= 1) {
      return <>{children}</>
    } else {
      return (
        <div
          ref={ref}
          style={{
            ...getViewStyles({ fillWidth: orientation === 'vertical', ...view }),
            display: 'flex',
            flexDirection: orientation === 'vertical' ? 'column' : 'row',
            gap: gap ? gap * Grain : undefined,
            justifyContent:
              justify === 'between'
                ? 'space-between'
                : justify === 'start'
                ? 'start'
                : justify === 'end'
                ? 'end'
                : justify === 'center'
                ? 'center'
                : unreachable(justify),
            alignItems: !align
              ? undefined
              : align === 'center'
              ? 'center'
              : align === 'start'
              ? 'start'
              : align === 'end'
              ? 'end'
              : unreachable(align),
          }}
        >
          {children}
        </div>
      )
    }
  })

  export const HStack = forwardRef<HTMLDivElement, Omit<StackProps & Children, 'orientation'>>(
    (p, ref) => <Stack {...p} ref={ref} orientation="horizontal" />,
  )

  export const VStack = forwardRef<HTMLDivElement, Omit<StackProps & Children, 'orientation'>>(
    (p, ref) => <Stack {...p} ref={ref} orientation="vertical" />,
  )

  type ScrollerProps = {
    scrollX?: boolean
    scrollY?: boolean
    ignoreNaturalSize?: boolean
  }

  export const Scroller = forwardRef<HTMLDivElement, ScrollerProps & Children>((props, ref) => {
    const content = (
      <div
        ref={ref}
        style={{
          position: props.ignoreNaturalSize ? 'absolute' : undefined,
          inset: props.ignoreNaturalSize ? 0 : undefined,
          overflowX: props.scrollX ? 'scroll' : undefined,
          overflowY: props.scrollY ? 'scroll' : undefined,
        }}
      >
        {props.children}
      </div>
    )

    if (props.ignoreNaturalSize) {
      return <div style={{ position: 'relative', height: '100%' }}>{content}</div>
    } else {
      return content
    }
  })

  export const NativeDiv = forwardRef<
    HTMLDivElement,
    CSSProperties & { children?: ReactNode; className?: string }
  >((props, ref) => {
    return (
      <div ref={ref} style={props} className={props.className}>
        {props.children}
      </div>
    )
  })
}

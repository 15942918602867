import { MSArray } from './array'
import { expectReturn2 } from './assert'

export type GroupProps<T, S> = {
  items: T[]
  keyFn: (item: T) => (string | null)[]
  transformFn: (values: T[]) => S
}

export function groupByKeyAndMap<T, S>({ items, keyFn, transformFn }: GroupProps<T, S>) {
  return items
    .map((x) => {
      return {
        key: keyFn(x),
        value: x,
      }
    })
    .reduce(
      (p, c) => {
        const matchingIndex = p.findIndex((x) => x.key.join('/') === c.key.join('/'))
        if (matchingIndex >= 0) {
          const matchingItem = expectReturn2(p.at(matchingIndex))('')
          return MSArray.replace(p, matchingIndex, {
            key: matchingItem.key,
            values: [...matchingItem.values, c.value],
          })
        } else {
          return [...p, { key: c.key, values: [c.value] }]
        }
      },
      [] as { key: (string | null)[]; values: T[] }[],
    )
    .map((kvs: { key: (string | null)[]; values: T[] }) => transformFn(kvs.values))
}

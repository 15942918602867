/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListAdminQBPushRequestsOrdering =
  (typeof ListAdminQBPushRequestsOrdering)[keyof typeof ListAdminQBPushRequestsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListAdminQBPushRequestsOrdering = {
  skip_reason: 'skip_reason',
  '-skip_reason': '-skip_reason',
  failure_reason: 'failure_reason',
  '-failure_reason': '-failure_reason',
  complete: 'complete',
  '-complete': '-complete',
  error_content: 'error_content',
  '-error_content': '-error_content',
  is_retry: 'is_retry',
  '-is_retry': '-is_retry',
  source_id: 'source_id',
  '-source_id': '-source_id',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

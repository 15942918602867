import FileSaver from 'file-saver'

export function download(file: File, basename: string) {
  switch (file.type) {
    case 'application/pdf':
      return FileSaver.saveAs(file, `${basename}.pdf`)
    case 'image/png':
      return FileSaver.saveAs(file, `${basename}.png`)
    case 'image/jpeg':
      return FileSaver.saveAs(file, `${basename}.jpeg`)
    case 'application/x-zip-compressed':
      return FileSaver.saveAs(file, `${basename}.zip`)
    default:
      throw new Error(`Cannot download unsupported file type: ${file.type}`)
  }
}

export function serializeFilename(str: string) {
  return str.replaceAll(/\s/g, '_').replaceAll(/[^a-zA-Z_0-9\\-]/g, '')
}

export function getHeightAndWidthFromFile(f: File) {
  return new Promise<{ height: number; width: number }>((res) => {
    const img = new Image()
    img.onload = () => {
      res({ height: img.height, width: img.width })
    }
    img.src = window.URL.createObjectURL(f)
  })
}

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListExpenseCodesOrdering =
  (typeof ListExpenseCodesOrdering)[keyof typeof ListExpenseCodesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListExpenseCodesOrdering = {
  name: 'name',
  '-name': '-name',
  description: 'description',
  '-description': '-description',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListInvoiceApprovalRulesOrdering =
  (typeof ListInvoiceApprovalRulesOrdering)[keyof typeof ListInvoiceApprovalRulesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListInvoiceApprovalRulesOrdering = {
  type: 'type',
  '-type': '-type',
  amount_threshold: 'amount_threshold',
  '-amount_threshold': '-amount_threshold',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type QBPullRequestLinkType =
  (typeof QBPullRequestLinkType)[keyof typeof QBPullRequestLinkType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QBPullRequestLinkType = {
  ap_invoice: 'ap_invoice',
  ap_invoice_payment: 'ap_invoice_payment',
  ar_invoice: 'ar_invoice',
  ar_invoice_payment: 'ar_invoice_payment',
  external_payment_source: 'external_payment_source',
  transfer: 'transfer',
  expense_code: 'expense_code',
  income_code: 'income_code',
  project: 'project',
  customer: 'customer',
  vendor: 'vendor',
  time_entry: 'time_entry',
  ap_invoice_embedded_file: 'ap_invoice_embedded_file',
  ap_invoice_embedded_primary_file: 'ap_invoice_embedded_primary_file',
  ar_invoice_embedded_file: 'ar_invoice_embedded_file',
  file: 'file',
  expense: 'expense',
  credit_memo_invoice: 'credit_memo_invoice',
  credit_card_payment: 'credit_card_payment',
  expense_receipt_embedded_file: 'expense_receipt_embedded_file',
  yield_payment: 'yield_payment',
  cash_back_payment: 'cash_back_payment',
  card_repayment: 'card_repayment',
  item: 'item',
  ledger_account: 'ledger_account',
  prime_contract: 'prime_contract',
} as const

import { Collapsable, MSArray } from 'msutils/array'
import { cn } from 'msutils/classnames'
import Tooltip from '../../../layout/Tooltip'
import Typography from '../../Typography'
import { DropdownButtonUtils } from './utils'

export function processOptions(
  uncollapsedOptions: Collapsable<DropdownButtonUtils.OptionOrDivider[]>,
) {
  const options = MSArray.collapse(uncollapsedOptions)
  return options.reduce(
    // eslint-disable-next-line
    (p, c, i) => {
      if (i === 0 && c.type === 'divider') {
        return p
      } else if (i === options.length - 1 && c.type === 'divider') {
        return p
      } else if (MSArray.isNonEmpty(p) && p.at(-1)?.type === 'divider' && c.type === 'divider') {
        return p
      } else {
        return [...p, c]
      }
    },
    [] as DropdownButtonUtils.OptionOrDivider[],
  )
}

export function Option(item: DropdownButtonUtils.OptionSpec) {
  return (
    <div className="w-full py-1.5 px-3">
      <Tooltip
        inactive={!item.disabled || !item.disabledMessage}
        message={item.disabledMessage ?? null}
      >
        <div
          className={cn(
            item.color ?? 'text-th-text',
            item.disabled && 'opacity-50',
            'flex justify-between items-center',
          )}
        >
          <div className="flex items-center gap-1">
            {item.icon}
            <Typography variant="bodybold">{item.name}</Typography>
          </div>
          {item.endIcon}
        </div>
      </Tooltip>
    </div>
  )
}

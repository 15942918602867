import { cn } from 'msutils/classnames'
import { CloseCircle } from 'compass-local/legacy/icons'
import { compassId } from 'compass-local/root/utils'
import Typography from 'compass/data/Typography'
import useScreenSize from 'compass/theme/useScreenSize'
import { Select2Utils as Utils } from './utils'

type OptionProps<TVal, TMeta> = Utils.OptionProps<TVal, TMeta> & {
  meta: TMeta
  isHighlighted: boolean
  setIsHighlighted: () => void
  onClick: () => void
}

export function Option<TVal, TMeta>(props: OptionProps<TVal, TMeta>) {
  const {
    getValueFromOption,
    getId,
    getTitle,
    getOptionProps,
    meta,
    isHighlighted,
    setIsHighlighted,
    onClick,
  } = props
  const val = getValueFromOption(meta)
  const id = getId(val)
  const title = getTitle(val)
  const metaProps = getOptionProps?.(meta)
  const sz = useScreenSize()

  return (
    <div
      key={id}
      role="button"
      tabIndex={0}
      onMouseEnter={() => !isHighlighted && setIsHighlighted()}
      onMouseMove={() => !isHighlighted && setIsHighlighted()}
      // onMouseDown, because we lose focus and the menu disappears if we use onClick
      onMouseDown={onClick}
      id={id}
      className={cn(
        compassId('select', 'option'),
        'cursor-pointer vflex gap-1',
        sz === 'sm' ? 'p-4' : 'p-2',
        sz === 'sm' ? 'rounded-8' : 'rounded-4',
        isHighlighted && 'bg-th-orange-light2',
      )}
    >
      <Typography variant="bodybold" className="whitespace-nowrap max-w-[220px] truncate">
        {title}
      </Typography>
      {metaProps?.subtitle && (
        <Typography variant="caption" className="text-th-text-secondary clamp-2">
          {metaProps.subtitle}
        </Typography>
      )}
    </div>
  )
}

type DeselectIconProps = {
  deselect: () => void
}

export function DeselectIcon({ deselect }: DeselectIconProps) {
  return (
    <CloseCircle
      height={12}
      className="text-th-coolgrey-2 hover:text-th-coolgrey-1 hitbox-lg pointer-events-auto"
      onClick={(e) => {
        deselect()
        e.stopPropagation()
      }}
    />
  )
}

import { useMemo } from 'react'
import { z } from 'zod'
import { Cb, Q } from 'cb'
import useUrlParam from 'utils/useUrlParam'
import { DateFilterSpec } from 'compass-local/FilterInput/utils'
import { useBusinessContext } from 'root/user'

export const moduleId = 'invoices'

export function useUrlParams() {
  const t = useUrlParam('t', z.enum(['all', 'unpaid', 'processing', 'paid']).default('all'))
  const q = useUrlParam('q', z.string().default(''))
  const s = useUrlParam(
    's',
    z
      .enum([
        'due_date',
        '-due_date',
        'date',
        '-date',
        'payer',
        '-payer',
        'payment_amount',
        '-payment_amount',
        'project_name',
        '-project_name',
      ])
      .nullable()
      .default(null),
  )
  const f = useUrlParam(
    'f',
    z
      .object({
        status: z
          .enum([
            'overdue',
            'paid',
            'partially_paid',
            'pending',
            'processing',
            'ready_for_payment',
            'rejected',
            'scheduled',
            'unsent',
            'void',
          ])
          .array()
          .default([]),
        issueDate: DateFilterSpec,
        dueDate: DateFilterSpec,
        deliveredOn: DateFilterSpec,
      })
      .default({}),
  )
  const p = useUrlParam('p', z.number().default(1))

  return useMemo(
    () => ({ tabState: t, searchState: q, sortState: s, filterState: f, pageState: p }),
    [t, q, s, f, p],
  )
}

export function useQueries() {
  const { business } = useBusinessContext()
  return Q.group({
    invoicesSummary: Cb.useRetrieveInvoicesPageSummary(business.id),
  })
}

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListProcoreAccountsOrdering =
  (typeof ListProcoreAccountsOrdering)[keyof typeof ListProcoreAccountsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListProcoreAccountsOrdering = {
  status: 'status',
  '-status': '-status',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

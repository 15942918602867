import { useQuery } from '@tanstack/react-query'
import { Cb } from 'cb'
import { Smarty } from 'lib/smarty'
import { F } from 'msutils'
import { State, formatAddress } from 'utils/address'
import { timeout } from 'utils/timeout'

export namespace RichAddressInputUtils {
  export const schema = F.Group({
    spec: {
      line1: F.Text({ errorKey: 'rich_address.line_1', required: true }),
      line2: F.Text({ errorKey: 'rich_address.line_2' }),
      city: F.Text({ errorKey: 'rich_address.city', required: true }),
      zip: F.Text({
        errorKey: 'rich_address.zip_code',
        required: true,
        minLength: 5,
        maxLength: 5,
      }),
      state: F.Choice<keyof typeof State>({ errorKey: 'rich_address.state' }).required(),
    },
  })

  export function toApi(valids: ReturnType<typeof schema.validate>) {
    return {
      rich_address: {
        line_1: valids.line1,
        line_2: valids.line2 || null,
        city: valids.city,
        zip_code: valids.zip,
        state: valids.state,
      },
      raw_address: null,
    }
  }

  export function fromApi(
    address: Cb.RichOrRawAddress | null,
    {
      fallback = null,
      rawAddressAsLine1 = false,
    }: { fallback?: Cb.RichAddress | null; rawAddressAsLine1?: boolean },
  ): typeof schema.initValue {
    const richAddress = address?.rich_address ?? fallback
    const rawAddress = address?.raw_address
    return richAddress !== null
      ? {
          line1: richAddress.line_1,
          line2: richAddress.line_2 ?? undefined,
          city: richAddress.city,
          zip: richAddress.zip_code,
          state: richAddress.state,
        }
      : rawAddress
      ? rawAddressAsLine1
        ? { line1: rawAddress }
        : {}
      : {}
  }

  export function format(state: F.Input<typeof schema>) {
    if (state.city.value && state.line1.value && state.state.value && state.zip.value) {
      return formatAddress({
        line_1: state.line1.value,
        line_2: state.line2.value,
        city: state.city.value,
        state: state.state.value,
        zip_code: state.zip.value,
      })
    } else {
      return '--'
    }
  }

  export function formatApi(value: Cb.RichOrRawAddress | null, settings?: { multiline: boolean }) {
    if (value === null) return null
    return value.rich_address !== null
      ? formatAddress(
          {
            line_1: value.rich_address.line_1,
            line_2: value.rich_address.line_2,
            city: value.rich_address.city,
            zip_code: value.rich_address.zip_code,
            state: value.rich_address.state,
          },
          settings,
        )
      : value.raw_address
  }

  export function searchAddress(pre: string): Promise<Cb.RichAddress[]> {
    if (pre.length <= 2) return Promise.resolve([])
    return Promise.race([Smarty.predict(pre), timeout(2000, []) as Promise<Cb.RichAddress[]>])
  }

  export function useSearchAddress(value: string) {
    return useQuery({ queryFn: () => searchAddress(value), queryKey: ['addr-input-v3', value] })
  }

  export function useExactMatch(value: string) {
    return useQuery({
      queryFn: async () => {
        const res = await searchAddress(value)
        return res.length === 1 ? res[0] : null
      },
      queryKey: ['addr-input-v2-exact', value],
    })
  }
}

import { useContext as useBaseContext, createContext } from 'react'
import { TextColors } from 'compass-local/utils/colors'

export namespace MetricsUtils {
  export type Variant = 'xsmall' | 'small' | 'medium' | 'large' | 'preview/medium' | 'preview/large'
  export type Layout = 'v' | 'h'
  export type Alignment = 'right' | 'left'
  export type Spec = {
    variant?: Variant
    align?: Alignment
    layout?: Layout
    color?: keyof typeof TextColors
    bold?: boolean
    labelIcon?: JSX.Element
    labelEndIcon?: JSX.Element
    icon?: JSX.Element
    clamp?: boolean
  }

  export const Ctx = createContext<Spec>({})

  export function useContext() {
    return useBaseContext(Ctx)
  }
}

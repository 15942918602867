import { Cb } from 'cb'
import { t } from 'content'
import LinkDONOTUSE from 'compass-local/Link2'
import { Module } from 'modules/routes'
import { theme2 } from 'theme2'
import Button from 'compass/data/Button'
import Typography from 'compass/data/Typography'

type Props = {
  details: Cb.TaskApproveInvoicesDetails
  close: () => void
}

export default function ApproveInvoices({ details, close }: Props) {
  return (
    <div className="flex justify-between items-center gap-4 px-4 py-3">
      <Typography>
        {details &&
          (details?.count === 1
            ? t('You have 1 bill to approve.')
            : t('You have {{ X }} bills to approve.', { X: details.count }))}
      </Typography>
      <LinkDONOTUSE href={Module('/bills', { f: { status: ['pending'] } }).href}>
        <Button theme={theme2.ButtonThemeTextOrange} onClick={close}>
          {t('View bills')}
        </Button>
      </LinkDONOTUSE>
    </div>
  )
}

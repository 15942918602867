/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type TutorialStatus = (typeof TutorialStatus)[keyof typeof TutorialStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TutorialStatus = {
  in_progress: 'in_progress',
  dismissed: 'dismissed',
  completed: 'completed',
} as const

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListContractsOrdering =
  (typeof ListContractsOrdering)[keyof typeof ListContractsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListContractsOrdering = {
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
  contract_type: 'contract_type',
  '-contract_type': '-contract_type',
  start_date: 'start_date',
  '-start_date': '-start_date',
  payer_name: 'payer_name',
  '-payer_name': '-payer_name',
  payee_name: 'payee_name',
  '-payee_name': '-payee_name',
  name: 'name',
  '-name': '-name',
  archived: 'archived',
  '-archived': '-archived',
} as const

import { cn } from 'msutils/classnames'
import { unreachable } from 'msutils/misc'
import { BorderStyle } from '../utils'

export namespace StandardBaseUtils {
  export function getBorderClassNames(border: BorderStyle) {
    if (!border) return ''
    return cn(
      border.width === 'sm'
        ? 'border'
        : border.width === 'lg'
        ? 'border outline outline-1.5 -outline-offset-2'
        : unreachable(border.width),
      border.color === 'grey'
        ? 'border-th-warmgrey-2 outline-th-warmgrey-2'
        : border.color === 'red'
        ? 'border-double outline-th-red-warning'
        : unreachable(border.color),
    )
  }
}

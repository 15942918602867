/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListGeneralProjectConfigsOrdering =
  (typeof ListGeneralProjectConfigsOrdering)[keyof typeof ListGeneralProjectConfigsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListGeneralProjectConfigsOrdering = {
  default_markup_multiplier: 'default_markup_multiplier',
  '-default_markup_multiplier': '-default_markup_multiplier',
  is_payee_project_creation_blocked: 'is_payee_project_creation_blocked',
  '-is_payee_project_creation_blocked': '-is_payee_project_creation_blocked',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

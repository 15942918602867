/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListNoteMentionLinksOrdering =
  (typeof ListNoteMentionLinksOrdering)[keyof typeof ListNoteMentionLinksOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListNoteMentionLinksOrdering = {
  employee_name: 'employee_name',
  '-employee_name': '-employee_name',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

import { createContext, ReactNode, useContext, useMemo } from 'react'
import { Cb, Q } from 'cb'
import Typography from 'compass/data/Typography'
import { MSArray, MSError } from 'msutils'
import { useAuthContext } from 'root/auth'
import withInjectedQueriesDONOTUSE, { AwaitedQueryInjectedProps } from 'utils/withInjectedQueries'
import { useSmartEmailVerificationQuery } from 'root/utils'

type AppContext = (
  | {
      type: 'business'
      employee: Cb.Employee
      payeeEnabled: true
      payerEnabled: boolean
      emailVerifications: Cb.EmailVerification[]
      business: Cb.Business
      internalAccount: Cb.TransactionAccountTypeInternalBankAccount | null
      cardProgram: Cb.CardProgram | null
    }
  | {
      type: 'client'
      payeeEnabled: boolean
      payerEnabled: true
      client: Cb.Client
    }
) & {
  user: Cb.User
  auth: string
  authType: 'impersonator' | 'default'
  logout: () => void
  qboEnabled: boolean
  accountingCodeV2Enabled: boolean
  hasAccountingCodes: boolean
  hasCostTypes: boolean
  qboPushEnabled: boolean
}

const Context = createContext<AppContext | undefined>(undefined)

export function useAppContext() {
  const context = useContext(Context)
  if (context === undefined) throw new Error('AppContext must be used within an AppProvider')
  return context
}

export function useBusinessContext() {
  const context = useAppContext()
  if (context.type !== 'business') throw new Error('Expected business context')
  return context
}

export function useClientContext() {
  const context = useAppContext()
  if (context.type !== 'client') throw new Error('Expected client context')
  return context
}

function useQueries() {
  return Q.group({
    user: Cb.useListUsers({ select: Q.first }),
    employees: Cb.useListEmployees(),
    emailVerifications: useSmartEmailVerificationQuery(),
    // Minimally disable the app for users that are archived
    business: Cb.useListBusiness({ params: { archived: false }, select: Q.opt }),
    client: Cb.useListClients({ select: Q.opt }),
    payerEnabled: Cb.useWhoAmI({ select: (data) => data.payer_enabled }),
    payeeEnabled: Cb.useWhoAmI({ select: (data) => data.payee_enabled }),
    qboEnabled: Cb.useListAccounts({
      select: (data) => data.results.some((x) => x.enabled && x.token_status === 'active'),
    }),
    accountingCodeV2Enabled: Cb.useListAccounts({
      select: (data) => data.results.some((x) => x.accounting_code_v2_enabled),
    }),
    hasAccountingCodes: Cb.useListCostCodes({
      select: (data) => MSArray.isNonEmpty(data.results),
    }),
    hasCostTypes: Cb.useListCostTypes({
      params: { archived: false },
      select: (data) => MSArray.isNonEmpty(data.results),
    }),
    qboPushEnabled: Cb.useListAccounts({
      select: (data) =>
        data.results.some((x) => x.outbound_sync_enabled && x.token_status === 'active'),
    }),
    internalAccount: Cb.useListTransactionAccounts({
      select: ({ results }) =>
        results.flatMap((x) => (x.type === 'internal_bank_account' ? [x] : [])).at(0) ?? null,
    }),
    cardProgram: Cb.useListCardPrograms({ select: Q.opt }),
  })
}

type Props = {
  children: ReactNode
  _queryset: AwaitedQueryInjectedProps<typeof useQueries>
}

export default withInjectedQueriesDONOTUSE(useQueries, ({ children, _queryset }: Props) => {
  const {
    qboEnabled,
    hasAccountingCodes,
    accountingCodeV2Enabled,
    hasCostTypes,
    qboPushEnabled,
    payeeEnabled,
    payerEnabled,
    emailVerifications,
    internalAccount,
    cardProgram,
    client,
    business,
    user,
    employees,
  } = _queryset

  const { authToken, authType, logout } = useAuthContext()
  const contextValue = useMemo((): AppContext => {
    const shared = {
      qboEnabled,
      hasAccountingCodes,
      accountingCodeV2Enabled,
      hasCostTypes,
      qboPushEnabled,
      auth: authToken,
      logout,
      authType,
      user,
      cardProgram,
    }
    if (payeeEnabled && business) {
      return {
        ...shared,
        payeeEnabled,
        emailVerifications,
        employee: MSArray.assertFind(employees, (x) => x.user_id === user.id),
        business,
        payerEnabled,
        internalAccount,
        type: 'business',
      }
    } else if (payerEnabled && client) {
      return {
        ...shared,
        payeeEnabled,
        client,
        payerEnabled,
        type: 'client',
      }
    } else {
      throw new MSError.Error2('Unexpected case in user type initialization')
    }
  }, [
    emailVerifications,
    qboEnabled,
    accountingCodeV2Enabled,
    hasAccountingCodes,
    hasCostTypes,
    qboPushEnabled,
    payeeEnabled,
    payerEnabled,
    client,
    business,
    authType,
    authToken,
    logout,
    user,
    internalAccount,
    cardProgram,
    employees,
  ])

  return (
    <Context.Provider value={contextValue}>
      {authType === 'impersonator' && (
        <div className="bg-red-200 p-4">
          {/* eslint-disable-next-line mosaic-js/no-raw-text-jsx */}
          <Typography variant="bodybold">internal user</Typography>
        </div>
      )}
      {children}
    </Context.Provider>
  )
})

import { KeyboardEvent, useMemo, useRef, useState } from 'react'
import { cn } from 'msutils/classnames'
import {
  InputBaseProps,
  InputContainer,
  InputStateProps,
  TextInputProps,
  useStateProps,
} from 'compass-local/input/utils'
import { Chevron } from 'compass-local/legacy/icons'
import Portal from 'compass-local/root/Portal'
import { compassId } from 'compass-local/root/utils'
import Typography from 'compass/data/Typography'
import { t } from 'content'
import { useOnChange, DomUtils } from 'msutils'
import useScreenSize from 'compass/theme/useScreenSize'
import { MSForm } from 'utils/form'
import SearchInput from 'compass-local/SearchInput'
import { DeselectIcon, Option } from '../component-utils'
import { Select2Utils as Utils } from '../utils'

type Props<TVal, TMeta> = InputStateProps<TVal | null> &
  InputBaseProps &
  TextInputProps &
  Utils.OptionProps<TVal, TMeta> & {
    title?: string
    isLoading?: boolean
  }

export default function Select2<TVal, TMeta>(props: Props<TVal, TMeta>) {
  const { value, update, focus, blur } = useStateProps(props)
  const [search, setSearch] = useState('')
  const [searchRef, setSearchRef] = useState<HTMLInputElement | null>(null)
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const [highlightedRow, setHighlightedRow] = useState<string | null>(null)
  const [hovered, setHovered] = useState(false)
  const sz = useScreenSize()
  const [pickerIsOpen, setPickerIsOpen] = useState(false)
  const setValue = useMemo(() => {
    return (newValue: TVal | null) => {
      update(newValue)
      blur?.()
      setHovered(false)
      searchRef?.blur()
    }
  }, [update, searchRef, blur])
  const menuRef = useRef<HTMLDivElement>(null)
  useOnChange([highlightedRow], () => {
    if (highlightedRow && menuRef.current) {
      const element = document.getElementById(highlightedRow)
      if (element && !DomUtils.isInView(menuRef.current, element)) {
        element.scrollIntoView()
      }
    }
  })

  const isFocused = document.activeElement === searchRef
  const selectedValueTitle = value ? props.getTitle(value) : ''
  const updateSearch = (newValue: string) => {
    setSearch(newValue)
    setHighlightedRow(Utils.first({ ...props, options: Utils.search(props, newValue) }))
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'ArrowDown') {
      setHighlightedRow(Utils.next(highlightedRow, props))
    } else if (e.code === 'ArrowUp') {
      setHighlightedRow(Utils.prev(highlightedRow, props))
    } else if (e.code === 'Enter') {
      setValue(Utils.getValueWithId(highlightedRow, props))
      setPickerIsOpen(false)
    } else if (e.code === 'Backspace') {
      if (search === '') {
        setValue(null)
      }
    } else if (e.code === 'Escape') {
      searchRef?.blur()
      setPickerIsOpen(false)
      e.stopPropagation()
    }
  }

  return (
    <InputContainer
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        if (sz === 'sm') {
          setPickerIsOpen(true)
        }
      }}
      containerRef={setContainerRef}
      inputEl={searchRef}
      text={search || selectedValueTitle}
      placeholder={
        props.placeholder === undefined ? t('Search...') : props.placeholder ?? undefined
      }
      isEmpty={!value && !search}
      icon={
        sz === 'sm' ? (
          !!value && <DeselectIcon deselect={() => setValue(null)} />
        ) : hovered && !!value ? (
          <DeselectIcon deselect={() => setValue(null)} />
        ) : (
          <Chevron height={12} className="text-th-coolgrey-2 rotate-90" />
        )
      }
    >
      {!search && (
        <div className="flex items-center absolute inset-0">
          <div className="truncate">{selectedValueTitle ?? ''}</div>
        </div>
      )}
      {sz === 'sm' ? (
        <div onClick={(e) => e.stopPropagation()}>
          <MSForm.Drawer
            title={props.title}
            inlineTitle
            isActive={pickerIsOpen}
            setInactive={() => setPickerIsOpen(false)}
          >
            <div className="-mx-3 vflex gap-1">
              <div className="px-2">
                {props.options.length >= 10 && (
                  <SearchInput value={search} update={updateSearch} onKeyDown={handleKeyDown} />
                )}
              </div>
              {props.isLoading ? (
                <Typography className="text-th-text-disabled">{t('Loading...')}</Typography>
              ) : (
                Utils.search(props, search).map((meta) => (
                  <Option
                    {...props}
                    key={props.getId(props.getValueFromOption(meta))}
                    meta={meta}
                    isHighlighted={props.getId(props.getValueFromOption(meta)) === highlightedRow}
                    setIsHighlighted={() =>
                      setHighlightedRow(props.getId(props.getValueFromOption(meta)))
                    }
                    onClick={() => {
                      setValue(props.getValueFromOption(meta))
                      setPickerIsOpen(false)
                      setHighlightedRow(null)
                      setSearch('')
                    }}
                  />
                ))
              )}
            </div>
          </MSForm.Drawer>
        </div>
      ) : (
        <>
          <input
            className={cn(compassId('input-select'), 'bg-transparent absolute inset-0')}
            ref={setSearchRef}
            value={search}
            onChange={(e) => updateSearch(e.target.value)}
            onFocus={() => {
              setHighlightedRow(value ? props.getId(value) : Utils.first(props))
              focus?.()
            }}
            onKeyDown={handleKeyDown}
            onBlur={() => {
              blur?.()
              setHovered(false)
              setHighlightedRow(null)
              setSearch('')
            }}
          />
          {isFocused && (
            <Portal element={containerRef}>
              <div
                style={{
                  top: (containerRef?.clientHeight ?? 5) + 4,
                  minWidth: containerRef?.clientWidth ?? 120,
                }}
                className="absolute bg-th-bg-white p-1 rounded-8 shadow-lg vflex gap-0.5 max-h-[230px] overflow-y-auto"
                ref={menuRef}
              >
                {props.isLoading ? (
                  <Typography className="text-th-text-disabled">{t('Loading...')}</Typography>
                ) : (
                  Utils.search(props, search).map((meta) => (
                    <Option
                      {...props}
                      key={props.getId(props.getValueFromOption(meta))}
                      meta={meta}
                      isHighlighted={props.getId(props.getValueFromOption(meta)) === highlightedRow}
                      setIsHighlighted={() =>
                        setHighlightedRow(props.getId(props.getValueFromOption(meta)))
                      }
                      onClick={() => setValue(props.getValueFromOption(meta))}
                    />
                  ))
                )}
              </div>
            </Portal>
          )}
        </>
      )}
    </InputContainer>
  )
}

import { cn } from 'msutils/classnames'
import Divider from 'compass-local/Divider'
import StandardBase from 'compass-local/input/StandardBase'
import { Chevron } from 'compass-local/legacy/icons'
import LinkButtonDONOTUSE from 'compass-local/legacy/LinkButtonDONOTUSE'
import { Link2Utils } from 'compass-local/Link2'
import Typography from 'compass/data/Typography'
import { onClickHref } from 'compass-local/utils/onClickHref'
import EllipsisMenu from 'components/misc/EllipsisMenu'
import { t } from 'content'
import { MSArray } from 'msutils'
import { unreachable } from 'msutils/misc'
import {
  Cell,
  ColumnDef,
  getPropFromMeta,
  HrefIndicator,
  Row,
  SelectControl,
  Style,
} from '../../internal-utils'
import { TableUtils as Utils } from '../../utils'

type Props<TData, TCtx> = {
  spec: ReturnType<typeof Utils.useSpec<TData, TCtx>>
  ctx: TCtx
  style: Style
  row: Row<TData>
  index: number
}

export function MobileRow<TData, TCtx>({ spec, ctx, row, style, index }: Props<TData, TCtx>) {
  const { data, depth, parent, hasChildren } = row
  const {
    rowActions: getRowActions,
    getId,
    href: getHref,
    state,
    getSelectDisabledReason,
    allowSelect,
    dragId: getDragId,
    dropIds: getDropIds,
    handleMove,
    rows,
    rowFooter: getRowFooter,
    rowHeader: getRowHeader,
    isInactive: getIsInactive,
  } = spec
  const columns = spec.columns.filter((c) => !c.dynamic?.omit?.(ctx))
  const push = Link2Utils.usePush()
  const cellMeta = { row: data, depth }
  const isLastInSection = !!(
    rows.at(index + 1)?.depth === depth - 1 ||
    (depth > 0 && index === rows.length - 1)
  )
  const showBorder =
    style === 'item-list'
      ? index !== rows.length - 1
      : style === 'estimate'
      ? index !== rows.length - 1 && ((!hasChildren && depth === 0) || isLastInSection)
      : style === 'preview'
      ? (!hasChildren && depth === 0) || isLastInSection
      : style === 'input-table'
      ? false
      : unreachable(style)

  const rowId = getId(data, index)
  const rowMeta = {
    ctx,
    depth,
    index,
    id: rowId,
    parent: parent?.data ?? null,
  }
  const href = getHref?.(data, rowMeta)
  const rowActions = MSArray.collapse(getRowActions?.(data, rowMeta) ?? [])
  const selected = state.selected.isActive(rowId)
  const border = style === 'input-table' ? { color: 'grey' as const, width: 'sm' as const } : null

  const hidden = (col: ColumnDef<any, TData, TCtx>) =>
    !!getPropFromMeta(col.hidden, { cellMeta, ctx }) ||
    !!getPropFromMeta(col.desktopOnly, { cellMeta, ctx }) ||
    !!getPropFromMeta(col.void, { cellMeta, ctx })

  const titleCell = columns.find((x) => getPropFromMeta(x.position, { cellMeta, ctx }) === 'title')
  const statusCell = columns.find(
    (x) => getPropFromMeta(x.position, { cellMeta, ctx }) === 'status' && !hidden(x),
  )
  const descriptionCells = columns.filter(
    (x) => getPropFromMeta(x.position, { cellMeta, ctx }) === 'description' && !hidden(x),
  )
  const badgeCells = columns.filter(
    (x) => getPropFromMeta(x.position, { cellMeta, ctx }) === 'badge' && !hidden(x),
  )
  const activeColumns = columns.filter(
    (x) => (getPropFromMeta(x.position, { cellMeta, ctx }) ?? 'row') === 'row' && !hidden(x),
  )

  const rowHeader = getRowHeader?.(row.data, rowMeta)

  const dragId = getDragId?.(data, rowMeta) ?? '<none>'

  const upwardsDropTarget = [...rows]
    .reverse()
    .map((x, i) => ({ row: x, index: rows.length - i - 1 }))
    .find((r) => {
      if (r.index < index) {
        const id = getId(r.row.data, r.index)
        const dropIds = getDropIds?.(r.row.data, {
          ctx,
          depth: r.row.depth,
          index: r.index,
          id,
          parent: r.row.parent?.data ?? null,
        })
        return dropIds?.includes(dragId)
      } else {
        return false
      }
    })
  const downwardDropTarget = rows
    .map((x, i) => ({ row: x, index: i }))
    .find((r) => {
      if (r.index > index) {
        const id = getId(r.row.data, r.index)
        const dropIds = getDropIds?.(r.row.data, {
          ctx,
          depth: r.row.depth,
          index: r.index,
          id,
          parent: r.row.parent?.data ?? null,
        })
        return dropIds?.includes(dragId)
      } else {
        return false
      }
    })

  // TODO: in theory, this needs to be recursive (like getMetaForParent(row, rows))
  // but it should be fine for now
  const isInactive = getIsInactive?.(data, rowMeta)
  const parentInactive =
    parent &&
    getIsInactive?.(parent?.data, {
      ctx,
      depth: depth - 1,
      index: parent.index,
      parent: null,
      id: getId(parent.data, parent.index),
    })

  return (
    <>
      {rowHeader?.component && (!(isInactive || parentInactive) || rowHeader.showWhenInactive) && (
        <div className="bg-th-bg-slate py-2">{rowHeader.component}</div>
      )}
      {!isInactive && !parentInactive && (
        <div
          className={cn(href && 'cursor-pointer')}
          style={{ paddingLeft: depth ? `${depth * 28}px` : undefined }}
          onClick={href ? onClickHref({ href, push }) : undefined}
        >
          <div
            className={cn('vflex gap-3', style === 'input-table' && 'p-5 bg-th-bg-slate rounded-4')}
          >
            <div className="flex gap-5 justify-between">
              <div className="flex items-center gap-3">
                {allowSelect && (
                  <SelectControl
                    selected={selected}
                    setSelected={(newValue) => state.selected.set(rowId, newValue)}
                    disabledMessage={getSelectDisabledReason?.(cellMeta, ctx) ?? null}
                  />
                )}
                <div className="flex items-center gap-3">
                  <div className="vflex gap-1">
                    <Typography variant="bodybold" preserveWhitespace>
                      {titleCell ? <Cell column={titleCell} props={{ cellMeta, ctx }} /> : '--'}
                    </Typography>
                    <div className="vflex gap-2">
                      {descriptionCells.map((x, j) => (
                        <Typography variant="caption" key={j}>
                          <Cell column={x} props={{ cellMeta, ctx }} />
                        </Typography>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                {statusCell && <Cell column={statusCell} props={{ cellMeta, ctx }} />}
                {badgeCells.map((x, j) => (
                  <Cell key={j} column={x} props={{ cellMeta, ctx }} />
                ))}
                {MSArray.isNonEmpty(rowActions) && <EllipsisMenu options={rowActions} />}
                {!!href && <HrefIndicator />}
              </div>
            </div>
            {activeColumns.map((x, j) => {
              const header = x.dynamic?.header?.(ctx) ?? x.header
              const isInputColumn =
                style === 'input-table' && !getPropFromMeta(x.nonInput, { cellMeta, ctx })

              return !isInputColumn ? (
                <div
                  className={cn(
                    'flex gap-5 justify-between',
                    style === 'input-table' && 'items-center',
                  )}
                  key={j}
                >
                  <Typography>{header}</Typography>
                  <Typography variant="bodybold" className={cn(isInputColumn && '!block')}>
                    <Cell column={x} props={{ cellMeta, ctx }} />
                  </Typography>
                </div>
              ) : (
                <StandardBase
                  border={getPropFromMeta(x.border, { cellMeta, ctx }) ?? border}
                  title={getPropFromMeta(x.inputTitle, { cellMeta, ctx }) ?? header}
                  key={j}
                >
                  <Cell column={x} props={{ cellMeta, ctx }} />
                </StandardBase>
              )
            })}
            {(upwardsDropTarget || downwardDropTarget) && (
              <div className="flex gap-2 items-center">
                {upwardsDropTarget && (
                  <LinkButtonDONOTUSE
                    onClick={() =>
                      handleMove?.(
                        { row: row.data, parent: parent?.data ?? null, index, depth, id: rowId },
                        {
                          row: upwardsDropTarget.row.data,
                          parent: upwardsDropTarget.row.parent?.data ?? null,
                          index: upwardsDropTarget.index,
                          id: getId(upwardsDropTarget.row.data, upwardsDropTarget.index),
                          depth: upwardsDropTarget.row.depth,
                        },
                      )
                    }
                    icon={<Chevron height={14} className="-rotate-90" />}
                  >
                    {t('Move up')}
                  </LinkButtonDONOTUSE>
                )}
                {downwardDropTarget && (
                  <LinkButtonDONOTUSE
                    onClick={() =>
                      handleMove?.(
                        { row: row.data, parent: parent?.data ?? null, index, depth, id: rowId },
                        {
                          row: downwardDropTarget.row.data,
                          parent: downwardDropTarget.row.parent?.data ?? null,
                          index: downwardDropTarget.index,
                          id: getId(downwardDropTarget.row.data, downwardDropTarget.index),
                          depth: downwardDropTarget.row.depth,
                        },
                      )
                    }
                    icon={<Chevron height={14} className="rotate-90" />}
                  >
                    {t('Move down')}
                  </LinkButtonDONOTUSE>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {!row.hasChildren &&
        row.path.map((parentIndex) => {
          const parentRow = rows[parentIndex]
          const parentId = getId(parentRow.data, parentIndex)
          const nextAtDepth = rows.findIndex(
            (x, j) => j > parentIndex && x.depth === parentRow.depth,
          )
          // show my own footer, show footer if there is no next at depth, show a footer if this is the row before going back to some specific depth
          const isDescendentOfLastInSection =
            parentIndex === index ||
            (nextAtDepth < 0 && index === rows.length - 1) ||
            nextAtDepth === index + 1
          const footer = isDescendentOfLastInSection
            ? getRowFooter?.(parentRow.data, {
                parent: parentRow.parent?.data ?? null,
                index: parentIndex,
                depth: parentRow.depth,
                id: parentId,
                ctx,
              })
            : null

          if (
            footer?.component &&
            parentIndex !== index &&
            (!parentInactive || footer.showWhenInactive)
          ) {
            return (
              <div className="py-2" key={`footer__${parentId}`}>
                <div style={{ paddingLeft: `${(parentRow.depth + 1) * 28}px` }}>
                  {footer.component}
                </div>
              </div>
            )
          } else {
            return null
          }
        })}
      {showBorder && <Divider />}
    </>
  )
}

import { Action } from 'utils/actions'
import Typography from 'compass/data/Typography'
import { Cb, Q } from 'cb'
import { useSupportContext } from 'root/global'
import { t } from 'content'
import { X } from 'compass-local/legacy/icons'
import useScreenSize from 'compass/theme/useScreenSize'
import OnboardAccountForm from 'components/forms/OnboardAccountForm'
import { Module } from 'modules/routes'
import { ExpenseCardUtils } from 'features/expense-card/utils'
import useActivateAccountContext from 'features/onboarding/useActivateAccountContext'
import { MSArray } from 'msutils'
import { cn } from 'msutils/classnames'

function useQueries() {
  return Q.group({
    stripeConnectedAccountLink: Cb.useListStripeConnectedAccountLinks({
      select: Q.opt,
    }),
    bankAccounts: Cb.useListBankAccounts({
      params: { archived: false },
    }),
    onboardingSurvey: Cb.useListOnboardingSurveys({ select: Q.opt }),
    cardProgram: Cb.useListCardPrograms({ select: Q.opt }),
    payerContacts: Cb.useListPayerContacts(),
    hasInvoice: Cb.useListInvoices({ select: (data) => MSArray.isNonEmpty(data.results) }),
    hasBill: Cb.useListBills({ select: (data) => MSArray.isNonEmpty(data.results) }),
  })
}

type BannerProps = {
  text: string
  action?: Action.Config
  closeAction?: () => void
}

function Banner({ text, action, closeAction }: BannerProps) {
  const sz = useScreenSize()

  return (
    <div className="w-full bg-th-orange-light2 px-5 py-3 flex justify-between items-center gap-5">
      <div
        className={cn(
          sz === 'sm' ? 'vflex gap-3' : 'flex justify-between gap-5 w-full items-center',
        )}
      >
        <Typography variant={sz === 'sm' ? 'label' : 'body'}>{text}</Typography>
        <div className="w-min">{action && <Action.Mount key={action.name} {...action} />}</div>
      </div>
      {closeAction && (
        <X height={12} thickness={2.2} className="cursor-pointer" onClick={closeAction} />
      )}
    </div>
  )
}

export default function ActivateAccountBanner() {
  const queryset = useQueries()
  const updateOnboardingSurvey = Cb.usePartialUpdateOnboardingSurveyHook()
  const { setChatOpen } = useSupportContext()

  const { status, onSuccess, controlledFormProps } = useActivateAccountContext()

  if (queryset.status !== 'success') return null

  const {
    stripeConnectedAccountLink,
    bankAccounts,
    onboardingSurvey,
    cardProgram,
    payerContacts,
    hasInvoice,
    hasBill,
  } = queryset.queries

  const beamCardAvailable = stripeConnectedAccountLink?.business_state
    ? ExpenseCardUtils.availableInState(stripeConnectedAccountLink.business_state)
    : true
  const wantsOnlyBeamCard = onboardingSurvey
    ? onboardingSurvey.wants_beam_card &&
      !onboardingSurvey.wants_bill_payment &&
      !onboardingSurvey.wants_estimating &&
      !onboardingSurvey.wants_invoicing
    : false
  const beamCardAppCompleted =
    cardProgram &&
    (cardProgram.status === 'approved' ||
      cardProgram.status === 'processing' ||
      cardProgram.status === 'declined' ||
      cardProgram.status === 'pending' ||
      cardProgram.status === 'closed' ||
      cardProgram.status === 'unavailable_in_state')

  // This is a just a heuristic since we don't store this information reliably.
  const isSubFlyWheel =
    MSArray.isNonEmpty(payerContacts.filter((x) => !x.is_guest)) ||
    MSArray.isNonEmpty(bankAccounts.filter((x) => !x.inbound_transfers_enabled))

  if (status === 'not-ready') return null
  if (
    !hasInvoice &&
    !hasBill &&
    !wantsOnlyBeamCard &&
    !stripeConnectedAccountLink?.details_submitted
  )
    return null

  return status === 'waiting-for-stripe' ? (
    <Banner
      text={t(
        "Business verification is in progress. If it's taking longer than 5 minutes, contact support.",
      )}
      action={Action.button(t('Contact support'), {
        onClick: () => setChatOpen(true),
        theme: 'text-small',
        endIcon: ['arrow', '90'],
      })}
    />
  ) : status === 'ready-for-first-submission' ? (
    <Banner
      text={
        wantsOnlyBeamCard
          ? t(
              'Before you can apply for the Beam Card, validate your business information. This one-time step takes about 10 minutes.',
            )
          : isSubFlyWheel
          ? t(
              'Before you can receive payments from new clients, validate your business information. This one-time step takes about 10 minutes.',
            )
          : t(
              'Before you can send and receive payments, validate your business information. This one-time step takes about 10 minutes.',
            )
      }
      action={Action.form(t('Validate business information'), {
        Form: OnboardAccountForm,
        theme: 'text-small',
        endIcon: ['arrow', '90'],
        qualify: () => ({ onSuccess }),
        controlledFormProps,
      })}
    />
  ) : status === 'ready-for-resubmission' ? (
    <Banner
      text={t('Additional information is required to validate your business.')}
      action={Action.form(t('Provide additional information'), {
        Form: OnboardAccountForm,
        theme: 'text-small',
        endIcon: ['arrow', '90'],
        qualify: () => ({ onSuccess }),
        controlledFormProps,
      })}
    />
  ) : MSArray.isEmpty(bankAccounts.filter((x) => x.inbound_transfers_enabled)) &&
    MSArray.isNonEmpty(bankAccounts) ? (
    <Banner
      text={t('Re-link your bank account to send payments.')}
      action={Action.href(t('Re-link bank account'), {
        href: Module('/settings/bank-account').href,
        theme: 'text-small',
        endIcon: ['arrow', '90'],
      })}
    />
  ) : MSArray.isEmpty(bankAccounts.filter((x) => x.inbound_transfers_enabled)) ? (
    <Banner
      text={t('Link your bank account to send and receive payments.')}
      action={Action.href(t('Link bank account'), {
        href: Module('/settings/bank-account').href,
        theme: 'text-small',
        endIcon: ['arrow', '90'],
      })}
    />
  ) : wantsOnlyBeamCard && beamCardAvailable && !beamCardAppCompleted ? (
    <Banner
      text={t("You're ready to apply for the Beam Card.")}
      action={Action.href(t('Start card application'), {
        href: Module('/expense-cards').href,
        theme: 'text-small',
        endIcon: ['arrow', '90'],
      })}
      closeAction={() => {
        if (onboardingSurvey) {
          updateOnboardingSurvey(onboardingSurvey.id, { wants_beam_card: false })
        }
      }}
    />
  ) : null
}

import { Cb, Q } from 'cb'
import Select2 from 'compass-local/input/Select2'
import { InputBaseUtils } from 'compass-local/InputBase'
import SelectInput from 'compass-local/SelectInput'
import { getInputProps, InputProps } from 'compass-local/utils/InputProps'
import { t } from 'content'

type Props = InputProps<Cb.CostType | null> &
  InputBaseUtils.ExternalProps & {
    v2?: boolean
  }

export default function CostTypeSelect(props: Props) {
  const { value, update, focus, blur } = getInputProps(props)
  const q = Q.group({
    costCodes: Cb.useListCostTypes({
      params: { archived: 'false' },
    }),
  })
  const options = q.status === 'success' ? q.queries.costCodes : null

  if (props.v2) {
    return (
      <Select2
        value={value}
        update={update}
        focus={focus}
        blur={blur}
        title={t('Cost type')}
        placeholder={t('Select cost type...')}
        isLoading={q.status === 'loading'}
        getId={(x) => x.id}
        getTitle={(c) => c.name}
        getValueFromOption={(x) => x}
        options={options ?? []}
      />
    )
  } else {
    return (
      <SelectInput
        placeholder={t('Select cost type...')}
        error={q.status === 'error' ? 'There was an error loading cost types' : undefined}
        {...props}
        title={t('Cost type')}
        isLoading={q.status === 'loading'}
        getId={(x) => x.id}
        getTitle={(c) => c.name}
        getOptionProps={(c) => ({ value: c })}
        options={options}
      />
    )
  }
}

import { useEffect, useState } from 'react'

const DefaultScrollParent = 'BODY'

// https://github.com/codastic/react-positioning-portal/blob/master/src/services/scroll-parents.ts
function isScrollParent(element: HTMLElement): boolean {
  try {
    const { overflow, overflowY, overflowX } = getComputedStyle(element)
    return /(auto|scroll)/.test(overflow + overflowX + overflowY)
  } catch (e) {
    return false
  }
}

export function getScrollableParents(
  element: HTMLElement | null,
  scrollParents: Array<HTMLElement | Window> = [],
): Array<HTMLElement | Window> {
  if (!element || element.tagName === DefaultScrollParent) {
    return [...scrollParents, window]
  }

  return getScrollableParents(
    element.parentElement,
    isScrollParent(element) ? [...scrollParents, element] : scrollParents,
  )
}

export function useElementSize(element: HTMLElement | null) {
  const [boundingRect, setBoundingRect] = useState<DOMRect | null>(
    element ? element.getBoundingClientRect() : null,
  )
  useEffect(() => {
    if (element) {
      const handleResize = () => {
        setBoundingRect(element.getBoundingClientRect())
      }

      const observer = new ResizeObserver(handleResize)
      observer.observe(element)
      return () => {
        observer.disconnect()
      }
    } else {
      return undefined
    }
  }, [element])
  return boundingRect
}

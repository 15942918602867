import { ReactNode, useEffect, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { AnchoredPortal } from '../../_internal/AnchoredPortal'

type Props = {
  isOpen: boolean
  setIsOpen: (newValue: boolean) => void
  anchor: ReactNode
  children: ReactNode
}

export default function DropdownEXPERIMENTAL({ isOpen, setIsOpen, anchor, children }: Props) {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isOpen) {
      const handleExternalClick = (e: MouseEvent) => {
        if (e.target instanceof HTMLElement) {
          if (!ref?.contains(e.target)) setIsOpen(false)
        }
      }
      document.addEventListener('click', handleExternalClick)
      return () => {
        document.removeEventListener('click', handleExternalClick)
      }
    } else {
      return undefined
    }
  }, [isOpen, ref, setIsOpen])

  return (
    <>
      {ref && (
        <AnchoredPortal anchor={ref} setInactive={() => setIsOpen(false)}>
          <div className="bg-white border shadow-md overflow-none rounded-2">
            <AnimateHeight height={isOpen ? 'auto' : 0} animateOpacity duration={100}>
              {children}
            </AnimateHeight>
          </div>
        </AnchoredPortal>
      )}
      <div ref={setRef} onClick={() => setIsOpen(!isOpen)}>
        {anchor}
      </div>
    </>
  )
}

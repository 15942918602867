export class TimeoutError extends Error {
  _type = 'timeout'
}

export function withTimeout<T>(p: Promise<T>, timeout = 2000): Promise<T> {
  return Promise.race([
    new Promise<never>((_, rej) => {
      setTimeout(() => {
        rej(new TimeoutError('Request timed out'))
      }, timeout)
    }),
    p,
  ])
}

import { Cb } from 'cb'
import { t } from 'content'
import { showToast } from 'utils/toast'
import { useFormState } from 'utils/form-input'
import AdditionalContactInput, {
  AdditionalContactInputUtils,
} from 'components/inputs/AdditionalContactInput'
import { MSForm } from 'utils/form'
import { ContactEmail } from 'components/inputs/ContactEmailSelect/Component'

type Props = {
  client: Cb.PayerContact | null
  vendor: Cb.PayeeContact | null
  onSuccess?: (_: ContactEmail) => void
}

const fc = MSForm.Controller<Props>()

export default fc.Form(() => {
  const { client, vendor, onSuccess } = fc.useContext()
  const { state, useValidatedAction } = useFormState(AdditionalContactInputUtils.schema)
  const createAdditionalContact = Cb.useCreateAdditionalContactHook()
  const createAdditionalContactAction = useValidatedAction(
    (validData) =>
      createAdditionalContact({
        payer_contact_id: client?.id ?? null,
        payee_contact_id: vendor?.id ?? null,
        name: validData.name,
        job_title: validData.jobTitle || null,
        email: validData.email || null,
        phone_number: validData.phone || null,
      }),
    {
      onSuccess: (contact) => {
        showToast({ title: t('Additional contact added') })
        if (contact.email) {
          onSuccess?.({ id: contact.id, email: contact.email, name: contact.name })
        }
      },
    },
  )
  return (
    <AdditionalContactInput
      state={state}
      submit={createAdditionalContactAction}
      copy={{ title: t('Add additional contact'), submit: t('Add') }}
    />
  )
})

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListBillOcrRawResultLineItemsOrdering =
  (typeof ListBillOcrRawResultLineItemsOrdering)[keyof typeof ListBillOcrRawResultLineItemsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListBillOcrRawResultLineItemsOrdering = {
  amount: 'amount',
  '-amount': '-amount',
  description: 'description',
  '-description': '-description',
  created_at: 'created_at',
  '-created_at': '-created_at',
  updated_at: 'updated_at',
  '-updated_at': '-updated_at',
} as const

import { cn } from 'msutils/classnames'
import Menu, { MenuOption } from 'compass-local/Menu'
import Spinner from 'compass-local/Spinner'
import { Ellipsis } from 'compass-local/legacy/icons'
import { Collapsable, MSArray } from 'msutils/array'

function processOptions(uncollapsedOptions: Collapsable<MenuOption[]>) {
  const options = MSArray.collapse(uncollapsedOptions)
  return options.reduce(
    // eslint-disable-next-line
    (p, c, i) => {
      if (i === 0 && c.type === 'divider') {
        return p
      } else if (i === options.length - 1 && c.type === 'divider') {
        return p
      } else if (MSArray.isNonEmpty(p) && p.at(-1)?.type === 'divider' && c.type === 'divider') {
        return p
      } else {
        return [...p, c]
      }
    },
    [] as MenuOption[],
  )
}

type Props = {
  options: Collapsable<MenuOption[]>
  isLoading?: boolean
  sizeDONOTUSE?: 'small' | 'default'
}

export default function EllipsisMenu(props: Props) {
  const options = processOptions(props.options)
  const { isLoading } = props

  return (
    <Menu options={options} overlayClassName="z-[10001]">
      {isLoading ? (
        <Spinner />
      ) : (
        <Ellipsis
          height={props.sizeDONOTUSE === 'small' ? 4 : 5}
          className={cn(
            'cursor-pointer text-th-text-secondary',
            props.sizeDONOTUSE === 'small' ? 'hitbox' : 'hitbox-lg',
          )}
        />
      )}
    </Menu>
  )
}

import Typography from 'compass/data/Typography'
import Card from 'compass/layout/Card'
import Toggle from 'compass-local/Toggle'
import { t } from 'content'
import { F, MSArray } from 'msutils'
import { EstimateItemsInputUtils } from 'components/inputs/EstimateItemsInput'
import { useAnalyticsContext } from 'root/global'
import { EstimateInputUtils as Utils } from '../utils'

type Props = {
  state: F.Input<typeof Utils.schema>
}

export default function PreviewOptions({ state }: Props) {
  const hasBufferedDiscount = MSArray.isNonEmpty(
    EstimateItemsInputUtils.getAllItems(state.lineItems).filter(
      (x) => x.type.value === 'buffered-discount',
    ),
  )
  const { showHideEstimateTotal } = useAnalyticsContext().flags

  return (
    <Card variant="white" disablePadding className="p-4 shadow vflex gap-4">
      <Typography variant="subtitle">{t('Format estimate')}</Typography>
      <div className="vflex gap-3">
        <Toggle
          title={t('Show cost breakdown')}
          {...F.props(state.showUnitCosts)}
          disabled={hasBufferedDiscount}
        />
        <div className="pl-6 vflex gap-3">
          <Toggle
            title={t('Show sub-section pricing')}
            {...F.props(state.showSubSectionPricing)}
            disabled={hasBufferedDiscount || !state.showUnitCosts.value}
          />
          <Toggle
            title={t('Show markup')}
            {...F.props(state.showMarkup)}
            disabled={hasBufferedDiscount || !state.showUnitCosts.value}
          />
        </div>
        <Toggle title={t('Show quantity')} {...F.props(state.showQuantity)} />
        <Toggle title={t('Show $0 items')} {...F.props(state.showZeroAmountItems)} />
        {showHideEstimateTotal && state.lineItems.children.some((x) => x.isSection.value) && (
          <Toggle
            title={t('Hide estimate total')}
            {...F.props(state.hideEstimateTotal)}
            {...(state.lineItems.children.some((x) => x.postSubtotal.value) && {
              disabled: true,
              disabledMessage: t(
                'Cannot hide subtotal when there are top-level items that show post subtotal',
              ),
            })}
          />
        )}
      </div>
    </Card>
  )
}
